// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'pack-drag',
    tooltip:
      "Transient anchor that uses a pack as additional friction or counterweight to slow one's descent down a cliff",
    jsx: (
      <>
        <Link to={routes.glossary_transient_anchor}>Transient anchor</Link> that
        uses a pack as additional friction or counterweight to slow one's
        descent down a cliff. Typically used in conjunction with a{' '}
        <Link to={routes.glossary_partner_capture}>partner capture</Link>.
      </>
    ),
    terms: ['pack-drag'],
  },
  {
    id: 'pack-rope',
    tooltip:
      'Short rope or cord that is tied to a backpack to simplify its hauling and lowering',
    jsx: (
      <>
        Short (approx. 30') <Link to={routes.glossary_rope}>rope</Link> or{' '}
        <Link to={routes.glossary_accessory_cord}>cord</Link> that is tied to a{' '}
        <Link to={routes.glossary_backpack}>backpack</Link> to simplify its
        hauling and lowering.
      </>
    ),
    terms: ['pack-rope'],
  },
  {
    id: 'pack-toss',
    tooltip:
      'A pothole escape technique where a canyoneer ties a rope to their pack, throws the pack over a pothole, and uses the pack as counterweight to escape the pothole',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_pothole_escape_technique}>
          pothole escape technique
        </Link>{' '}
        where a canyoneer ties a rope to their pack, throws the pack over a{' '}
        <Link to={routes.glossary_keeper_pothole}>pothole</Link>, and uses the
        pack as counterweight to escape the pothole.
      </>
    ),
    terms: ['pack-toss'],
  },
  {
    id: 'packraft',
    tooltip:
      'Compact, lightweight, durable raft designed to help canyoneers exit on rivers where other forms of traversal are impractical or impossible',
    jsx: (
      <>
        Compact, lightweight, durable raft designed to help canyoneers exit on
        rivers where other forms of traversal are impractical or impossible.
      </>
    ),
    terms: ['packraft'],
  },
  {
    id: 'partner-assist',
    tooltip:
      'A pothole escape technique where one canyoneer climbs out of the pothole with aid from other canyoneers',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_pothole_escape_technique}>
          pothole escape technique
        </Link>{' '}
        where one canyoneer climbs out of the{' '}
        <Link to={routes.glossary_keeper_pothole}>pothole</Link> with aid from
        other canyoneers. This can be done by climbing on the partners, being
        lifted up by partners, or even forming a{' '}
        <Link to={routes.glossary_human_pyramid}>human pyramid</Link>.
      </>
    ),
    terms: ['partner-assist'],
  },
  {
    id: 'partner-capture',
    tooltip:
      'An efficient way to get down short drops as an alternative to rappelling. One or two canyoneers help to lower another canyoneer by pressing him/her against the cliff to provide friction and slowly lower the canyoneer to the ground.',
    jsx: (
      <>
        An efficient way to get down short drops as an alternative to
        rappelling. One or two canyoneers help to lower another canyoneer by
        pressing him/her against the cliff to provide friction and slowly lower
        the canyoneer to the ground.
      </>
    ),
    terms: ['partner-capture'],
    article: 'partner-capture',
  },
  {
    id: 'pass-a-knot',
    tooltip:
      'An advanced technique that allows a rappeller to safely descend past a knot in a rope',
    jsx: (
      <>
        An advanced technique that allows a rappeller to safely descend past a
        knot in a rope. The knot is usually there to isolate a{' '}
        <Link to={routes.glossary_core_shot}>core shot</Link> or join two ropes.
      </>
    ),
    terms: ['pass-a-knot'],
  },
  {
    id: 'peak',
    tooltip: 'Top of a mountain',
    jsx: (
      <>
        Top of a <Link to={routes.glossary_mountain}>mountain</Link>.
      </>
    ),
    terms: ['peak'],
  },
  {
    id: 'personal-locator-beacon',
    tooltip:
      'A device that sends your GPS location and status to the outside world via satellite',
    jsx: (
      <>
        A device that sends your GPS location and status to the outside world
        via satellite. Brands I know of are{' '}
        <Link to={routes.glossary_spot_plb}>SPOT</Link> and{' '}
        <Link to={routes.glossary_inreach}>Garmin inReach</Link>.
      </>
    ),
    terms: ['personal-locator-beacon', 'plb'],
  },
  {
    id: 'petroglyph',
    tooltip: 'Prehistoric carving on the canyon walls',
    jsx: (
      <>
        Prehistoric carving on the canyon walls. Different than a{' '}
        <Link to={routes.glossary_pictograph}>pictograph</Link>.
      </>
    ),
    terms: ['petroglyph'],
  },
  {
    id: 'pick-off',
    tooltip: 'A technique to aid a rappeller down to the bottom of a rappel',
    jsx: (
      <>
        A technique to aid a rappeller down to the bottom of a rappel. It
        typically involves a rescuer on a second rappel rope that descends to
        the stuck rappeller, transfers the rappeller's weight onto the rescuer's
        rappel device, and then finishes the rappel{' '}
        <Link to={routes.glossary_tandem_rappel}>tandem</Link>.
      </>
    ),
    terms: ['pick-off'],
  },
  {
    id: 'picket',
    tooltip:
      'An anchor that consists of a line of sticks pushed into the ground',
    jsx: (
      <>
        An <Link to={routes.glossary_anchor}>anchor</Link> that consists of a
        line of sticks pushed into the ground. Seldom used. Or simply a stick
        used like a <Link to={routes.glossary_chockstone}>chockstone</Link>.
      </>
    ),
    terms: ['picket'],
  },
  {
    id: 'picpocket',
    tooltip: 'Imlay Canyon Gear brand name of the chest pouch',
    jsx: (
      <>
        Imlay Canyon Gear brand name of the{' '}
        <Link to={routes.glossary_chest_pouch}>chest pouch</Link>.
      </>
    ),
    terms: ['picpocket'],
  },
  {
    id: 'pictograph',
    tooltip: 'Prehistoric painting on the canyon walls',
    jsx: (
      <>
        Prehistoric painting on the canyon walls. Different than a{' '}
        <Link to={routes.glossary_petroglyph}>petroglyph</Link>.
      </>
    ),
    terms: ['pictograph'],
  },
  {
    id: 'piggyback',
    tooltip:
      'Haul system that is secured to the rappel line via a rope grab rather than using the rappel line directly',
    jsx: (
      <>
        <Link to={routes.glossary_haul_system}>Haul system</Link> that is
        secured to the <Link to={routes.glossary_rappel_line}>rappel line</Link>{' '}
        via a <Link to={routes.glossary_rope_grab}>rope grab</Link> rather than
        using the rappel line directly.
      </>
    ),
    terms: ['pig-rig', 'piggyback'],
  },
  {
    id: 'piton',
    tooltip: 'A metal peg or spike hammered into the rock for use as an anchor',
    jsx: (
      <>
        A metal peg or spike hammered into the rock for use as an{' '}
        <Link to={routes.glossary_anchor}>anchor</Link>.
      </>
    ),
    terms: ['piton'],
  },
  {
    id: 'plaquette',
    tooltip: 'A way to use a rigging plate as a progress capture device',
    jsx: (
      <>
        A way to use an <Link to={routes.glossary_atc}>ATC-XP</Link>, Kong GiGi,{' '}
        <Link to={routes.glossary_totem}>Totem</Link>, or similar rigging plate
        as a{' '}
        <Link to={routes.glossary_progress_capture_device}>
          progress capture device
        </Link>
        . Pulling out the slack in the rope will cause the rope to pinch against
        itself.
      </>
    ),
    terms: ['plaquette'],
  },
  {
    id: 'pnw',
    tooltip: 'Pacific Northwest',
    jsx: (
      <>
        <Link to="http://ropewiki.com/Pacific_Northwest">
          Pacific Northwest
        </Link>{' '}
        - a canyoneering region.
      </>
    ),
    terms: ['pnw'],
  },
  {
    id: 'poach',
    tooltip: 'Descend a canyon without authorization from the managing agency',
    jsx: <>Descend a canyon without authorization from the managing agency.</>,
    terms: ['poach'],
  },
  {
    id: 'polyester',
    tooltip:
      "Rope material with a low melting point and must be weaved tight to be strong which translate to stiff rope. Doesn't hold much water so good for wet canyons.",
    jsx: (
      <>
        Rope material with a low melting point and must be weaved tight to be
        strong which translate to stiff rope. Doesn't hold much water so good
        for wet canyons.
      </>
    ),
    terms: ['polyester'],
  },
  {
    id: 'polypropylene',
    tooltip:
      "Rope material that doesn't hold water and has a low melting point",
    jsx: (
      <>Rope material that doesn't hold water and has a low melting point.</>
    ),
    terms: ['polypropylene'],
  },
  {
    id: 'pot-sticker',
    tooltip:
      'CanyonWerks brand name for the sand bag - a sewn bag designed to hold sand. Its primary function is to be a counterweight when getting out of potholes.',
    jsx: (
      <>
        <Link to={routes.glossary_canyonwerks}>CanyonWerks</Link> brand name for
        the <Link to={routes.glossary_sand_bag}>sand bag</Link>.
      </>
    ),
    terms: ['pot-sticker'],
  },
  {
    id: 'potable',
    tooltip: 'Drinkable in regards to water',
    jsx: <>Drinkable in regards to water.</>,
    terms: ['potable'],
  },
  {
    id: 'pothole',
    tooltip: 'A hole in the canyon floor',
    jsx: (
      <>
        A hole in the canyon floor. See{' '}
        <Link to={routes.glossary_keeper_pothole}>keeper pothole</Link>.
      </>
    ),
    terms: ['pothole'],
  },
  {
    id: 'pothole-escape-technique',
    tooltip:
      'The act of getting out of a keeper pothole in a safe and efficient manner',
    jsx: (
      <>
        The act of getting out of a{' '}
        <Link to={routes.glossary_keeper_pothole}>keeper pothole</Link> in a
        safe and efficient manner. Some techniques are{' '}
        <Link to={routes.glossary_partner_assist}>partner assist</Link>,{' '}
        <Link to={routes.glossary_sand_bag}>sand bag</Link> toss,{' '}
        <Link to={routes.glossary_pack_toss}>pack toss</Link>, and{' '}
        <Link to={routes.glossary_hooking}>hooking</Link>.
      </>
    ),
    terms: ['pothole-escape-technique'],
  },
  {
    id: 'potshot',
    tooltip:
      'Imlay Canyon Gear brand name for the sand bag - a sewn bag designed to hold sand. Its primary function is to be a counterweight when getting out of potholes.',
    jsx: (
      <>
        <Link to="http://www.store.canyoneeringusa.com/index.php?option=com_rokecwid&Itemid=108&view=ecwid&ecwid_product_id=10899993#ecwid:category=0&mode=product&product=10899993">
          Imlay Canyon Gear
        </Link>{' '}
        brand name for the <Link to={routes.glossary_sand_bag}>sand bag</Link>.
      </>
    ),
    terms: ['potshot'],
  },
  {
    id: 'progress-capture-device',
    tooltip:
      'Progress Capture Device - an ascender and pulley used in a haul system',
    jsx: (
      <>
        An <Link to={routes.glossary_ascender}>ascender</Link> and{' '}
        <Link to={routes.glossary_pulley}>pulley</Link> used in a{' '}
        <Link to={routes.glossary_haul_system}>haul system</Link>.
      </>
    ),
    terms: ['pcd', 'progress-capture-device', 'ratchet'],
  },
  {
    id: 'prusik',
    tooltip: 'The most common friction hitch',
    jsx: (
      <>
        The most common{' '}
        <Link to={routes.glossary_friction_hitch}>friction hitch</Link>. Tied
        with a closed loop of cord that is{' '}
        <Link to={routes.glossary_girth_hitch}>girth hitched</Link> three times
        around a rope. Best used on a rope where the hitch needs to grab in
        either direction. Named after Austrian mountaineer Karl Prusik.
      </>
    ),
    terms: ['prusik'],
  },
  {
    id: 'prusik-minding-pulley',
    tooltip:
      'Prusik Minding Pulley - pulley that allows a Prusik to be self-minding in a haul system',
    jsx: (
      <>
        <Link to={routes.glossary_pulley}>Pulley</Link> that allows a{' '}
        <Link to={routes.glossary_prusik}>Prusik</Link> to be{' '}
        <Link to={routes.glossary_self_minding}>self-minding</Link> in a{' '}
        <Link to={routes.glossary_haul_system}>haul system</Link>.
      </>
    ),
    terms: ['pmp', 'prusik-minding-pulley'],
  },
  {
    id: 'pull',
    tooltip:
      'The art of pulling down and retrieving your rope after a rappel without it getting stuck',
    jsx: (
      <>
        The art of pulling down and retrieving your rope after a rappel without
        it getting stuck.
      </>
    ),
    terms: ['pull'],
    article: 'pull',
  },
  {
    id: 'pull-cord',
    tooltip:
      'General term for rope, cordelette, or dyneema cord used to retrieve the rappel line',
    jsx: (
      <>
        General term for <Link to={routes.glossary_rope}>rope</Link>,{' '}
        <Link to={routes.glossary_cordelette}>cordelette</Link>, or{' '}
        <Link to={routes.glossary_dyneema}>Dyneema</Link> cord used to retrieve
        the <Link to={routes.glossary_rappel_line}>rappel line</Link>.
      </>
    ),
    terms: ['pull-cord'],
  },
  {
    id: 'pull-side',
    tooltip:
      'The side of a rope that will be pulled as opposed to the rappel side',
    jsx: (
      <>
        The side of a rope that will be{' '}
        <Link to={routes.glossary_pull}>pulled</Link> as opposed to the{' '}
        <Link to={routes.glossary_rappel_side}>rappel side</Link>.
      </>
    ),
    terms: ['pull-side'],
  },
  {
    id: 'pulley',
    tooltip: 'Used for hauling and ascending',
    jsx: (
      <>
        A wheel on an axle that is designed to support movement and change of
        direction of a taut cable.{' '}
        <Link to="https://en.wikipedia.org/wiki/Pulley">
          Definition from Wikipedia
        </Link>
        . Used for <Link to={routes.glossary_haul_system}>hauling</Link> and{' '}
        <Link to={routes.glossary_ascend}>ascending</Link> to reduce energy loss
        from friction.
      </>
    ),
    terms: ['pulley'],
  },
  {
    id: 'purcell-prusik',
    tooltip:
      'An adjustable loop secured by a Prusik that can be used in many applications',
    jsx: (
      <>
        An adjustable loop secured by a{' '}
        <Link to={routes.glossary_prusik}>Prusik</Link> that can be used in many
        applications. Examples are{' '}
        <Link to={routes.glossary_safety_tether}>safety tether</Link>,{' '}
        <Link to={routes.glossary_foot_loop}>foot loop</Link>,{' '}
        <Link to={routes.glossary_load_releasing_hitch}>
          load releasing hitch
        </Link>
        , <Link to={routes.glossary_friction_hitch}>friction hitch</Link>,{' '}
        <Link to={routes.glossary_lift_and_shift}>lift and shift</Link>,{' '}
        <Link to={routes.glossary_haul_system}>haul system</Link>, and probably
        many other things.
      </>
    ),
    terms: ['purcell-prusik'],
  },
];

export default definitions;
