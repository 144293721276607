// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'a-water-rating',
    tooltip:
      'Water levels are anything from completely dry to waist deep water. No swimming is required.',
    jsx: (
      <>
        Water levels are anything from completely dry to waist deep water. No
        swimming is required.
      </>
    ),
    terms: ['a-water-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'abc',
    tooltip: 'Advanced Base Camp - climbing gear brand',
    jsx: (
      <>
        Advanced Base Camp - climbing gear brand manufactured by{' '}
        <Link to="http://www.libertymountain.com/">Liberty Mountain</Link>.
      </>
    ),
    terms: ['abc'],
  },
  {
    id: 'aca-canyon-rating-system',
    tooltip:
      'Concise way to represent some of the important information about any given canyon',
    jsx: (
      <>
        The most well-known canyon rating system in the United States. It is a
        concise way to represent some of the important information about any
        given canyon. Created by the{' '}
        <Link to={routes.glossary_american_canyoneering_association}>ACA</Link>.
      </>
    ),
    terms: ['aca-canyon-rating-system'],
    article: 'canyon-rating-system',
  },
  {
    id: 'aguanchor',
    tooltip: 'The original water anchor',
    jsx: (
      <>
        The original <Link to={routes.glossary_water_anchor}>water anchor</Link>
        .
      </>
    ),
    terms: ['aguanchor'],
  },
  {
    id: 'american-canyoneering-association',
    tooltip:
      'American Canyoneering Association - certifies canyon leaders and guides',
    jsx: 'Certifies canyon leaders and guides. Based out of Escalante, Utah.',
    terms: ['american-canyoneering-association'],
    article: {
      title: 'Canyoneering.net',
      route: 'https://www.canyoneering.net/',
    },
  },
  {
    id: 'american-death-triangle',
    tooltip:
      'Webbing rigged on two anchors that forms a triangle. It is not redundant, significantly increases load on each anchor point, and changes the direction of pull for each anchor point.',
    jsx: (
      <>
        <Link to={routes.glossary_webbing}>Webbing</Link> rigged on two{' '}
        <Link to={routes.glossary_anchor}>anchors</Link> that forms a triangle.
        It is not <Link to={routes.glossary_redundant}>redundant</Link>,
        significantly increases load on each anchor point, and changes the
        direction of pull for each anchor point.
      </>
    ),
    terms: ['american-death-triangle'],
    article: {
      title: 'American Death Triangle on Wikipedia',
      route: 'https://en.wikipedia.org/wiki/American_death_triangle',
    },
  },
  {
    id: 'anchor',
    tooltip:
      "Something that stays put at the top of every rappel that attaches to the rappel rope so the rappeller doesn't fall",
    jsx: (
      <>
        System at the top of every <Link to="#rappel">rappel</Link> made up of
        1) something that stays put while loaded and 2) the{' '}
        <Link to={routes.glossary_rigging}>rigging</Link> that holds the rappel
        rope so canyoneers can safely rappel down.{' '}
        <Link to={routes.glossary_dear}>DEAR</Link> anchors are ideal.
        <br />
        Examples of anchors are <Link to={routes.glossary_bolt}>
          bolts
        </Link>,{' '}
        <Link to={routes.glossary_natural_anchor}>natural anchors</Link>, and{' '}
        <Link to={routes.glossary_transient_anchor}>transient anchors</Link>.
      </>
    ),
    terms: ['anchor'],
    article: 'anchors',
  },
  {
    id: 'angle',
    tooltip:
      'One of the qualities of EARNEST rigging. Angle between strands of webbing should be less than 90° or so.',
    jsx: (
      <>
        One of the qualities of EARNEST rigging. The angle between the widest
        pieces of <Link to={routes.glossary_webbing}>webbing</Link> on an anchor
        should be 90° or less.
      </>
    ),
    terms: ['angle'],
    article: 'earnest',
  },
  {
    id: 'ape-index',
    tooltip: 'Arm span - used to calculate how much rope will be deployed',
    jsx: (
      <>
        The distance from one hand to the other when they are outstretched. Used
        when{' '}
        <Link to={routes.glossary_set_rope_length}>setting rope length</Link>.
        For example, given a forty foot rappel, a canyoneer with an ape index of
        five feet will need eight arm lengths of rope to reach the bottom of the
        rappel.
      </>
    ),
    terms: ['ape-index'],
  },
  {
    id: 'approach',
    tooltip: 'The part of the route before the canyon',
    jsx: (
      <>
        The part between the beginning of the hiking and the start of the{' '}
        <Link to={routes.glossary_technical_section}>technical section</Link>.
        The exact definition varies between{' '}
        <Link to={routes.glossary_beta_provider}>beta providers</Link> and
        canyons.
      </>
    ),
    terms: ['approach'],
  },
  {
    id: 'arch',
    tooltip:
      'A naturally occurring curved rock structure that spans an elevated space',
    jsx: (
      <>
        A natural arch is a rock exposure that has a hole completely through it
        formed by the natural, selective removal of rock, leaving a relatively
        intact frame (definition from The{' '}
        <Link to="http://www.naturalarches.org/archinfo/faq.htm#whatis">
          Natural Arch and Bridge Society
        </Link>
        ).
        <br />
        See <Link to={routes.glossary_rock_formation}>rock formation</Link>.
      </>
    ),
    terms: ['arch'],
  },
  {
    id: 'armor',
    tooltip:
      'Any or all of knee pads, elbow pads, butt protector, gloves, helmet, shin and forearm pads. Protects the canyoneer on difficult down climbs or extended stemming sections.',
    jsx: (
      <>
        Any or all of knee pads, elbow pads,{' '}
        <Link to={routes.glossary_butt_protector}>butt protector</Link>,{' '}
        <Link to={routes.glossary_gloves}>gloves</Link>,{' '}
        <Link to={routes.glossary_helmet}>helmet</Link>, shin and forearm pads.
        Protects the canyoneer on difficult{' '}
        <Link to={routes.glossary_down_climb}>down climbs</Link> or extended{' '}
        <Link to={routes.glossary_stemming}>stemming</Link> sections.
      </>
    ),
    terms: ['armor'],
  },
  {
    id: 'art',
    tooltip:
      'Anchors, Rigging, Techniques - canyoneering course offered by Canyons and Crags',
    jsx: (
      <>
        <Link to={routes.glossary_anchor}>
          <strong>A</strong>nchors
        </Link>
        ,{' '}
        <Link to={routes.glossary_rigging}>
          <strong>R</strong>igging
        </Link>
        , <strong>T</strong>echniques - canyoneering course offered by{' '}
        <Link to="https://canyonsandcrags.com/">Canyons and Crags</Link>.
      </>
    ),
    terms: ['art'],
  },
  {
    id: 'ascend',
    tooltip: 'Travel up a rope using ascenders',
    jsx: (
      <>
        Travel up a rope using{' '}
        <Link to={routes.glossary_ascender}>ascenders</Link>.
      </>
    ),
    terms: ['ascend', 'jug'],
  },
  {
    id: 'ascender',
    tooltip: 'A mechanical rope grab or friction hitch used to ascend',
    jsx: (
      <>
        A mechanical <Link to={routes.glossary_rope_grab}>rope grab</Link> or{' '}
        <Link to={routes.glossary_friction_hitch}>friction hitch</Link> used to{' '}
        <Link to={routes.glossary_ascend}>ascend</Link>.
      </>
    ),
    terms: ['ascender'],
  },
  {
    id: 'association-for-canyoneering-education',
    tooltip:
      'Association for Canyoneering Education - certifies canyon leaders and guides',
    jsx: 'Certifies canyon leaders and guides. Based out of Las Vegas, Nevada.',
    terms: ['ace', 'association-for-canyoneering-education'],
    article: {
      title: 'Association for Canyoneering Education',
      route: 'https://acecanyoneering.com/',
    },
  },
  {
    id: 'atc',
    tooltip: 'Tube-style belay and rappel device',
    jsx: (
      <>
        Tube-style belay and{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link>. Most
        canyoneers opt for canyoneering specific rappel devices. Stands for{' '}
        <strong>A</strong>ir <strong>T</strong>raffic <strong>C</strong>
        ontroller.
      </>
    ),
    terms: ['atc'],
  },
  {
    id: 'ats',
    tooltip:
      'Sterling brand figure 8 style canyoneering specific rappel device',
    jsx: (
      <>
        <Link to="https://sterlingrope.com/store/work/hardware/descent-control-rigging/ats">
          Sterling
        </Link>{' '}
        brand <Link to={routes.glossary_figure_8_rappel_device}>figure 8</Link>{' '}
        style canyoneering specific{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link>. I{' '}
        <em>think</em> it stands for <strong>A</strong>lpine <strong>T</strong>
        raining <strong>S</strong>ystems.
      </>
    ),
    terms: ['ats'],
    article: {
      title: 'Alpine Training Systems',
      route: 'http://theatsteam.com/',
    },
  },
  {
    id: 'atwood-gear',
    tooltip: 'Canyoneering Gear Manufacturer',
    jsx: (
      <>
        Canyoneering Gear Manufacturer responsible for tools such as the{' '}
        <Link to={routes.glossary_chester}>Chester</Link>,{' '}
        <Link to={routes.glossary_sand_trap_anchor}>Sand Trap Anchor</Link>, and{' '}
        <Link to={routes.glossary_waterpocket}>Waterpocket</Link>.
      </>
    ),
    terms: ['atwood-gear'],
    article: {
      title: 'Atwood Gear website',
      route: 'https://atwoodgear.com/',
    },
  },
  {
    id: 'autoblock',
    tooltip:
      'A friction hitch that is commonly used below the rappel device for a self belay',
    jsx: (
      <>
        A <Link to={routes.glossary_friction_hitch}>friction hitch</Link> that
        is commonly used below the{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> for a{' '}
        <Link to={routes.glossary_self_belay}>self belay</Link>.
      </>
    ),
    terms: ['autoblock'],
  },
];

export default definitions;
