// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'z-rig',
    tooltip: 'A way to add more friction while on rappel',
    jsx: (
      <>
        A way to add more friction while on rappel where the rappeller puts the{' '}
        <Link to={routes.glossary_rappel_line}>rappel line</Link> through a{' '}
        <Link to={routes.glossary_carabiner}>carabiner</Link> in the{' '}
        <Link to={routes.glossary_leg_loop}>leg loop</Link> and then through
        another carabiner on the rope above the{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link>, creating
        a Z shape in rappel line. This can be done while on rappel and is a
        common way to add friction on non-canyoneering specific rappel devices.
      </>
    ),
    terms: ['z-rig'],
  },
  {
    id: 'zac',
    tooltip: 'Zion Adventure Company',
    jsx: (
      <>
        Zion Adventure Company - outfitter and guide service in Springdale,
        Utah.
      </>
    ),
    terms: ['zac'],
    article: {
      title: 'Zion Adventure Company',
      route: 'https://www.zionadventures.com/',
    },
  },
  {
    id: 'zion-trifecta',
    tooltip:
      "Imlay, Heaps, and Kolob canyons. If you're crazy you descend them all in one day.",
    jsx: (
      <>
        <Link to="http://ropewiki.com/Imlay_Canyon_(disambiguation)">
          Imlay
        </Link>
        ,{' '}
        <Link to="http://ropewiki.com/Heaps_Canyon_(disambiguation)">
          Heaps
        </Link>
        , and <Link to="http://ropewiki.com/Kolob_Canyon">Kolob</Link> canyons.
        If you're crazy you descend them{' '}
        <Link to="http://www.math.utah.edu/~sfolias/canyontales/tale/?i=ziontrifecta">
          all in one day
        </Link>
        .
      </>
    ),
    terms: ['zion-trifecta'],
  },
  {
    id: 'zions',
    tooltip: 'Wrong way to say Zion',
    jsx: (
      <>
        Zions Bancorporation is a bank holding company headquartered in Salt
        Lake City, Utah. Zions is on the list of largest banks in the United
        States.
      </>
    ),
    terms: ['zions'],
    article: {
      title: 'Zions on Wikipedia',
      route: 'https://en.wikipedia.org/wiki/Zions_Bancorporation',
    },
  },
  {
    id: 'zip',
    tooltip: 'Send gear down a cliff or over an obstacle on a tensioned line',
    jsx: (
      <>
        Send gear down a cliff or over an obstacle on a tensioned line. For
        sending people, use a{' '}
        <Link to={routes.glossary_guided_rappel}>guided rappel</Link>.
      </>
    ),
    terms: ['zip'],
  },
  {
    id: 'znp',
    tooltip: 'Zion National Park',
    jsx: <>Zion National Park - canyoneering region in Utah.</>,
    terms: ['znp'],
    article: {
      title: 'Zion National Park',
      route: 'https://www.nps.gov/zion/index.htm',
    },
  },
];

export default definitions;
