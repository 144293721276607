// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'galumph',
    tooltip:
      'Quickly traverse a skinny section of canyon in a stemming position by leaning out, catching and holding yourself up with your arms and then swinging your legs forward',
    jsx: (
      <>
        Quickly <Link to={routes.glossary_traverse}>traverse</Link> a skinny
        section of canyon in a{' '}
        <Link to={routes.glossary_stemming}>stemming</Link> position by leaning
        out, catching and holding yourself up with your arms and then swinging
        your legs forward.
      </>
    ),
    terms: ['galumph'],
  },
  {
    id: 'gate',
    tooltip: 'The opening part of the carabiner from the nose to the hinge',
    jsx: (
      <>
        The opening part of the{' '}
        <Link to={routes.glossary_carabiner}>carabiner</Link> from the{' '}
        <Link to={routes.glossary_nose_carabiner}>nose</Link> to the hinge.
      </>
    ),
    terms: ['gate'],
    article: 'carabiner',
  },
  {
    id: 'gcnra',
    tooltip: 'Glen Canyon National Recreation Area',
    jsx: (
      <>
        <Link to="https://www.nps.gov/glca/index.htm">
          Glen Canyon National Recreation Area
        </Link>{' '}
        - canyoneering region in Utah.
      </>
    ),
    terms: ['gcnra'],
  },
  {
    id: 'gear-loop',
    tooltip:
      'Loops, typically attached to the waist belt of the harness, that hold commonly used gear',
    jsx: (
      <>
        Loops, typically attached to the{' '}
        <Link to={routes.glossary_waist_belt}>waist belt</Link> of the{' '}
        <Link to={routes.glossary_harness}>harness</Link>, that hold commonly
        used gear.
      </>
    ),
    terms: ['gear-loop'],
  },
  {
    id: 'geologists-pick',
    tooltip: 'Hammer used to create a small hook hole in the rock',
    jsx: (
      <>
        Hammer used to create a small hook hole in the rock. Considered evil
        since it deliberately damages the rock.
      </>
    ),
    terms: ['g-pick', 'geologists-pick'],
  },
  {
    id: 'ghost',
    tooltip: 'Descend a canyon without leaving any evidence of passage',
    jsx: (
      <>
        Descend a canyon without leaving any evidence of passage. This involves
        use of a{' '}
        <Link to={routes.glossary_retrievable_rigging_system}>
          retrievable rigging system
        </Link>
        , avoiding <Link to={routes.glossary_rope_groove}>rope grooves</Link>,
        scuff marks on the rock, littering, etc. After a rainstorm washes away
        footprints and other evidences of human descent, the canyon will look
        like it has never been descended.
      </>
    ),
    terms: ['ghost'],
  },
  {
    id: 'girth-hitch',
    tooltip:
      'A hitch that is used as a cinch wrap around natural anchors and also for attaching a safety tether to a harness',
    jsx: (
      <>
        A <Link to={routes.glossary_hitch}>hitch</Link> that is used as a{' '}
        <Link to={routes.glossary_cinch_wrap}>cinch wrap</Link> around{' '}
        <Link to={routes.glossary_natural_anchor}>natural anchors</Link> and
        also for attaching a{' '}
        <Link to={routes.glossary_safety_tether}>safety tether</Link> to a{' '}
        <Link to={routes.glossary_harness}>harness</Link>.
      </>
    ),
    terms: ['girth-hitch'],
  },
  {
    id: 'glazing',
    tooltip: 'Shiny and/or stiff parts of a rope that are melted from heat',
    jsx: (
      <>
        Shiny and/or stiff parts of a{' '}
        <Link to={routes.glossary_rope}>rope</Link> that are melted from heat.
        This is usually occurs from contact with a{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> that has
        heated up while on rappel.
      </>
    ),
    terms: ['glazing'],
    article: 'rope',
  },
  {
    id: 'global-positioning-system',
    tooltip:
      'Tells the group where they are if the canyon is wide open and the batteries are good',
    jsx: (
      <>
        Tells the group where they are if the canyon is wide open and the
        batteries are good.
      </>
    ),
    terms: ['global-positioning-system', 'gps'],
  },
  {
    id: 'gloves',
    tooltip:
      'Protection from rope burns while on rappel, traction when down climbing, or warmth in cold water',
    jsx: (
      <>
        Primarily used as protection from rope burns while on rappel. Also used
        for traction and protection when{' '}
        <Link to={routes.glossary_down_climb}>down climbing</Link> or for warmth
        in canyons with lots of cold water.
      </>
    ),
    terms: ['gloves'],
  },
  {
    id: 'glue-in',
    tooltip: 'A type of bolt that is anchored to the wall using glue',
    jsx: (
      <>
        A type of <Link to={routes.glossary_bolt}>bolt</Link> that is anchored
        to the wall using glue as opposed to an{' '}
        <Link to={routes.glossary_expansion_bolt}>expansion bolt</Link>.
        Glue-ins, when placed correctly, are among the strongest and longest
        lasting bolts.
      </>
    ),
    terms: ['glue-in'],
  },
  {
    id: 'gorging',
    tooltip: 'Another name for canyoneering',
    jsx: (
      <>
        Another name for{' '}
        <Link to={routes.glossary_canyoneering}>canyoneering</Link>.
      </>
    ),
    terms: ['gorging'],
  },
  {
    id: 'gorp',
    tooltip: 'Good old raisins and peanuts',
    jsx: (
      <>
        <strong>G</strong>ood <strong>O</strong>ld <strong>R</strong>aisins and{' '}
        <strong>P</strong>eanuts. Same as trail mix.
      </>
    ),
    terms: ['gorp'],
  },
  {
    id: 'grappling-hook',
    tooltip: 'Black Diamond brand larger-size aid climbing hook',
    jsx: (
      <>
        <Link to="https://www.blackdiamondequipment.com/en/grappling-hook-BD5202250000ALL1.html">
          Black Diamond
        </Link>{' '}
        brand larger-size aid climbing{' '}
        <Link to={routes.glossary_hook}>hook</Link>.
      </>
    ),
    terms: ['grappling-hook'],
  },
  {
    id: 'gsenm',
    tooltip: 'Grand Staircase-Escalante National Monument',
    jsx: (
      <>
        <Link to="https://en.wikipedia.org/wiki/Grand_Staircase-Escalante_National_Monument">
          Grand Staircase-Escalante National Monument
        </Link>{' '}
        - canyoneering region.
      </>
    ),
    terms: ['gsenm'],
  },
  {
    id: 'guide-hand',
    tooltip: 'The free hand of a rappeller',
    jsx: (
      <>
        The free hand of a rappeller. Usually the non-dominant hand. Opposite of{' '}
        <Link to={routes.glossary_brake_hand}>brake hand</Link>.
      </>
    ),
    terms: ['guide-hand'],
  },
  {
    id: 'guide-line',
    tooltip:
      'A tensioned rope that holds the rappeller away from the wall when hauling or lowering',
    jsx: (
      <>
        A tensioned rope that holds the rappeller away from the wall when
        hauling or lowering. Used in{' '}
        <Link to={routes.glossary_guided_rappel}>guided rappels</Link>.
      </>
    ),
    terms: ['guide-line', 'tag-line', 'track-line'],
  },
  {
    id: 'guided-rappel',
    tooltip:
      'Rappel with a guide line that directs the rappeller over some hazard',
    jsx: (
      <>
        Rappel with a <Link to={routes.glossary_guide_line}>guide line</Link>{' '}
        that directs the
        <Link to={routes.glossary_rappeller}>rappeller</Link> over some hazard.
      </>
    ),
    terms: ['guided-rappel'],
  },
  {
    id: 'guitar-hero',
    tooltip:
      "Grabbing the carabiner attached to one's rappel device and the rappel rope with their guide hand as a way of applying friction while on rappel",
    jsx: (
      <>
        Grabbing the <Link to={routes.glossary_carabiner}>carabiner</Link>{' '}
        attached to one's{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> and the{' '}
        <Link to={routes.glossary_rappel_line}>rappel line</Link> with their{' '}
        <Link to={routes.glossary_guide_hand}>guide hand</Link> as a way of
        adding friction while on <Link to={routes.glossary_rappel}>rappel</Link>
        . Kind of looks like someone strumming a guitar only by moving their
        fingers and with the wrong hand.
      </>
    ),
    terms: ['guitar-hero'],
  },
];

export default definitions;
