// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'kayenta-sandstone',
    tooltip: 'Red/brown colored, ledge-forming sandstone',
    jsx: (
      <>
        Red/brown colored, ledge-forming{' '}
        <Link to={routes.glossary_sandstone}>sandstone</Link>.
      </>
    ),
    terms: ['kayenta-sandstone'],
  },
  {
    id: 'keeper-pothole',
    tooltip: 'A hole in the canyon floor that is difficult to get out of',
    jsx: (
      <>
        A hole in the canyon floor that is difficult to get out of. Many{' '}
        <Link to={routes.glossary_pothole_escape_technique}>
          pothole escape techniques
        </Link>{' '}
        have been developed to overcome these difficult obstacles.
      </>
    ),
    terms: ['keeper-pothole'],
  },
  {
    id: 'keg',
    tooltip:
      'Hard waterproof container with screw-on lid used to keep things dry while canyoneering',
    jsx: (
      <>
        Hard waterproof container with screw-on lid used to keep things dry
        while canyoneering. Similar purpose to a{' '}
        <Link to={routes.glossary_dry_bag}>dry bag</Link>.
      </>
    ),
    terms: ['keg'],
  },
  {
    id: 'kelsey-exit',
    tooltip:
      'One of many exits popularized my Michael Kelsey that entails strenuous, steep scrambling to escape',
    jsx: (
      <>
        One of many <Link to={routes.glossary_exit}>exits</Link> popularized my
        Michael Kelsey that entails strenuous, steep{' '}
        <Link to={routes.glossary_scrambling}>scrambling</Link> to escape.
      </>
    ),
    terms: ['kelsey-exit'],
  },
  {
    id: 'kernmantle-rope',
    tooltip: 'A rope that has both a core and a sheath',
    jsx: (
      <>
        A <Link to={routes.glossary_rope}>rope</Link> that has both a{' '}
        <Link to={routes.glossary_core}>core</Link> and a{' '}
        <Link to={routes.glossary_sheath}>sheath</Link>. All canyoneering ropes
        are kernmantle ropes. Kern means core and mantle means sheath in German.
      </>
    ),
    terms: ['kernmantle-rope'],
    article: 'rope',
  },
  {
    id: 'kilonewton',
    tooltip:
      'Unit of measurement often seen on canyoneering gear. 1 kN = 224.809 lbf and 22.2 kN = 5000 lbf.',
    jsx: (
      <>
        Unit of measurement often seen on canyoneering gear. 1 kN = 224.809 lbf
        and 22.2 kN = 5000 lbf.
      </>
    ),
    terms: ['kilonewton', 'kn'],
  },
  {
    id: 'knot',
    tooltip: 'A fastening made by tying a piece of rope or webbing',
    jsx: (
      <>
        A fastening made by tying a piece of rope or webbing (
        <Link to="https://www.google.com/search?q=knot">
          definition from Google
        </Link>
        ). Knot is a superset of <Link to={routes.glossary_bend}>bends</Link>{' '}
        and <Link to={routes.glossary_hitch}>hitches</Link>.
      </>
    ),
    terms: ['knot'],
  },
  {
    id: 'knot-block',
    tooltip: 'A static block that uses a knot as the block',
    jsx: (
      <>
        A <Link to={routes.glossary_static_block}>static block</Link> that uses
        a <Link to={routes.glossary_knot}>knot</Link> as the block. Not commonly
        used on the{' '}
        <Link to={routes.glossary_colorado_plateau}>Colorado Plateau</Link>{' '}
        because the knot can either get sucked into the{' '}
        <Link to={routes.glossary_rapide}>rapide</Link> and make the{' '}
        <Link to={routes.glossary_pull}>pull</Link> impossible or get sucked{' '}
        <em>through</em> the rapide and cause the rappeller to fall.
      </>
    ),
    terms: ['knot-block'],
  },
  {
    id: 'knot-chock',
    tooltip:
      'An anchor that is created by tying a knot in webbing and wedging it into a crack',
    jsx: (
      <>
        An <Link to={routes.glossary_anchor}>anchor</Link> that is created by
        tying a knot in webbing and wedging it into a crack. Not commonly used
        on the{' '}
        <Link to={routes.glossary_colorado_plateau}>Colorado Plateau</Link>{' '}
        because <Link to={routes.glossary_sandstone}>sandstone</Link> is soft
        and a knot can pull through a crack easily.
      </>
    ),
    terms: ['knot-chock'],
  },
];

export default definitions;
