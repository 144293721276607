// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'jester',
    tooltip: 'A releasable twin rope system that uses the totem',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_releasable_twin_rope_system}>
          releasable twin rope system
        </Link>
        . Uses the <Link to={routes.glossary_totem}>totem</Link> as a linear
        rigging plate. Name derived from the{' '}
        <Link to={routes.glossary_joker}>Joker</Link>.
      </>
    ),
    terms: ['jester'],
  },
  {
    id: 'joint',
    tooltip:
      'A fracture or split in the landscape. This type of landform creates long, straight sections of canyons.',
    jsx: (
      <>
        A fracture or split in the landscape. This type of landform creates
        long, straight sections of canyons.
      </>
    ),
    terms: ['hallway', 'joint'],
  },
  {
    id: 'joker',
    tooltip: 'A releasable twin rope system',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_releasable_twin_rope_system}>
          releasable twin rope system
        </Link>
        .
      </>
    ),
    terms: ['joker'],
  },
  {
    id: 'jumar',
    tooltip: 'Either an Ascender or to ascend',
    jsx: (
      <>
        An <Link to={routes.glossary_ascender}>Ascender</Link> or to{' '}
        <Link to={routes.glossary_ascend}>ascend</Link>.
      </>
    ),
    terms: ['jumar'],
  },
];

export default definitions;
