// @flow
import React, { type Node } from 'react';
import glossaryTermData, { type GlossaryTerm } from '../data/glossary-terms';
import glossaryDefinitionData from '../data/glossary-definitions';
import { term2id } from './key';
import type {
  Article,
  FrontMatter,
  GlossaryDefinition,
  GlossaryInformation,
  Id,
  Url,
} from './types';
import frontMatterMap from '../data/front-matter';
import Link from '../components/link';

const termMap = glossaryTermData.reduce((acc, term) => {
  acc[term.id] = term;
  return acc;
}, {});

const definitionMap = glossaryDefinitionData.reduce((acc, def) => {
  acc[def.id] = def;
  return acc;
}, {});

const getTermIdFromString = (str: string): ?Id => {
  // See if the string is already a valid key
  if (termMap[str]) {
    return termMap[str].id;
  }

  // See if we can convert it to an id
  const id = term2id(str);
  if (termMap[id]) {
    return termMap[id].id;
  }

  // Try removing the last s from the string
  if (id.charAt(id.length - 1) === 's') {
    const no_s = id.substring(0, id.length - 1);
    if (termMap[no_s]) {
      return termMap[no_s].id;
    }
  }

  // Try removing the last ing from the string
  if (id.endsWith('ing')) {
    const no_ing = id.substring(0, id.length - 3);
    if (termMap[no_ing]) {
      return termMap[no_ing].id;
    }
  }

  return undefined;
};

//region Get full definition

const getTermString = (termId: Id, terms: Array<Id>): string => {
  const filteredTerms = terms.filter((id) => id !== termId);

  const names = filteredTerms.map((id) => termMap[id].name);

  if (names.length === 0) {
    return '';
  }

  if (names.length === 2) {
    return ` Same as ${names[0]} and ${names[1]}.`;
  }

  return ` Same as ${names.join(', ')}.`;
};

const getInternalArticleLink = (article: string): ?Node => {
  const split = article.split('#');
  const frontMatter: FrontMatter = frontMatterMap[split[0]];
  const hash = split[1] ? `#${split[1]}` : '';

  return (
    <Link to={frontMatter.route + hash} title={frontMatter.preview}>
      {frontMatter.title}
    </Link>
  );
};

const getExternalArticleLink = (article: {
  title: string,
  route: Url,
}): ?Node => {
  return <Link to={article.route}>{article.title}</Link>;
};

const getArticle = (article?: Article): ?Node => {
  if (!article) {
    return undefined;
  }

  const link: ?Node =
    typeof article === 'string'
      ? getInternalArticleLink(article)
      : getExternalArticleLink(article);

  return (
    <>
      {' See '}
      {link}
      {' for detailed information.'}
    </>
  );
};

const getQuoteDefinition = (
  term: GlossaryTerm,
  definition: GlossaryDefinition
): Node => {
  const sameAs = getTermString(term.id, definition.terms);

  return (
    <>
      {definition.jsx}
      {term.specific && <> {term.specific}</>}
      {sameAs && (
        <>
          <br />
          {sameAs}
        </>
      )}
    </>
  );
};

const getFullDefinition = (
  term: GlossaryTerm,
  definition: GlossaryDefinition
): Node => {
  const quoteDefinition = getQuoteDefinition(term, definition);
  const article = getArticle(definition.article);

  return (
    <>
      {quoteDefinition}
      {article && (
        <>
          <br />
          {article}
        </>
      )}
    </>
  );
};

//endregion Get full definition

const getGlossaryInformation = (term: GlossaryTerm): GlossaryInformation => {
  const definition = definitionMap[term.definition];

  const quoteDefinition = getQuoteDefinition(term, definition);
  const fullDefinition = getFullDefinition(term, definition);

  return {
    termId: term.id,
    definitionId: definition.id,
    name: term.name,
    route: `/glossary#${term.id}`,
    tooltip: definition.tooltip,
    fullDefinition,
    quoteDefinition,
  };
};

const map: { [termId: Id]: GlossaryInformation } = glossaryTermData.reduce(
  (acc, term) => {
    acc[term.id] = getGlossaryInformation(term);
    return acc;
  },
  {}
);

export const getGlossaryDefinition = (str: string): ?GlossaryInformation => {
  const termId = getTermIdFromString(str);
  if (termId) {
    return map[termId];
  } else {
    console.error(`Unable to find glossary term for string: ${str}`);
    return undefined;
  }
};

export const allDefinitions: Array<GlossaryInformation> = Object.keys(map).map(
  (key) => map[key]
);

export const getTermCount = (): number => {
  return glossaryTermData.length;
};

export const getDefinitionCount = (): number => {
  return glossaryDefinitionData.length;
};
