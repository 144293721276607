// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'wade',
    tooltip: 'Walk through water',
    jsx: <>Walk through water.</>,
    terms: ['wade'],
  },
  {
    id: 'wag-bag',
    tooltip: 'Waste alleviation & gelling bag designed to hold human waste',
    jsx: (
      <>
        <strong>W</strong>aste <strong>A</strong>lleviation & <strong>G</strong>
        elling bag. Double-walled human waste container and has some gel to
        neutralize the dangerous pathogens in human waste.
      </>
    ),
    terms: ['wag-bag'],
    article: {
      title: "Coming to terms with the poop problem in Utah's outdoors",
      route:
        'https://www.ksl.com/article/50219376/coming-to-terms-with-the-poop-problem-in-utahs-outdoors',
    },
  },
  {
    id: 'waist-belt',
    tooltip: 'Part of the harness that goes around and supports the waist',
    jsx: (
      <>
        Part of the <Link to={routes.glossary_harness}>harness</Link> that goes
        around and supports the waist. Waist belts are rated to carry
        rappellers.
      </>
    ),
    terms: ['waist-belt'],
  },
  {
    id: 'wanchor',
    tooltip: 'A water anchor created by Steve Woodford',
    jsx: (
      <>
        A <Link to={routes.glossary_water_anchor}>water anchor</Link> created by
        Steve Woodford. Portmanteau of water and anchor. Manufactured by{' '}
        <Link to={routes.glossary_on_rope_canyoneering}>
          On Rope Canyoneering
        </Link>
        .
      </>
    ),
    terms: ['wanchor'],
    article: {
      title: "W'anchor website",
      route: 'http://www.onropecanyoneering.com/canyoneering-gear/wanchor',
    },
  },
  {
    id: 'water-anchor',
    tooltip:
      'A retrievable rigging system that uses water as the anchor material',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_retrievable_rigging_system}>
          retrievable rigging system
        </Link>{' '}
        that uses water as the{' '}
        <Link to={routes.glossary_transient_anchor}>transient anchor</Link>{' '}
        material. Fill it with water and rappel off of it. Pull a cord that
        empties the water bladder allowing you to retrieve the rigging.
      </>
    ),
    terms: ['water-anchor'],
  },
  {
    id: 'water-hazard',
    tooltip:
      'Hazards that exist in swift water scenarios: foot entrapment, strainer, siphon, undercut, and recirculating current',
    jsx: (
      <>
        Hazards that exist in{' '}
        <Link to={routes.glossary_swift_water}>swift water</Link> scenarios.
        Hazards are{' '}
        <Link to={routes.glossary_foot_entrapment}>foot entrapment</Link>,{' '}
        <Link to={routes.glossary_strainer}>strainer</Link>,{' '}
        <Link to={routes.glossary_siphon}>siphon</Link>,{' '}
        <Link to={routes.glossary_undercut}>undercut</Link>,{' '}
        <Link to={routes.glossary_recirculating_current}>
          recirculating current
        </Link>
        , and{' '}
        <Link to={routes.glossary_rope_entanglement}>rope entanglement</Link>.
      </>
    ),
    terms: ['water-hazard'],
  },
  {
    id: 'water-knot',
    tooltip: 'Rethreaded overhand knot used to create a loop in webbing',
    jsx: (
      <>
        Rethreaded <Link to={routes.glossary_overhand_knot}>overhand</Link>{' '}
        <Link to={routes.glossary_knot}>knot</Link> used to create a{' '}
        <Link to={routes.glossary_loop}>loop</Link> in{' '}
        <Link to={routes.glossary_webbing}>webbing</Link>.
      </>
    ),
    terms: ['water-knot'],
  },
  {
    id: 'water-rating',
    tooltip: 'The normal amount of water that a canyon holds',
    jsx: (
      <>
        The normal amount of water that a canyon holds. It ranges from dry to
        high flow and hazardous. The rating is a single letter{' '}
        <Link to={routes.glossary_a_water_rating}>A</Link>,{' '}
        <Link to={routes.glossary_b_water_rating}>B</Link>, or{' '}
        <Link to={routes.glossary_c_water_rating}>C</Link>.
      </>
    ),
    terms: ['water-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'waterpocket',
    tooltip: 'Atwood Gear brand name for the water anchor',
    jsx: (
      <>
        <Link to={routes.glossary_atwood_gear}>Atwood Gear</Link> brand name for
        the <Link to={routes.glossary_water_anchor}>water anchor</Link>.
      </>
    ),
    terms: ['waterpocket'],
    article: {
      title: 'Waterpocket website',
      route: 'https://atwoodgear.com/products/waterpocket-anchor',
    },
  },
  {
    id: 'wccm',
    tooltip: 'West Coast Canyoneering Method',
    jsx: (
      <>West Coast Canyoneering Method - trains and certifies canyon guides.</>
    ),
    terms: ['wccm'],
  },
  {
    id: 'webbing',
    tooltip: 'Flat woven fabric primarily used in rigging',
    jsx: (
      <>
        Flat woven fabric primarily used in rigging. The best canyoneering
        webbing is tubular and 1" across.
        <br />
        Because webbing is left behind in a canyon, many canyoneering locations
        require webbing to be specific colors. No location prohibits black
        webbing so it is always a safe choice.
      </>
    ),
    terms: ['tape', 'webbing'],
  },
  {
    id: 'wetsuit',
    tooltip:
      'Close fitting neoprene suit designed to preserve heat while in water',
    jsx: (
      <>Close fitting neoprene suit designed to preserve heat while in water.</>
    ),
    terms: ['wetsuit'],
  },
  {
    id: 'whiptail',
    tooltip: 'In We Go Canyoneering brand name of a toggle',
    jsx: (
      <>
        <Link to="http://inwegocanyoneering.com/whiptail">
          In We Go Canyoneering
        </Link>{' '}
        brand name of a <Link to={routes.glossary_toggle}>toggle</Link>.
      </>
    ),
    terms: ['whiptail'],
  },
  {
    id: 'wingate-sandstone',
    tooltip: 'Towering red cliffs and spires',
    jsx: (
      <>
        <Link to={routes.glossary_sandstone}>Sandstone</Link> that makes
        towering red cliffs and spires. The great walls of{' '}
        <Link to="http://ropewiki.com/Capitol_Reef_National_Park">
          Capitol Reef National Park
        </Link>{' '}
        are Wingate sandstone.
      </>
    ),
    terms: ['wingate-sandstone'],
  },
  {
    id: 'working-end-carabiner',
    tooltip:
      'The versatile end of a carabiner that holds the devices that do things',
    jsx: (
      <>
        The versatile end of a{' '}
        <Link to={routes.glossary_carabiner}>carabiner</Link> that holds the
        devices that do things - devices such as{' '}
        <Link to={routes.glossary_ascender}>ascenders</Link>,{' '}
        <Link to={routes.glossary_rappel_device}>rappel devices</Link>,{' '}
        <Link to={routes.glossary_friction_hitch}>friction hitches</Link>,{' '}
        <Link to={routes.glossary_safety_tether}>safety tethers</Link>, etc. The
        opposite side of the{' '}
        <Link to={routes.glossary_skinny_end}>skinny end</Link>.
      </>
    ),
    terms: ['basket', 'wide-end', 'working-end-carabiner'],
    article: 'carabiner',
  },
  {
    id: 'working-end-rope',
    tooltip: 'The side of the rope that is in active use',
    jsx: (
      <>
        The side of the rope that is in active use. The{' '}
        <Link to={routes.glossary_bag_end}>bag end</Link> is the other side.
      </>
    ),
    terms: ['rappel-end', 'rappel-side', 'working-end-rope'],
  },
  {
    id: 'wrap-3-pull-2',
    tooltip:
      'Cinch wrap where a tree or rock is wrapped 3 times. On one wrap the knot is placed toward the cliff where it will receive the least amount of force. The other two wraps are pulled out and connected to the rapide.',
    jsx: (
      <>
        <Link to={routes.glossary_cinch_wrap}>Cinch wrap</Link> where a{' '}
        <Link to={routes.glossary_natural_anchor}>tree or rock</Link> is wrapped
        3 times. On one wrap the{' '}
        <Link to={routes.glossary_ring_bend}>bend</Link> is placed toward the
        cliff where it will receive the least amount of force. The other two
        wraps are pulled out and connected to the{' '}
        <Link to={routes.glossary_rapide}>rapide</Link>. Very strong rigging.
      </>
    ),
    terms: ['wrap-3-pull-2'],
  },
];

export default definitions;
