// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'off-width',
    tooltip:
      "Off Width - crack that is too wide to fit a hand and too narrow to fit one's body",
    jsx: (
      <>
        Crack that is too wide to fit a hand and too narrow to fit one's body.
      </>
    ),
    terms: ['off-width', 'ow'],
  },
  {
    id: 'on-rope-canyoneering',
    tooltip: 'Canyoneering Gear Manufacturer',
    jsx: (
      <>
        Canyoneering gear manufacturer responsible for the{' '}
        <Link to={routes.glossary_hoodoo}>HOODOO</Link> and{' '}
        <Link to={routes.glossary_wanchor}>W'anchor</Link>.
      </>
    ),
    terms: ['on-rope-canyoneering'],
    article: {
      title: 'On Rope Canyoneering website',
      route: 'http://www.onropecanyoneering.com/',
    },
  },
  {
    id: 'open-end-prusik',
    tooltip:
      'Prusik that has an eye hole on each end of the cord as opposed to a sewn Prusik loop',
    jsx: (
      <>
        <Link to={routes.glossary_prusik}>Prusik</Link> that has an eye hole on
        each end of the cord as opposed to a{' '}
        <Link to={routes.glossary_sewn_prusik_loop}>sewn Prusik loop</Link>.
      </>
    ),
    terms: ['open-end-prusik'],
  },
  {
    id: 'orv',
    tooltip: 'Off Road Vehicle',
    jsx: <>Off road vehicle.</>,
    terms: ['orv'],
  },
  {
    id: 'overhand-knot',
    tooltip:
      'Knot tied by making a loop and feeding one of the tails through it',
    jsx: (
      <>
        Perhaps the simplest knot. It is tied by making a{' '}
        <Link to={routes.glossary_loop}>loop</Link> and feeding one of the{' '}
        <Link to={routes.glossary_tail}>tails</Link> through it.
      </>
    ),
    terms: ['overhand-knot'],
  },
  {
    id: 'overhand-on-a-bight',
    tooltip: 'Make a bight in the rope and tie an overhand knot',
    jsx: (
      <>
        Make a <Link to={routes.glossary_bight}>bight</Link> in the rope and tie
        an <Link to={routes.glossary_overhand_knot}>overhand knot</Link>.
      </>
    ),
    terms: ['overhand-on-a-bight'],
  },
  {
    id: 'overhang',
    tooltip: 'A part of a drop with a greater-than-vertical slope',
    jsx: <>A part of a drop with a greater-than-vertical slope.</>,
    terms: ['overhang'],
  },
];

export default definitions;
