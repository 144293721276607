// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'national-park-service',
    tooltip:
      'National Park Service - Agency that manages land where many of the canyons are located',
    jsx: <>Agency that manages land where many of the canyons are located.</>,
    terms: ['national-park-service', 'nps'],
    article: {
      title: 'National Park Service',
      route: 'https://www.nps.gov/index.htm',
    },
  },
  {
    id: 'natural-anchor',
    tooltip:
      'An anchor made primarily from materials found in nature such as rocks and trees',
    jsx: (
      <>
        An <Link to={routes.glossary_anchor}>anchor</Link> made primarily from
        materials found in nature. Examples are rocks, trees,{' '}
        <Link to={routes.glossary_solution_pocket}>solution pockets</Link>,{' '}
        <Link to={routes.glossary_cairn_anchor}>cairn</Link>,{' '}
        <Link to={routes.glossary_deadman_anchor}>deadman</Link>,{' '}
        <Link to={routes.glossary_chockstone}>chockstone</Link>,{' '}
        <Link to={routes.glossary_sand_trap}>sand</Link>, and{' '}
        <Link to={routes.glossary_water_anchor}>water</Link>.
      </>
    ),
    terms: ['natural-anchor'],
    article: 'single-solid-natural-anchors',
  },
  {
    id: 'natural-bridge',
    tooltip: 'An arch that was likely created with flowing water',
    jsx: (
      <>
        An arch that also has one or more of the following attributes: formed by
        a water current or has water flowing through it, used as a bridge my
        humans, or looks like a man made bridge. From{' '}
        <Link to="http://www.naturalarches.org/archinfo/faq.htm#bridge">
          The Natural Arch and Bridge Society
        </Link>
        .
      </>
    ),
    terms: ['natural-bridge'],
  },
  {
    id: 'navajo-sandstone',
    tooltip:
      'Sandstone layer that is white to tan colored and makes spectacular white domes',
    jsx: (
      <>
        <Link to={routes.glossary_sandstone}>Sandstone</Link> layer that is
        white to tan colored and makes spectacular white domes. Examples are the
        San Rafael Reef and Zion National Park.
      </>
    ),
    terms: ['navajo-sandstone'],
  },
  {
    id: 'neoprene',
    tooltip: 'Flexible, synthetic rubber used in wetsuits',
    jsx: (
      <>
        Flexible, synthetic rubber used in{' '}
        <Link to={routes.glossary_wetsuit}>wetsuits</Link>.
      </>
    ),
    terms: ['neo', 'neoprene'],
  },
  {
    id: 'no-extension',
    tooltip:
      'One of the qualities of EARNEST rigging. If one anchor were to fail, the rigging would not extend and shock load the other anchor.',
    jsx: (
      <>
        One of the qualities of EARNEST rigging. If one anchor were to fail, the
        rigging would not extend and shock load the other anchor.
      </>
    ),
    terms: ['no-extension'],
    article: 'earnest',
  },
  {
    id: 'nose-carabiner',
    tooltip: 'The tip of the carabiner that touches the gate when closed',
    jsx: (
      <>
        The tip of the <Link to={routes.glossary_carabiner}>carabiner</Link>{' '}
        that touches the <Link to={routes.glossary_gate}>gate</Link> when
        closed.
      </>
    ),
    terms: ['nose-carabiner'],
    article: 'carabiner',
  },
  {
    id: 'nuisance-rappel',
    tooltip:
      'Rappel that provides no unique or redeeming qualities when compared to other rappels in that canyon',
    jsx: (
      <>
        <Link to={routes.glossary_rappel}>Rappel</Link> that provides no unique
        or redeeming qualities when compared to other rappels in that canyon.
      </>
    ),
    terms: ['nuisance-rappel'],
  },
  {
    id: 'nylon',
    tooltip:
      'Rope and sling material that is inexpensive, stretchy, and holds water when wet',
    jsx: (
      <>
        Rope and <Link to={routes.glossary_sling}>sling</Link> material that is
        inexpensive, stretchy, and holds water when wet.
      </>
    ),
    terms: ['nylon'],
  },
];

export default definitions;
