// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'i-beam-carabiner',
    tooltip:
      'Carabiner where the cross-section resembles the capital letter I (with crossbars) as opposed to a standard carabiner which resembles an O',
    jsx: (
      <>
        <Link to={routes.glossary_carabiner}>Carabiner</Link> where the
        cross-section resembles the capital letter I (with crossbars) as opposed
        to a standard carabiner which resembles an O. The I-beam carabiner is
        lighter but wears out faster and less consistently where the rope passes
        over it.
      </>
    ),
    terms: ['i-beam-carabiner'],
  },
  {
    id: 'i-time-rating',
    tooltip: 'It should take a few hours to complete the canyon',
    jsx: <>It should take a few hours to complete the canyon.</>,
    terms: ['i-time-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'ibis-hook',
    tooltip: 'Large hook manufactured by Pika Mountaineering',
    jsx: (
      <>
        Large <Link to={routes.glossary_hook}>hook</Link> manufactured by{' '}
        <Link to="http://www.blumental.us/store/products/pikamtncom">
          Pika Mountaineering
        </Link>
        .
      </>
    ),
    terms: ['ibis-hook'],
  },
  {
    id: 'icopro',
    tooltip: 'International Canyoning Organization for Professionals',
    jsx: <>International Canyoning Organization for Professionals.</>,
    terms: ['icopro'],
    article: {
      title: 'ICOpro',
      route: 'http://www.icopro.org',
    },
  },
  {
    id: 'ii-time-rating',
    tooltip: 'It should take about half a day to complete the canyon',
    jsx: <>It should take about half a day to complete the canyon.</>,
    terms: ['ii-time-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'iii-time-rating',
    tooltip: 'It should take most of a day to complete the canyon',
    jsx: <>It should take most of a day to complete the canyon.</>,
    terms: ['iii-time-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'imlay-canyon-gear',
    tooltip:
      'Canyoneering website that provides beta, instruction, trip reports, and store',
    jsx: (
      <>
        Canyoneering website that provides beta, instruction, trip reports, and{' '}
        store. Designer of the{' '}
        <Link to={routes.glossary_sandtrap}>SandTrap</Link>,{' '}
        <Link to={routes.glossary_fiddlestick}>FiddleStick</Link>,{' '}
        <Link to={routes.glossary_potshot}>PotShot</Link>, and{' '}
        <Link to={routes.glossary_scuttlebutt}>ScuttleButt</Link>.
      </>
    ),
    terms: ['canyoneering-usa', 'cusa', 'imlay-canyon-gear'],
    article: {
      title: 'Canyoneering USA/Imlay Canyon Gear website',
      route: 'https://www.canyoneeringusa.com/',
    },
  },
  {
    id: 'inreach',
    tooltip: 'Personal locator beacon manufactured by Garmin',
    jsx: (
      <>
        <Link to={routes.glossary_personal_locator_beacon}>
          Personal locator beacon
        </Link>{' '}
        manufactured by Garmin.
      </>
    ),
    terms: ['inreach'],
  },
  {
    id: 'iv-time-rating',
    tooltip: 'Should be a very long day to complete the canyon',
    jsx: <>It should be a very long day to complete the canyon.</>,
    terms: ['iv-time-rating'],
    article: 'canyon-rating-system',
  },
];

export default definitions;
