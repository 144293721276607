// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'c-water-rating',
    tooltip: 'Flowing water',
    jsx: (
      <>
        <Link to={routes.glossary_swift_water}>Flowing water</Link>.
      </>
    ),
    terms: ['c-water-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'cairn',
    tooltip: 'A human created stack of rocks',
    jsx: (
      <>
        A human created stack of rocks. A cairn either marks the current way to
        go, or simply evidence that someone has been there before and may or may
        not be lost. From the Scottish Gaelic word{' '}
        <Link to="https://en.wikipedia.org/wiki/Cairn">càrn</Link>.
      </>
    ),
    terms: ['cairn'],
  },
  {
    id: 'cairn-anchor',
    tooltip:
      'A human created stack of rocks that is used as an anchor for rappelling',
    jsx: (
      <>
        A <Link to={routes.glossary_cairn}>cairn</Link> that is used as an{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> for rappelling.
      </>
    ),
    terms: ['cairn-anchor'],
  },
  {
    id: 'cam',
    tooltip: 'Spring-loaded camming device',
    jsx: (
      <>
        Either a{' '}
        <Link to={routes.glossary_spring_loaded_camming_device}>
          Spring-loaded camming device
        </Link>{' '}
        or the part of an <Link to={routes.glossary_ascender}>ascender</Link>{' '}
        that pinches the rope.
      </>
    ),
    terms: ['cam'],
  },
  {
    id: 'candition',
    tooltip:
      'Website that provides recent canyon conditions from fellow canyoneers',
    jsx: (
      <>
        Website that provides recent canyon conditions from fellow canyoneers.
        Portmanteau of <strong>can</strong>yon and con<strong>dition</strong>.
      </>
    ),
    terms: ['candition'],
    article: {
      title: 'Candition website',
      route: 'https://www.candition.com/',
    },
  },
  {
    id: 'canyon',
    tooltip:
      'A deep cleft between escarpments or cliffs resulting from weathering and the erosive activity of a river over geologic timescales',
    jsx: (
      <>
        A deep cleft between escarpments or cliffs resulting from weathering and
        the erosive activity of a river over geologic timescales. Definition
        from <Link to="https://en.wikipedia.org/wiki/Canyon">Wikipedia</Link>.
      </>
    ),
    terms: ['canyon'],
  },
  {
    id: 'canyon-collective',
    tooltip: 'Canyoneering website with a very active forum',
    jsx: <>Canyoneering website with a very active forum.</>,
    terms: ['canyon-collective'],
    article: {
      title: 'Canyon Collective',
      route: 'http://canyoncollective.com/',
    },
  },
  {
    id: 'canyon-mode',
    tooltip:
      'A manner of rigging figure 8 style rappel devices where the rope goes through the carabiner instead of around the rappel device',
    jsx: (
      <>
        A manner of rigging{' '}
        <Link to={routes.glossary_figure_8_rappel_device}>figure 8</Link> style{' '}
        <Link to={routes.glossary_rappel_device}>rappel devices</Link> where the
        rope goes through the carabiner instead of around the rappel device.
        Dangerous for some rappel devices and required for others.
      </>
    ),
    terms: ['canyon-mode'],
  },
  {
    id: 'canyon-quickdraw',
    tooltip:
      'A quickdraw that uses locking carabiners instead of non-locking carabiners',
    jsx: (
      <>
        A <Link to={routes.glossary_quickdraw}>quickdraw</Link> that uses oval
        and/or <Link to={routes.glossary_hms_carabiner}>HMS carabiners</Link>{' '}
        instead of non-locking carabiners. Typically used for a{' '}
        <Link to={routes.glossary_safety_rigging}>safety</Link> backup on{' '}
        <Link to={routes.glossary_contingency_8}>contingency 8</Link> rigging.
      </>
    ),
    terms: ['canyon-quickdraw'],
  },
  {
    id: 'canyon-resume',
    tooltip:
      'A list of canyon descents a canyoneer has made, including meta-information about those descents',
    jsx: (
      <>
        A list of canyon descents a canyoneer has made, including
        meta-information about those descents.
      </>
    ),
    terms: ['canyon-resume'],
  },
  {
    id: 'canyon-skid-plate',
    tooltip: 'On Rope Canyoneering brand name for a butt protector',
    jsx: (
      <>
        <Link to={routes.glossary_on_rope_canyoneering}>
          On Rope Canyoneering
        </Link>{' '}
        brand name for a{' '}
        <Link to={routes.glossary_butt_protector}>butt protector</Link>.
      </>
    ),
    terms: ['canyon-skid-plate'],
    article: {
      title: 'Canyon Skid Plate website',
      route:
        'http://www.onropecanyoneering.com/canyoneering-gear/canyon-skid-plate',
    },
  },
  {
    id: 'canyoneer',
    tooltip: 'One who descends technical canyons',
    jsx: (
      <>
        One who descends{' '}
        <Link to={routes.glossary_technical_rating}>technical</Link>{' '}
        <Link to={routes.glossary_slot_canyon}>canyons</Link>.
      </>
    ),
    terms: ['canyoneer', 'canyoner'],
  },
  {
    id: 'canyoneering',
    tooltip: 'The art of descending a canyon',
    jsx: (
      <>
        The art of descending a <Link to={routes.glossary_canyon}>canyon</Link>.
      </>
    ),
    terms: ['canyoneering', 'canyoning'],
  },
  {
    id: 'canyoneering-circle',
    tooltip: 'Group of friends that canyoneer together',
    jsx: <>Group of friends that canyoneer together.</>,
    terms: ['canyoneering-circle'],
  },
  {
    id: 'canyonman',
    tooltip: 'The greatest canyoneer ever',
    jsx: (
      <>
        <Link to="http://www.climb-utah.com/Zion/subway2.htm">
          The greatest canyoneer ever
        </Link>
        .
      </>
    ),
    terms: ['canyonman'],
  },
  {
    id: 'canyonwerks',
    tooltip: 'Defunct canyoneering gear manufacturer',
    jsx: (
      <>
        Defunct canyoneering gear manufacturer that invented the{' '}
        <Link to={routes.glossary_critr}>CRITR</Link> and many other innovative
        creations.
        <br />
        Alas, nothing lasts in canyoneering.
      </>
    ),
    terms: ['canyonwerks'],
  },
  {
    id: 'capsize',
    tooltip: 'When a knot is pulled hard enough that it rolls',
    jsx: (
      <>
        When a non-<Link to={routes.glossary_bend}>bend</Link> knot, such as a{' '}
        <Link to={routes.glossary_european_death_knot}>
          European Death Knot
        </Link>
        , is used to join two ropes, and the knot is pulled hard enough that the
        knot rolls. If it capsizes enough times and the{' '}
        <Link to={routes.glossary_tail}>tails</Link> are not long, it will roll
        off the rope.
      </>
    ),
    terms: ['capsize'],
  },
  {
    id: 'carabiner',
    tooltip:
      'A metal loop with a spring-loaded gate used to connect safety-critical components',
    jsx: (
      <>
        A metal loop with a spring-loaded{' '}
        <Link to={routes.glossary_gate}>gate</Link> used to connect
        safety-critical components. Short for Karabinerhaken which means "spring
        hook" in German. Definition from{' '}
        <Link to="https://en.wikipedia.org/wiki/Carabiner">
          Carabiner article on Wikipedia
        </Link>
        .
      </>
    ),
    terms: ['biner', 'carabiner'],
    article: 'carabiner',
  },
  {
    id: 'care',
    tooltip: 'Capitol Reef National Park',
    jsx: (
      <>
        <Link to="https://www.nps.gov/care/index.htm">
          <strong>CA</strong>pitol <strong>RE</strong>ef National Park
        </Link>{' '}
        - canyoneering region.
      </>
    ),
    terms: ['care'],
  },
  {
    id: 'ce-marking',
    tooltip:
      'Marking on some canyoneering gear that means it meets a specific standard of safety. It also means it can be legally sold in Europe.',
    jsx: (
      <>
        Marking on some canyoneering gear that means it meets a specific
        standard of safety. It also means it can be legally sold in Europe.
        Stands for Conformité Européenne which is French for "European
        Conformity".
      </>
    ),
    terms: ['ce-marking'],
    article: {
      title: 'CE marking on Wikipedia',
      route: 'https://en.wikipedia.org/wiki/CE_marking',
    },
  },
  {
    id: 'cem',
    tooltip: 'A retrievable rigging system that requires only a rope',
    jsx: (
      <>
        <strong>C</strong>añonismo <strong>E</strong>n <strong>M</strong>exico
        (Spanish for Canyoneering in Mexico). A{' '}
        <Link to={routes.glossary_retrievable_rigging_system}>
          retrievable rigging system
        </Link>{' '}
        that requires only a rope. I think this is the most widely used
        rope-only retrievable rigging system.
      </>
    ),
    terms: ['cem'],
  },
  {
    id: 'cfs',
    tooltip: 'Cubic Feet per Second - measures water flow in class C canyons',
    jsx: (
      <>
        <strong>C</strong>ubic <strong>F</strong>eet per <strong>S</strong>
        econd. A measurement of water flow in{' '}
        <Link to={routes.glossary_c_water_rating}>class C</Link> canyons.
      </>
    ),
    terms: ['cfs'],
  },
  {
    id: 'cheater-stick',
    tooltip:
      'Tent pole with a hook attached to one end. Used primarily as a pothole escape technique.',
    jsx: (
      <>
        Tent pole with a <Link to={routes.glossary_hook}>hook</Link> attached to
        one end. Used primarily as a{' '}
        <Link to={routes.glossary_pothole_escape_technique}>
          pothole escape technique
        </Link>
        .
      </>
    ),
    terms: ['cheater-stick'],
  },
  {
    id: 'check',
    tooltip:
      'Before rappelling, check Clothing, Harness/Helmet, Environment, Connections, and Knots',
    jsx: (
      <>
        <strong>C</strong>lothing <strong>H</strong>arness / <strong>H</strong>
        elmet <strong>E</strong>nvironment <strong>C</strong>onnections{' '}
        <strong>K</strong>nots.{' '}
        <Link to={routes.glossary_safety_check}>Safety check</Link> for a
        rappeller before they start the rappel.
      </>
    ),
    terms: ['check'],
    article: 'check',
  },
  {
    id: 'chest-pouch',
    tooltip:
      "Small pouch that attaches to a canyoneer's chest by criss crossed straps",
    jsx: (
      <>
        Small pouch that attaches to a canyoneer's chest by straps that criss
        cross at the back. The versions I'm aware of are{' '}
        <Link to={routes.glossary_atwood_gear}>Atwood Gear</Link>'s{' '}
        <Link to={routes.glossary_chester}>Chester</Link> and{' '}
        <Link to={routes.glossary_imlay_canyon_gear}>Imlay Canyon Gear</Link>'s{' '}
        <Link to={routes.glossary_picpocket}>PicPocket</Link>.
      </>
    ),
    terms: ['chest-pouch'],
  },
  {
    id: 'chester',
    tooltip: 'Atwood Gear brand name of the chest pouch',
    jsx: (
      <>
        <Link to={routes.glossary_atwood_gear}>Atwood Gear</Link> brand name of
        the <Link to={routes.glossary_chest_pouch}>chest pouch</Link>.
      </>
    ),
    terms: ['chester'],
    article: {
      title: 'Chester website',
      route: 'https://atwoodgear.com/products/chester-pouch',
    },
  },
  {
    id: 'chimney',
    tooltip:
      'A drop in a canyon where the opposite walls are near enough that one can chimney down instead of having to rappel',
    jsx: (
      <>
        A drop in a canyon where the opposite walls are near enough that one can{' '}
        <Link to={routes.glossary_chimneying}>chimney down</Link> instead of
        having to rappel.
      </>
    ),
    terms: ['chimney', 'elevator'],
  },
  {
    id: 'chimneying',
    tooltip:
      "Traversing a skinny section of canyon with one's back on one wall and feet on the other wall",
    jsx: (
      <>
        Traversing a skinny section of canyon with one's back on one wall and
        feet on the other wall. See{' '}
        <Link to={routes.glossary_stemming}>stemming</Link>.
      </>
    ),
    terms: ['chimneying'],
  },
  {
    id: 'chockstone',
    tooltip:
      'An anchor that is created by rigging a rock that is wedged into a crack',
    jsx: (
      <>
        An <Link to={routes.glossary_anchor}>anchor</Link> that is created by
        rigging a rock that is wedged into a crack.
      </>
    ),
    terms: ['chockstone'],
  },
  {
    id: 'choss',
    tooltip: 'Climbing term for rock that is loose and crumbly',
    jsx: (
      <>
        Climbing term for rock that is loose and crumbly. Sucks when climbing,
        annoying when rappelling, dangerous to be in the{' '}
        <Link to={routes.glossary_fall_zone}>fall zone</Link>.
      </>
    ),
    terms: ['choss'],
  },
  {
    id: 'chute',
    tooltip:
      'A sloping channel on the canyon floor that canyoneers like to dynamically down climb',
    jsx: (
      <>
        A sloping channel on the canyon floor that canyoneers like to
        dynamically <Link to={routes.glossary_down_climb}>down climb</Link>.
      </>
    ),
    terms: ['chute', 'slide'],
  },
  {
    id: 'cinch-wrap',
    tooltip:
      'Any webbing knot that cinches tight around the anchor to prevent it from sliding down',
    jsx: (
      <>
        Any webbing knot that cinches tight around the anchor to prevent it from
        sliding down. Examples are{' '}
        <Link to={routes.glossary_girth_hitch}>girth hitch</Link>, choke hitch,
        and <Link to={routes.glossary_wrap_3_pull_2}>wrap 3 pull 2</Link>.
      </>
    ),
    terms: ['cinch-wrap'],
  },
  {
    id: 'civilian-conservation-corps',
    tooltip:
      'Civilian Conservation Corps - Program during the great depression who are responsible for the conservation and development of many of the canyoneering areas',
    jsx: (
      <>
        Program during the great depression who are responsible for the
        conservation and development of many of the canyoneering areas.
      </>
    ),
    terms: ['ccc', 'civilian-conservation-corps'],
    article: {
      title: 'Civilian Conservation Corps',
      route: 'https://en.wikipedia.org/wiki/Civilian_Conservation_Corps',
    },
  },
  {
    id: 'class-3-scrambling',
    tooltip: 'Scrambling with increased exposure',
    jsx: (
      <>
        <Link to={routes.glossary_scrambling}>Scrambling</Link> with increased{' '}
        <Link to={routes.glossary_exposure}>exposure</Link>. Handholds are
        necessary. A rope should be available for learning climbers, or if you
        just choose to use one that day, but is usually not required. Falls
        could easily be fatal. Definition from the{' '}
        <Link to={routes.glossary_yosemite_decimal_system}>
          Yosemite Decimal System
        </Link>
        .
      </>
    ),
    terms: ['3rd-class-scrambling', 'class-3-scrambling'],
  },
  {
    id: 'class-4-scrambling',
    tooltip: 'Simple climbing, with exposure',
    jsx: (
      <>
        Simple climbing, with{' '}
        <Link to={routes.glossary_exposure}>exposure</Link>. A rope is often
        used. Natural protection can be easily found. Falls may well be fatal.
        Definition from the{' '}
        <Link to={routes.glossary_yosemite_decimal_system}>
          Yosemite Decimal System
        </Link>
        .
      </>
    ),
    terms: ['4th-class-scrambling', 'class-4-scrambling'],
  },
  {
    id: 'climb-utah',
    tooltip: 'Beta provider website',
    jsx: (
      <>
        <Link to={routes.glossary_beta_provider}>Beta provider</Link>{' '}
        <Link to="http://www.climb-utah.com/">website</Link>.
      </>
    ),
    terms: ['climb-utah'],
  },
  {
    id: 'climbing-harness',
    tooltip: 'Harness designed for climbing that has a vertical belay loop',
    jsx: (
      <>
        <Link to={routes.glossary_harness}>Harness</Link> designed for climbing
        that has a vertical{' '}
        <Link to={routes.glossary_belay_loop}>belay loop</Link> and particularly
        flimsy <Link to={routes.glossary_gear_loop}>gear loops</Link>.
      </>
    ),
    terms: ['climbing-harness'],
  },
  {
    id: 'clove-hitch',
    tooltip:
      'A hitch that is most commonly used in canyoneering as a block hitch for biner blocks',
    jsx: (
      <>
        A <Link to={routes.glossary_hitch}>hitch</Link> that is most commonly
        used in canyoneering as a{' '}
        <Link to={routes.glossary_block_hitch}>block hitch</Link> for{' '}
        <Link to={routes.glossary_biner_block}>biner blocks</Link>.
      </>
    ),
    terms: ['clove-hitch'],
  },
  {
    id: 'coalition-of-american-canyoneers',
    tooltip:
      'Coalition of American Canyoneers - nonprofit dedicated to promoting and preserving access to canyons',
    jsx: (
      <>
        Nonprofit dedicated to promoting and preserving access to canyons. Free
        to sign up and add another voice to preserve access to canyons.
      </>
    ),
    terms: ['coalition-of-american-canyoneers'],
    article: {
      title: 'Coalition of American Canyoneers',
      route: 'http://www.americancanyoneers.org/',
    },
  },
  {
    id: 'colorado-plateau',
    tooltip:
      'Canyon country. Many major canyoneering locations are within the Colorado Plateau. It stretches from Dinosaur National Monument in the northeast to the Grand Canyon in the southwest.',
    jsx: (
      <>
        Canyon country. Many major canyoneering locations are within the
        Colorado Plateau. It stretches from Dinosaur National Monument in the
        northeast to the Grand Canyon in the southwest.
      </>
    ),
    terms: ['colorado-plateau'],
    article: {
      title: 'Colorado Plateau on Wikipedia',
      route: 'https://en.wikipedia.org/wiki/Colorado_Plateau',
    },
  },
  {
    id: 'compact-secure',
    tooltip:
      'Variation of the contingency 8 that adds more friction to prevent accidental release',
    jsx: (
      <>
        Variation of the{' '}
        <Link to={routes.glossary_contingency_8}>contingency 8</Link> that adds
        more friction to prevent accidental release.
      </>
    ),
    terms: ['compact-secure'],
    article: {
      title: 'Compact secure on Ropewiki',
      route: 'http://ropewiki.com/Figure_8_block#Compact_secure',
    },
  },
  {
    id: 'constrictor-hitch',
    tooltip:
      'A hitch that is most commonly used in canyoneering as a block hitch for biner blocks',
    jsx: (
      <>
        A <Link to={routes.glossary_hitch}>hitch</Link> that is most commonly
        used in canyoneering as a{' '}
        <Link to={routes.glossary_block_hitch}>block hitch</Link> for{' '}
        <Link to={routes.glossary_biner_block}>biner blocks</Link>.
      </>
    ),
    terms: ['constrictor-hitch'],
  },
  {
    id: 'contingency-8',
    tooltip:
      'A releasable rigging system that uses a figure 8 to block the rope while still allowing it to be releasable',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_releasable_rigging_system}>
          releasable rigging system
        </Link>{' '}
        that uses a{' '}
        <Link to={routes.glossary_figure_8_rappel_device}>figure 8</Link> to{' '}
        <Link to={routes.glossary_block}>block</Link> the rope while still
        allowing it to be releasable. Also called a figure 8 block.
      </>
    ),
    terms: ['contingency-8'],
    article: {
      title: 'Figure 8 block on Ropewiki',
      route: 'http://ropewiki.com/Figure_8_block',
    },
  },
  {
    id: 'contingency-block',
    tooltip:
      'Releasable rigging system that uses a block as the tie-off on the bag end of the rapide',
    jsx: (
      <>
        <Link to={routes.glossary_releasable_rigging_system}>
          Releasable rigging system
        </Link>{' '}
        that uses a <Link to={routes.glossary_block}>block</Link> as the tie-off
        on the <Link to={routes.glossary_bag_end}>bag end</Link> of the{' '}
        <Link to={routes.glossary_rapide}>rapide</Link>. Examples are{' '}
        <Link to={routes.glossary_euro_8}>Euro 8</Link>,{' '}
        <Link to={routes.glossary_contingency_8}>contingency 8</Link> and{' '}
        <Link to={routes.glossary_contingency_totem}>contingency totem</Link>.
      </>
    ),
    terms: ['contingency-block'],
  },
  {
    id: 'contingency-redirect',
    tooltip:
      'Releasable rigging system that is rigged through separate rigging rather than a contingency block',
    jsx: (
      <>
        <Link to={routes.glossary_releasable_rigging_system}>
          Releasable rigging system
        </Link>{' '}
        that is <Link to={routes.glossary_redirect}>redirected</Link> through
        separate <Link to={routes.glossary_rigging}>rigging</Link> rather than a{' '}
        <Link to={routes.glossary_contingency_block}>contingency block</Link>.
        Examples are <Link to={routes.glossary_jester}>jester</Link>,{' '}
        <Link to={routes.glossary_joker}>joker</Link>, and{' '}
        <Link to={routes.glossary_munter_mule_overhand}>MMO</Link>.
      </>
    ),
    terms: ['contingency-redirect'],
  },
  {
    id: 'contingency-totem',
    tooltip:
      'A releasable rigging system that is similar to a contingency 8 but uses a totem instead of a figure 8',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_releasable_rigging_system}>
          releasable rigging system
        </Link>{' '}
        that is similar to a{' '}
        <Link to={routes.glossary_contingency_8}>contingency 8</Link> but uses a{' '}
        <Link to={routes.glossary_totem}>totem</Link> instead of a{' '}
        <Link to={routes.glossary_figure_8_rappel_device}>figure 8</Link>.
      </>
    ),
    terms: ['contingency-totem'],
  },
  {
    id: 'contour-line',
    tooltip:
      'A line on a topographic map that connects adjacent points that have the same elevation',
    jsx: (
      <>
        A line on a{' '}
        <Link to={routes.glossary_topographic_map}>topographic map</Link> that
        connects adjacent points that have the same elevation.
      </>
    ),
    terms: ['contour-line'],
  },
  {
    id: 'cordelette',
    tooltip: 'Thin cord used in various canyoneering applications',
    jsx: (
      <>
        6 to 7 mm diameter cord that is used in various canyoneering
        applications such as tying a{' '}
        <Link to={routes.glossary_purcell_prusik}>Purcell Prusik</Link> or for
        use as a <Link to={routes.glossary_pull_cord}>pull cord</Link>.
      </>
    ),
    terms: ['accessory-cord', 'cordelette', 'utility-cord'],
  },
  {
    id: 'cordura',
    tooltip:
      'Abrasion resistant Nylon-based fabric used for things like backpacks and armor',
    jsx: (
      <>
        Abrasion resistant Nylon-based fabric used in canyoneering for things
        like <Link to={routes.glossary_backpack}>backpacks</Link> and{' '}
        <Link to={routes.glossary_armor}>armor</Link>.
      </>
    ),
    terms: ['cordura'],
  },
  {
    id: 'core',
    tooltip:
      'The inner part of a kernmantle rope. It provides the tensile strength.',
    jsx: (
      <>
        The inner part of a kernmantle rope. It provides the{' '}
        <Link to={routes.glossary_tensile_strength}>tensile strength</Link> and
        is protected by the <Link to={routes.glossary_sheath}>sheath</Link>.
      </>
    ),
    terms: ['core'],
    article: 'rope',
  },
  {
    id: 'core-shot',
    tooltip:
      'A section of rope that is damaged and may not be able to support the weight of a canyoneer',
    jsx: (
      <>
        A section of rope that is damaged and may not be able to support the
        weight of a canyoneer. The <Link to={routes.glossary_core}>core</Link>{' '}
        is often exposed.
      </>
    ),
    terms: ['core-shot'],
    article: 'rope',
  },
  {
    id: 'counter-weight-rappel',
    tooltip:
      'A rappel set up where the rope is human-anchored at the bottom of the rappel, goes up through a fixed anchor at the top of the rappel, and then back down to the bottom',
    jsx: (
      <>
        A <Link to={routes.glossary_rappel}>rappel</Link> set up where the{' '}
        <Link to={routes.glossary_rope}>rope</Link> is{' '}
        <Link to={routes.glossary_human_anchor}>human-anchored</Link> at the
        bottom of the rappel, goes up through a fixed{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> at the top of the
        rappel, and then back down to the bottom.
      </>
    ),
    terms: ['counter-weight-rappel'],
  },
  {
    id: 'courtesy-loop',
    tooltip:
      'A loop that doubles as a hand hold and the top loop in courtesy rigging. Rigged just above the lip of the rappel.',
    jsx: (
      <>
        A loop of webbing that is large enough to be used as a hand hold and is
        clipped to the <Link to={routes.glossary_rapide}>rapide</Link> loop to
        form <Link to={routes.glossary_courtesy_rigging}>courtesy rigging</Link>
        . Rather than tied near the anchor, this loop is located just above the
        lip of the <Link to={routes.glossary_rappel}>rappel</Link> and can be
        used as a hand hold.
      </>
    ),
    terms: ['courtesy-loop'],
    article: {
      title: 'Bluugnome - Smokestack Canyon - Courtesy Loop for Easier Start',
      route:
        'http://www.bluugnome.com/cyn_route/cap-reef_smokestack/cap-reef_smokestack.aspx',
    },
  },
  {
    id: 'courtesy-rigging',
    tooltip:
      'Rigging that allows for all but the last rappeller to start the rappel from near the anchor and thus have an easier rappel start. For LAPAR, the rigging is extended past the cliff edge for an easy pull.',
    jsx: (
      <>
        <Link to={routes.glossary_rigging}>Rigging</Link> that allows for all
        but the last rappeller to start the rappel from near the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> and thus have an easier
        rappel start. For the last rappeller, the rigging is extended past the
        cliff edge for an easy <Link to={routes.glossary_pull}>pull</Link>.
      </>
    ),
    terms: ['courtesy-anchor', 'courtesy-rigging'],
  },
  {
    id: 'creep',
    tooltip:
      'Very slowly lower the rappel line while someone is on rappel to change the point where the rope is being weighted most',
    jsx: (
      <>
        Very slowly lower the{' '}
        <Link to={routes.glossary_rappel_line}>rappel line</Link> while someone
        is on rappel to change the point where the rope is being weighted most.
        This is done to distribute the wear and prevent{' '}
        <Link to={routes.glossary_core_shot}>core shots</Link> from occurring.
      </>
    ),
    terms: ['creep'],
  },
  {
    id: 'critr',
    tooltip: 'CanyonWerks figure 8 style canyoneering rappel device',
    jsx: (
      <>
        <Link to={routes.glossary_canyonwerks}>CanyonWerks</Link>{' '}
        <Link to={routes.glossary_figure_8_rappel_device}>figure 8</Link> style
        canyoneering{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link>. Stands
        for <strong>C</strong>anyon <strong>R</strong>appel <strong>I</strong>
        nvented by <strong>T</strong>odd <strong>R</strong>entchler.
      </>
    ),
    terms: ['critr'],
  },
  {
    id: 'cross-joint',
    tooltip: 'Section of canyon where perpendicular joints intersect',
    jsx: (
      <>
        Section of canyon where perpendicular{' '}
        <Link to={routes.glossary_joint}>joints</Link> intersect.
      </>
    ),
    terms: ['cross-joint'],
  },
  {
    id: 'cross-load',
    tooltip:
      'When a carabiner is weighted on its minor axis - on the gate and spine of the carabiner',
    jsx: (
      <>
        When a <Link to={routes.glossary_carabiner}>carabiner</Link> is weighted
        on its <Link to={routes.glossary_minor_axis}>minor axis</Link> - on the{' '}
        <Link to={routes.glossary_gate}>gate</Link> and{' '}
        <Link to={routes.glossary_spine}>spine</Link> of the carabiner.
      </>
    ),
    terms: ['cross-load'],
    article: 'carabiner',
  },
  {
    id: 'crux',
    tooltip: 'The hardest part of a canyon',
    jsx: <>The hardest part of a canyon.</>,
    terms: ['crux'],
  },
  {
    id: 'cryptobiotic-soil',
    tooltip:
      'Crust of microorganisms on top of the soil that helps prevent erosion and is very susceptible to crushing',
    jsx: (
      <>
        Crust of microorganisms on top of the soil that helps prevent erosion
        and is very susceptible to crushing. Don't step on it.
      </>
    ),
    terms: ['biological-soil', 'cryptobiotic-soil'],
  },
  {
    id: 'cst',
    tooltip: 'Canyon Specific Technology',
    jsx: (
      <>
        Stands for <strong>C</strong>anyon <strong>S</strong>pecific{' '}
        <strong>T</strong>echnology. <Link to={routes.glossary_rope}>Rope</Link>{' '}
        manufactured by{' '}
        <Link to={routes.glossary_on_rope_canyoneering}>
          On Rope Canyoneering
        </Link>
        .
      </>
    ),
    terms: ['cst'],
  },
  {
    id: 'cut-and-lower',
    tooltip:
      'Procedure to convert a double rope rappel to lower that involves securing a rescue rope to the rappel ropes and then cutting the rappel rope from the rapide so the rappeller can be safely lowered',
    jsx: (
      <>
        Procedure to convert a{' '}
        <Link to={routes.glossary_double_rope_technique}>
          double rope rappel
        </Link>{' '}
        to lower that involves securing a{' '}
        <Link to={routes.glossary_rescue_rope}>rescue rope</Link> to the rappel
        ropes and then cutting the rappel rope from the{' '}
        <Link to={routes.glossary_rapide}>rapide</Link> so the rappeller can be
        safely lowered. This is done when the rappeller is stuck on rappel and
        is unable to <Link to={routes.glossary_self_rescue}>self-rescue</Link>.
      </>
    ),
    terms: ['cut-and-lower'],
  },
];

export default definitions;
