// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'quad',
    tooltip:
      'EARNEST rigging similar to an equalette but with greater redundancy',
    jsx: (
      <>
        <Link to={routes.glossary_earnest}>EARNEST</Link> rigging similar to an{' '}
        <Link to={routes.glossary_equalette}>equalette</Link> but with greater{' '}
        <Link to={routes.glossary_redundant}>redundancy</Link>.
      </>
    ),
    terms: ['quad'],
  },
  {
    id: 'quickdraw',
    tooltip: 'Two carabiners connected by a dogbone',
    jsx: (
      <>
        Climbing gear that consists of two{' '}
        <Link to={routes.glossary_carabiner}>carabiners</Link> - one with a
        crooked or wire <Link to={routes.glossary_gate}>gate</Link> - secured to
        each other with a <Link to={routes.glossary_dogbone}>dogbone</Link>. Not
        commonly used in canyoneering. See{' '}
        <Link to={routes.glossary_canyon_quickdraw}>canyon quickdraw</Link>.
      </>
    ),
    terms: ['quickdraw'],
  },
  {
    id: 'quicksand',
    tooltip:
      'Solid-looking sand surface with a sand/water mixture beneath the surface',
    jsx: (
      <>
        Solid-looking sand surface with a sand/water mixture beneath the
        surface.
      </>
    ),
    terms: ['quicksand', 'sinking-sand'],
  },
];

export default definitions;
