// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import Link from './link';
import type { Id } from '../utils/types';
import { getGlossaryDefinition } from '../utils/glossary-helpers';
import { color_light } from '../utils/styles';

const StyledLink = styled(Link)`
  cursor: help;
  text-decoration: underline ${color_light} dotted;
`;

type Props = {
  children: Node,
  displayTerm?: boolean,
  id?: Id,
};

export default class GlossaryLink extends React.Component<Props> {
  render() {
    const { children, displayTerm, id } = this.props;

    let str: string = '';
    if (id) {
      str = id;
    } else if (typeof children === 'string') {
      str = children;
    }

    const def = getGlossaryDefinition(str);

    if (def) {
      const tooltip = (displayTerm ? def.name + ': ' : '') + def.tooltip;
      return (
        <StyledLink to={def.route} title={tooltip}>
          {children}
        </StyledLink>
      );
    } else {
      return <>{children}</>;
    }
  }
}
