// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'x-canyon',
    tooltip: 'A canyon where failure will likely result in death',
    jsx: (
      <>
        A canyon with an{' '}
        <Link to={routes.glossary_x_risk_rating}>X rating</Link>.
      </>
    ),
    terms: ['x-canyon'],
  },
  {
    id: 'x-risk-rating',
    tooltip: 'Failure will likely result in death',
    jsx: (
      <>
        Failure will likely result in death. Refers to everything in{' '}
        <Link to={routes.glossary_r_risk_rating}>R</Link> but with greater{' '}
        <Link to={routes.glossary_exposure}>exposure</Link>. Canyoneers who
        regularly do X canyons split the rating into X and{' '}
        <Link to={routes.glossary_xx_risk_rating}>XX</Link> where X is deadly
        and XX is also deadly. But more deadly.
      </>
    ),
    terms: ['x-risk-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'x-section',
    tooltip: 'Section of canyon where failure will likely result in death',
    jsx: (
      <>
        Same as <Link to={routes.glossary_r_section}>R section</Link> but with
        the increased risk of an{' '}
        <Link to={routes.glossary_x_risk_rating}>X</Link> risk rating.
      </>
    ),
    terms: ['x-section'],
  },
  {
    id: 'xx-risk-rating',
    tooltip:
      'A canyon with especially difficult, exposed moves where failure will likely result in death',
    jsx: (
      <>
        Especially difficult, <Link to={routes.glossary_exposure}>exposed</Link>{' '}
        moves where failure will likely result in death. There are currently 6
        publicly known XX canyons:{' '}
        <Link to="http://ropewiki.com/Bad_Neighbor">Bad Neighbor</Link>,
        <Link to="http://ropewiki.com/Bishop_Canyon">Bishop</Link>,{' '}
        <Link to="http://ropewiki.com/Box_Elder_Canyon">SOS</Link>,{' '}
        <Link to="http://ropewiki.com/PINTAC">PINTAC</Link>,{' '}
        <Link to="http://ropewiki.com/Long_Branch">Long Branch</Link>, and{' '}
        <Link to="http://ropewiki.com/Big_Freaking_Silo_(BFS)">BFS</Link>.
      </>
    ),
    terms: ['xx-risk-rating'],
    article: 'canyon-rating-system',
  },
];

export default definitions;
