// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'b-water-rating',
    tooltip: 'Deep wading to swimming. Water has no current.',
    jsx: <>Deep wading to swimming. Water has no current.</>,
    terms: ['b-water-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'backpack',
    tooltip:
      'Canyoneering backpacks should be sturdy and hold up through the rigors of canyoneering. They should also shed water quickly, be comfortable, and the bottom should be angled for easy down climbing.',
    jsx: (
      <>
        Canyoneering backpacks should be sturdy and hold up through the rigors
        of canyoneering. They should also shed water quickly, be comfortable,
        and the bottom should be angled for easy{' '}
        <Link to={routes.glossary_down_climb}>down climbing</Link>.
      </>
    ),
    terms: ['backpack'],
  },
  {
    id: 'bad-beta',
    tooltip:
      'Beta that is incorrect and canyoneers have a negative experience because of it',
    jsx: (
      <>
        <Link to={routes.glossary_beta}>Beta</Link> that is incorrect and
        canyoneers have a negative experience because of it.
      </>
    ),
    terms: ['bad-beta'],
  },
  {
    id: 'bag-end',
    tooltip:
      'The side of the rope that is tied to the bag. The working end is the other side.',
    jsx: (
      <>
        The side of the <Link to={routes.glossary_rope}>rope</Link> that is tied
        to the bag. The{' '}
        <Link to={routes.glossary_working_end_rope}>working end</Link> is the
        other side. These terms are used to distinguish between ends in{' '}
        <Link to={routes.glossary_single_rope_technique}>SRT</Link>.
      </>
    ),
    terms: ['bag-end'],
  },
  {
    id: 'bag-up-rigging-system',
    tooltip:
      'A method of rigging the rope to the anchor by measuring out the rope length and dropping the tail down to the bottom of the rappel while keeping the bag at the top',
    jsx: (
      <>
        A method of <Link to={routes.glossary_rigging}>rigging</Link> the{' '}
        <Link to={routes.glossary_rope}>rope</Link> to the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> by measuring out the
        rope length and dropping the <Link to={routes.glossary_tail}>tail</Link>{' '}
        down to the bottom of the{' '}
        <Link to={routes.glossary_rappel}>rappel</Link> while keeping the{' '}
        <Link to={routes.glossary_rope_bag}>bag</Link> at the top. In most
        situations this provides more rescue options than{' '}
        <Link to={routes.glossary_tail_up_rigging_system}>tail up rigging</Link>
        .
      </>
    ),
    terms: ['bag-up-rigging-system', 'tail-down-rigging-system'],
  },
  {
    id: 'basket-hitch',
    tooltip:
      'Hitch typically used for rigging on a single solid natural anchor',
    jsx: (
      <>
        <Link to={routes.glossary_hitch}>Hitch</Link> made from a loop that is
        simply wrapped around an object. It is typically used for{' '}
        <Link to={routes.glossary_rigging}>rigging</Link> on a single solid{' '}
        <Link to={routes.glossary_natural_anchor}>natural anchor</Link>.
      </>
    ),
    terms: ['basket-hitch'],
  },
  {
    id: 'batman-up',
    tooltip: "Climb something in the same way as Adam West's Batman",
    jsx: (
      <>
        Climb something in the same way as{' '}
        <Link to="https://www.youtube.com/watch?v=3Lm0Prpc5qA">
          Adam West's Batman
        </Link>
        .
      </>
    ),
    terms: ['batman-up'],
  },
  {
    id: 'beached-whale',
    tooltip:
      'Method of exiting a pothole by laying against the down canyon wall to apply friction using the entire body',
    jsx: (
      <>
        Method of exiting a{' '}
        <Link to={routes.glossary_keeper_pothole}>pothole</Link> by laying
        against the <Link to={routes.glossary_down_canyon}>down canyon</Link>{' '}
        wall to apply friction using the entire body.
      </>
    ),
    terms: ['beached-whale'],
  },
  {
    id: 'belay',
    tooltip:
      'Watch for and prevent a rappeller from falling when they lose control on rappel. There are two main types of belays, top-rope belays and bottom belays.',
    jsx: (
      <>
        Watch for and prevent a rappeller from falling when they lose control on
        rappel. There are three main types of belays,{' '}
        <Link to={routes.glossary_top_rope_belay}>top-rope belay</Link>,{' '}
        <Link to={routes.glossary_bottom_belay}>bottom belay</Link>, and{' '}
        <Link to={routes.glossary_self_belay}>self belay</Link>.
      </>
    ),
    terms: ['belay'],
  },
  {
    id: 'belay-device',
    tooltip:
      'A device used for belaying that clips to a rappel rope and the anchor and allows the belayer to halt the descent of the rappeller',
    jsx: (
      <>
        A device used for <Link to={routes.glossary_belay}>belaying</Link> that
        clips to a rappel <Link to={routes.glossary_rope}>rope</Link> and the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> and allows the{' '}
        <Link to={routes.glossary_belayer}>belayer</Link> to halt the descent of
        the rappeller.
      </>
    ),
    terms: ['belay-device'],
  },
  {
    id: 'belay-loop',
    tooltip:
      'Loop in the harness where life-supporting gear, such as a rappel device or safety tether, are attached. Climbing harnesses have vertical belay loops and rappelling harnesses have horizontal belay loops.',
    jsx: (
      <>
        Loop in the <Link to={routes.glossary_harness}>harness</Link> where
        life-supporting gear, such as a{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> or{' '}
        <Link to={routes.glossary_safety_tether}>safety tether</Link>, are
        attached.{' '}
        <Link to={routes.glossary_climbing_harness}>Climbing harnesses</Link>{' '}
        have vertical belay loops and{' '}
        <Link to={routes.glossary_rappelling_harness}>
          rappelling harnesses
        </Link>{' '}
        have horizontal belay loops.
      </>
    ),
    terms: ['belay-loop'],
  },
  {
    id: 'belay-station',
    tooltip:
      'Rigging that is located mid-rappel where a rappeller can safely hang',
    jsx: (
      <>
        <Link to={routes.glossary_rigging}>Rigging</Link> that is located mid-
        <Link to={routes.glossary_rappel}>rappel</Link> where a rappeller can
        safely hang.
      </>
    ),
    terms: ['belay-station'],
  },
  {
    id: 'belayer',
    tooltip:
      'A canyoneer who prevents an rappeller from falling if they lose control while on rappel',
    jsx: (
      <>
        A canyoneer who prevents an{' '}
        <Link to={routes.glossary_rappeller}>rappeller</Link> from falling if
        they lose control while on rappel.
      </>
    ),
    terms: ['belayer'],
  },
  {
    id: 'bell',
    tooltip:
      'The area in a canyon where down below is wider than up above so it makes a bell or hourglass shape',
    jsx: (
      <>
        The area in a canyon where down below is wider than up above so it makes
        a bell or hourglass shape.
      </>
    ),
    terms: ['bell', 'mae-west-slot'],
  },
  {
    id: 'bench',
    tooltip:
      'Long, narrow, and flat section of land where one direction is more steeply sloped up and the opposite direction is more steeply sloped down',
    jsx: (
      <>
        Long, narrow, and flat section of land where one direction is more
        steeply sloped up and the opposite direction is more steeply sloped
        down.
      </>
    ),
    terms: ['bench'],
  },
  {
    id: 'bend',
    tooltip: 'A knot that joins two ropes',
    jsx: (
      <>
        A <Link to={routes.glossary_knot}>knot</Link> that joins two ropes.
      </>
    ),
    terms: ['bend'],
  },
  {
    id: 'best-person-at-risk',
    tooltip:
      'Best Person at Risk - refers to situations such as marginal anchors where the most capable canyoneer will experience a greater amount of risk',
    jsx: (
      <>
        Refers to situations such as{' '}
        <Link to={routes.glossary_marginal_anchor}>marginal anchors</Link> where
        the most capable canyoneer will experience a greater amount of risk.
        Compare with{' '}
        <Link to={routes.glossary_last_person_at_risk}>
          Last Person At Risk
        </Link>
        .
      </>
    ),
    terms: ['best-man-at-risk', 'best-person-at-risk', 'bpar'],
  },
  {
    id: 'beta',
    tooltip: 'Ever-changing instructions on how to descend a canyon',
    jsx: (
      <>
        Ever-changing instructions on how to descend a canyon. If many
        professional fields when something is in beta, it is not complete and
        will probably change before public consumption.
      </>
    ),
    terms: ['beta'],
  },
  {
    id: 'beta-provider',
    tooltip:
      'A person, book, website, or other source of information that distributes beta on a canyon',
    jsx: (
      <>
        A person, book, website, or other source of information that distributes{' '}
        <Link to={routes.glossary_beta}>beta</Link> on a canyon.
      </>
    ),
    terms: ['beta-provider'],
  },
  {
    id: 'big-friendly-rock',
    tooltip: 'Big Friendly Rock - used as a bomber natural anchor',
    jsx: (
      <>
        Used as a <Link to={routes.glossary_bomber}>bomber</Link>{' '}
        <Link to={routes.glossary_natural_anchor}>natural anchor</Link>.
      </>
    ),
    terms: ['bfr', 'big-friendly-rock'],
  },
  {
    id: 'bight',
    tooltip: 'A bend in a rope when tying a knot',
    jsx: (
      <>
        A bend in a <Link to={routes.glossary_rope}>rope</Link> when tying a{' '}
        <Link to={routes.glossary_knot}>knot</Link>.
      </>
    ),
    terms: ['bight'],
  },
  {
    id: 'biner-block',
    tooltip: 'A static block that uses a carabiner as the block',
    jsx: (
      <>
        A <Link to={routes.glossary_static_block}>static block</Link> that uses
        a <Link to={routes.glossary_carabiner}>carabiner</Link> as the block.
        The rope is tied with a{' '}
        <Link to={routes.glossary_block_hitch}>block hitch</Link> such as{' '}
        <Link to={routes.glossary_clove_hitch}>clove hitch</Link>,{' '}
        <Link to={routes.glossary_triple_clove_hitch}>triple clove hitch</Link>,
        or <Link to={routes.glossary_constrictor_hitch}>constrictor hitch</Link>
        .
      </>
    ),
    terms: ['biner-block'],
  },
  {
    id: 'bivouac',
    tooltip: 'A temporary camp without tents',
    jsx: (
      <>
        A temporary camp without tents. Probably Swiss German from "Biwacht"
        which means additional guard at night (from{' '}
        <Link to="https://www.google.com/search?q=define%3A+bivouac&oq=define%3A+bivouac&aqs=chrome..69i57j69i58.5913j1j7&sourceid=chrome&ie=UTF-8">
          Google
        </Link>
        ).
      </>
    ),
    terms: ['bivouac', 'bivy'],
  },
  {
    id: 'bivy-sack',
    tooltip:
      'A portable, lightweight, and hopefully waterproof sack for bivvying',
    jsx: (
      <>
        A portable, lightweight, and hopefully waterproof sack for{' '}
        <Link to={routes.glossary_bivouac}>bivvying</Link>.
      </>
    ),
    terms: ['bivy-sack'],
  },
  {
    id: 'block',
    tooltip:
      'Rigging system for rappelling that uses something secured to a rope and is too large to pass through the rapide',
    jsx: (
      <>
        <Link to={routes.glossary_rigging}>Rigging</Link> system for rappelling
        that uses something secured to a rope and is too large to pass through
        the <Link to={routes.glossary_rapide}>rapide</Link>.<br />
        See <Link to={routes.glossary_static_block}>static block</Link> and{' '}
        <Link to={routes.glossary_releasable_rigging_system}>
          releasable rigging system
        </Link>
        .
      </>
    ),
    terms: ['block'],
  },
  {
    id: 'block-hitch',
    tooltip:
      'A hitch used for biner blocks. Examples are clove, triple clove, and constrictor.',
    jsx: (
      <>
        A <Link to={routes.glossary_hitch}>hitch</Link> used for{' '}
        <Link to={routes.glossary_biner_block}>biner blocks</Link>. Examples are{' '}
        <Link to={routes.glossary_clove_hitch}>clove</Link>,{' '}
        <Link to={routes.glossary_triple_clove_hitch}>triple clove</Link>, and{' '}
        <Link to={routes.glossary_constrictor_hitch}>constrictor</Link> hitches.
      </>
    ),
    terms: ['block-hitch'],
  },
  {
    id: 'blowout',
    tooltip: 'Part of the anchor breaks and causes the rigging to fail',
    jsx: (
      <>
        When some part of the <Link to={routes.glossary_rigging}>rigging</Link>{' '}
        breaks off part of the rest of the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> and fails. Examples are
        when <Link to={routes.glossary_hooking}>hooking</Link> out of a pothole
        and the drilled holes blow out, or when a{' '}
        <Link to={routes.glossary_chockstone}>chockstone</Link> pulls through
        the <Link to={routes.glossary_sandstone}>sandstone</Link> crack.
      </>
    ),
    terms: ['blowout'],
  },
  {
    id: 'bluff',
    tooltip: 'Cliff with a good view',
    jsx: <>Cliff with a good view.</>,
    terms: ['bluff'],
  },
  {
    id: 'bluugnome',
    tooltip:
      'Bluugnome - canyoneering website that provides beta, instruction, and store',
    jsx: (
      <>
        Canyoneering <Link to="http://www.bluugnome.com">website</Link> that
        provides <Link to={routes.glossary_beta}>beta</Link>, instruction, and a{' '}
        <Link to="http://www.bg-gear.com/store/">store</Link>. Designer of the{' '}
        <Link to={routes.glossary_sqwurel}>SQWUREL</Link> and{' '}
        <Link to={routes.glossary_smooth_operator}>Smooth Operator</Link>.
      </>
    ),
    terms: ['bg', 'bluugnome'],
  },
  {
    id: 'bogley',
    tooltip: 'Online forum with canyoneering subsection',
    jsx: <>Online forum with canyoneering subsection.</>,
    terms: ['bogley'],
    article: {
      title: 'Bogley Canyoneering Forum',
      route: 'http://www.bogley.com/forum/forumdisplay.php?15-Canyoneering',
    },
  },
  {
    id: 'bolt',
    tooltip:
      'A human-made anchor that is created by drilling a hole into the rock and placing a bolt and hanger',
    jsx: (
      <>
        A human-made <Link to={routes.glossary_anchor}>anchor</Link> that is
        created by drilling a hole into the rock and placing a bolt and{' '}
        <Link to={routes.glossary_hanger}>hanger</Link>. Bolts used in
        canyoneering are either{' '}
        <Link to={routes.glossary_expansion_bolt}>expansion bolts</Link> or{' '}
        <Link to={routes.glossary_glue_in}>glue-ins</Link>.
      </>
    ),
    terms: ['bolt'],
  },
  {
    id: 'bolt-kit',
    tooltip: 'Kit to place bolts',
    jsx: (
      <>
        Kit to place <Link to={routes.glossary_bolt}>bolts</Link>. Includes
        bolts, <Link to={routes.glossary_hanger}>hangers</Link>, wrench, and
        bit.
      </>
    ),
    terms: ['bolt-kit'],
  },
  {
    id: 'bolt-wars',
    tooltip:
      'The ongoing argument of whether or not to place bolts in some canyons',
    jsx: (
      <>
        The ongoing argument of whether or not to place{' '}
        <Link to={routes.glossary_bolt}>bolts</Link> in some canyons.
      </>
    ),
    terms: ['bolt-wars'],
  },
  {
    id: 'bomb-bay',
    tooltip:
      'A belled out section where the canyoneer is at skinny top and it is impossible to down climb. One must either fall or swing down to reach the bottom.',
    jsx: (
      <>
        A <Link to={routes.glossary_bell}>belled out</Link> section where the
        canyoneer is at skinny top and it is impossible to{' '}
        <Link to={routes.glossary_down_climb}>down climb</Link>. One must either
        fall or swing down to reach the bottom.
      </>
    ),
    terms: ['bomb-bay'],
  },
  {
    id: 'bomber',
    tooltip: 'A very solid anchor',
    jsx: (
      <>
        An <Link to={routes.glossary_anchor}>anchor</Link> that can have an
        enormous amount of force applied to it and it will not fail. Opposite of
        a <Link to={routes.glossary_marginal_anchor}>marginal anchor</Link>.
        <br />
        Examples of bomber anchors are a pair of well-placed{' '}
        <Link to={routes.glossary_bolt}>bolts</Link>, A tree that is six inches
        in diameter, alive, and well-rooted, and a really big rock.
      </>
    ),
    terms: ['bomber'],
    article: 'single-solid-natural-anchors',
  },
  {
    id: 'booty',
    tooltip:
      'Valuable gear found in a canyon that was left behind by a previous canyoneering group',
    jsx: (
      <>
        Valuable gear found in a canyon that was left behind by a previous
        canyoneering group.
      </>
    ),
    terms: ['booty'],
  },
  {
    id: 'bottom-belay',
    tooltip:
      'A belay that is performed by tensioning the rappel rope below the rappeller',
    jsx: (
      <>
        A <Link to={routes.glossary_belay}>belay</Link> that is performed by
        tensioning the rappel rope below the rappeller.
      </>
    ),
    terms: ['bottom-belay', 'fireman-belay'],
  },
  {
    id: 'boulder-hop',
    tooltip: 'Down climb on boulders',
    jsx: (
      <>
        <Link to={routes.glossary_down_climb}>Down climb</Link> on boulders.
      </>
    ),
    terms: ['boulder-hop'],
  },
  {
    id: 'box-canyon',
    tooltip:
      'A canyon that has cliffs on both sides but is not skinny like a slot canyon',
    jsx: (
      <>
        A <Link to={routes.glossary_canyon}>canyon</Link> that has cliffs on
        both sides but is not skinny like a{' '}
        <Link to={routes.glossary_slot_canyon}>slot canyon</Link>.
      </>
    ),
    terms: ['box-canyon'],
  },
  {
    id: 'brake-hand',
    tooltip:
      'The hand a rappeller uses to slow down while rappelling. Usually the dominant hand.',
    jsx: (
      <>
        The hand a rappeller uses to slow down while rappelling. Usually the
        dominant hand. Opposite of{' '}
        <Link to={routes.glossary_guide_hand}>guide hand</Link>.
      </>
    ),
    terms: ['brake-hand'],
  },
  {
    id: 'bridging',
    tooltip:
      'Traversing a skinny section of canyon with both hands on one wall and both feet on the other',
    jsx: (
      <>
        Traversing a skinny section of canyon with both hands on one wall and
        both feet on the other. See{' '}
        <Link to={routes.glossary_stemming}>stemming</Link>.
      </>
    ),
    terms: ['bridging'],
  },
  {
    id: 'bureau-of-land-management',
    tooltip:
      'Bureau of Land Management - Manages land where many canyons are located',
    jsx: (
      <>
        Manages land where many canyons are located. Part of the U.S. Department
        of the Interior.
      </>
    ),
    terms: ['blm', 'bureau-of-land-management'],
    article: {
      title: 'Bureau of Land Management website',
      route: 'https://www.blm.gov/',
    },
  },
  {
    id: 'bushwhack',
    tooltip:
      "To force one's way through a forested or overgrown area where no path exists",
    jsx: (
      <>
        To force one's way through a forested or overgrown area where no path
        exists.{' '}
        <Link to="https://www.wordnik.com/words/bushwhack">
          Definition from Wordnik
        </Link>
        .
      </>
    ),
    terms: ['bushwhack'],
  },
  {
    id: 'butt-protector',
    tooltip:
      "A strong piece of material, usually PVC, that is attached to a canyoneer's harness to protect the harness and clothing",
    jsx: (
      <>
        A strong piece of material, usually PVC, that is attached to a
        canyoneer's harness to protect the harness and clothing.
        <br />
        Versions I know of are the{' '}
        <Link to={routes.glossary_scuttlebutt}>Scuttlebutt</Link>,{' '}
        <Link to={routes.glossary_shuffle_butt}>Shuffle Butt</Link>,{' '}
        <Link to={routes.glossary_canyon_skid_plate}>Canyon Skid Plate</Link>,
        and one by <Link to={routes.glossary_canyonwerks}>CanyonWerks</Link>{' '}
        that is no longer in production.
      </>
    ),
    terms: ['butt-protector'],
  },
  {
    id: 'butt-slide',
    tooltip: 'A dynamic down climbing technique where you slide on your butt',
    jsx: (
      <>
        A dynamic <Link to={routes.glossary_down_climb}>down climbing</Link>{' '}
        technique where you slide on your butt. Destroys clothing and harnesses
        without a{' '}
        <Link to={routes.glossary_butt_protector}>butt protector</Link>.
      </>
    ),
    terms: ['butt-slide'],
  },
  {
    id: 'butte',
    tooltip: 'Hill with flat top and cliffs all around it',
    jsx: (
      <>
        Hill with flat top and cliffs all around it. Means "small hill" in
        French.
      </>
    ),
    terms: ['butte'],
  },
];

export default definitions;
