// @flow
import type { Node } from 'react';
import type { Id } from '../utils/types';
import Link from '../components/link';
import React from 'react';

export type GlossaryTerm = {
  id: Id,
  name: string,
  definition: Id,
  specific?: Node,
};

const terms: Array<GlossaryTerm> = [
  // #
  {
    id: '1-technical-rating',
    name: '1 (Technical Rating)',
    definition: '1-technical-rating',
  },
  {
    id: '2-ring-retrievable-rigging',
    name: '2-Ring Retrievable Rigging',
    definition: '2-ring-retrievable-rigging',
  },
  {
    id: '2-technical-rating',
    name: '2 (Technical Rating)',
    definition: '2-technical-rating',
  },
  {
    id: '3-technical-rating',
    name: '3 (Technical Rating)',
    definition: '3-technical-rating',
  },
  {
    id: '3rd-class-scrambling',
    name: '3rd Class Scrambling',
    definition: 'class-3-scrambling',
  },
  {
    id: '4-technical-rating',
    name: '4 (Technical Rating)',
    definition: '4-technical-rating',
  },
  {
    id: '4th-class-scrambling',
    name: '4th Class Scrambling',
    definition: 'class-4-scrambling',
  },
  // a
  {
    id: 'a-water-rating',
    name: 'A (Water Rating)',
    definition: 'a-water-rating',
  },
  { id: 'abc', name: 'ABC', definition: 'abc' },
  { id: 'abseil', name: 'Abseil', definition: 'rappel' },
  { id: 'aca', name: 'ACA', definition: 'american-canyoneering-association' },
  {
    id: 'aca-canyon-rating-system',
    name: 'ACA Canyon Rating System',
    definition: 'aca-canyon-rating-system',
  },
  { id: 'accessory-cord', name: 'Accessory Cord', definition: 'cordelette' },
  {
    id: 'ace',
    name: 'ACE',
    definition: 'association-for-canyoneering-education',
  },
  {
    id: 'aguanchor',
    name: 'Aguanchor',
    definition: 'aguanchor',
  },
  { id: 'aider', name: 'Aider', definition: 'etrier' },
  {
    id: 'american-canyoneering-association',
    name: 'American Canyoneering Association',
    definition: 'american-canyoneering-association',
    specific:
      'Used to be the American Canyoneering Academy. Before that it was the American Canyoneering Association.',
  },
  {
    id: 'american-death-triangle',
    name: 'American Death Triangle',
    definition: 'american-death-triangle',
  },
  { id: 'anchor', name: 'Anchor', definition: 'anchor' },
  { id: 'angle', name: 'Angle', definition: 'angle' },
  { id: 'ape-index', name: 'Ape Index', definition: 'ape-index' },
  { id: 'approach', name: 'Approach', definition: 'approach' },
  { id: 'arch', name: 'Arch', definition: 'arch' },
  { id: 'armor', name: 'Armor', definition: 'armor' },
  { id: 'art', name: 'ART', definition: 'art' },
  { id: 'ascend', name: 'Ascend', definition: 'ascend' },
  { id: 'ascender', name: 'Ascender', definition: 'ascender' },
  {
    id: 'assisted-rappel',
    name: 'Assisted Rappel',
    definition: 'tandem-rappel',
  },
  {
    id: 'association-for-canyoneering-education',
    name: 'Association for Canyoneering Education',
    definition: 'association-for-canyoneering-education',
  },
  { id: 'atc', name: 'ATC', definition: 'atc' },
  { id: 'ats', name: 'ATS', definition: 'ats' },
  { id: 'atwood-gear', name: 'Atwood Gear', definition: 'atwood-gear' },
  { id: 'autoblock', name: 'Autoblock', definition: 'autoblock' },
  // b
  {
    id: 'b-water-rating',
    name: 'B (Water Rating)',
    definition: 'b-water-rating',
  },
  { id: 'backpack', name: 'Backpack', definition: 'backpack' },
  { id: 'bad-beta', name: 'Bad Beta', definition: 'bad-beta' },
  {
    id: 'bag-down-rigging-system',
    name: 'Bag Down Rigging System',
    definition: 'tail-up-rigging-system',
  },
  { id: 'bag-end', name: 'Bag End', definition: 'bag-end' },
  {
    id: 'bag-up-rigging-system',
    name: 'Bag Up Rigging System',
    definition: 'bag-up-rigging-system',
  },
  { id: 'basket', name: 'Basket', definition: 'working-end-carabiner' },
  { id: 'basket-hitch', name: 'Basket Hitch', definition: 'basket-hitch' },
  { id: 'batman-up', name: 'Batman Up', definition: 'batman-up' },
  { id: 'beached-whale', name: 'Beached Whale', definition: 'beached-whale' },
  { id: 'belay', name: 'Belay', definition: 'belay' },
  { id: 'belay-device', name: 'Belay Device', definition: 'belay-device' },
  { id: 'belay-loop', name: 'Belay Loop', definition: 'belay-loop' },
  { id: 'belay-station', name: 'Belay Station', definition: 'belay-station' },
  { id: 'belayer', name: 'Belayer', definition: 'belayer' },
  { id: 'bell', name: 'Bell', definition: 'bell' },
  { id: 'bench', name: 'Bench', definition: 'bench' },
  { id: 'bend', name: 'Bend', definition: 'bend' },
  {
    id: 'best-man-at-risk',
    name: 'Best Man At Risk',
    definition: 'best-person-at-risk',
    specific: 'A more accurate term is Best Person At Risk.',
  },
  {
    id: 'best-person-at-risk',
    name: 'Best Person At Risk',
    definition: 'best-person-at-risk',
  },
  { id: 'beta', name: 'Beta', definition: 'beta' },
  { id: 'beta-provider', name: 'Beta Provider', definition: 'beta-provider' },
  {
    id: 'bfr',
    name: 'BFR',
    definition: 'big-friendly-rock',
    specific: (
      <>
        Stands for <strong>B</strong>ig <strong>F</strong>riendly{' '}
        <strong>R</strong>ock. Yes the F stands for friendly.
      </>
    ),
  },
  {
    id: 'bg',
    name: 'BG',
    definition: 'bluugnome',
    specific: (
      <>
        Stands for <strong>B</strong>luu<strong>G</strong>nome.
      </>
    ),
  },
  {
    id: 'bhk',
    name: 'BHK',
    definition: 'master-point',
    specific: (
      <>
        Stands for <strong>B</strong>ig <strong>H</strong>onkin'{' '}
        <strong>K</strong>not.
      </>
    ),
  },
  {
    id: 'big-friendly-rock',
    name: 'Big Friendly Rock',
    definition: 'big-friendly-rock',
  },
  { id: 'bight', name: 'Bight', definition: 'bight' },
  { id: 'biner', name: 'Biner', definition: 'carabiner' },
  { id: 'biner-block', name: 'Biner Block', definition: 'biner-block' },
  {
    id: 'biological-soil',
    name: 'Biological Soil',
    definition: 'cryptobiotic-soil',
  },
  { id: 'bivouac', name: 'Bivouac', definition: 'bivouac' },
  { id: 'bivy', name: 'Bivy', definition: 'bivouac' },
  { id: 'bivy-sack', name: 'Bivy Sack', definition: 'bivy-sack' },
  { id: 'blm', name: 'BLM', definition: 'bureau-of-land-management' },
  { id: 'block', name: 'Block', definition: 'block' },
  { id: 'block-hitch', name: 'Block Hitch', definition: 'block-hitch' },
  { id: 'blowout', name: 'Blowout', definition: 'blowout' },
  { id: 'bluff', name: 'Bluff', definition: 'bluff' },
  { id: 'bluugnome', name: 'BluuGnome', definition: 'bluugnome' },
  { id: 'bogley', name: 'Bogley', definition: 'bogley' },
  { id: 'bolt', name: 'Bolt', definition: 'bolt' },
  { id: 'bolt-kit', name: 'Bolt Kit', definition: 'bolt-kit' },
  { id: 'bolt-wars', name: 'Bolt Wars', definition: 'bolt-wars' },
  { id: 'bomb-bay', name: 'Bomb Bay', definition: 'bomb-bay' },
  { id: 'bomber', name: 'Bomber', definition: 'bomber' },
  { id: 'booty', name: 'Booty', definition: 'booty' },
  { id: 'bottom-belay', name: 'Bottom Belay', definition: 'bottom-belay' },
  { id: 'boulder-hop', name: 'Boulder Hop', definition: 'boulder-hop' },
  { id: 'box-canyon', name: 'Box Canyon', definition: 'box-canyon' },
  { id: 'bpar', name: 'BPAR', definition: 'best-person-at-risk' },
  { id: 'brake-hand', name: 'Brake Hand', definition: 'brake-hand' },
  { id: 'bridging', name: 'Bridging', definition: 'bridging' },
  { id: 'buddy-check', name: 'Buddy Check', definition: 'safety-check' },
  {
    id: 'bunny-strap',
    name: 'Bunny Strap',
    definition: 'safety-tether',
    specific: (
      <>
        A bunny strap specifically refers to hanging one's pack while stemming.
      </>
    ),
  },
  {
    id: 'bureau-of-land-management',
    name: 'Bureau of Land Management',
    definition: 'bureau-of-land-management',
  },
  { id: 'bushwhack', name: 'Bushwhack', definition: 'bushwhack' },
  {
    id: 'butt-protector',
    name: 'Butt Protector',
    definition: 'butt-protector',
  },
  { id: 'butt-slide', name: 'Butt Slide', definition: 'butt-slide' },
  { id: 'butte', name: 'Butte', definition: 'butte' },
  // c
  {
    id: 'c-water-rating',
    name: 'C (Water Rating)',
    definition: 'c-water-rating',
  },
  {
    id: 'cac',
    name: 'CAC',
    definition: 'coalition-of-american-canyoneers',
    specific: (
      <>
        Stands for <strong>C</strong>oalition of <strong>A</strong>merican{' '}
        <strong>C</strong>anyoneers.
      </>
    ),
  },
  { id: 'cairn', name: 'Cairn', definition: 'cairn' },
  { id: 'cairn-anchor', name: 'Cairn Anchor', definition: 'cairn-anchor' },
  { id: 'cam', name: 'Cam', definition: 'cam' },
  { id: 'candition', name: 'Candition', definition: 'candition' },
  { id: 'canyon', name: 'Canyon', definition: 'canyon' },
  {
    id: 'canyon-collective',
    name: 'Canyon Collective',
    definition: 'canyon-collective',
  },
  { id: 'canyon-left', name: 'Canyon Left', definition: 'left-down-canyon' },
  { id: 'canyon-mode', name: 'Canyon Mode', definition: 'canyon-mode' },
  {
    id: 'canyon-quickdraw',
    name: 'Canyon Quickdraw',
    definition: 'canyon-quickdraw',
  },
  { id: 'canyon-resume', name: 'Canyon Resume', definition: 'canyon-resume' },
  { id: 'canyon-right', name: 'Canyon Right', definition: 'right-down-canyon' },
  {
    id: 'canyon-skid-plate',
    name: 'Canyon Skid Plate',
    definition: 'canyon-skid-plate',
  },
  { id: 'canyoneer', name: 'Canyoneer', definition: 'canyoneer' },
  { id: 'canyoneering', name: 'Canyoneering', definition: 'canyoneering' },
  {
    id: 'canyoneering-circle',
    name: 'Canyoneering Circle',
    definition: 'canyoneering-circle',
  },
  {
    id: 'canyoneering-usa',
    name: 'Canyoneering USA',
    definition: 'imlay-canyon-gear',
  },
  { id: 'canyoner', name: 'Canyoner', definition: 'canyoneer' },
  { id: 'canyoning', name: 'Canyoning', definition: 'canyoneering' },
  { id: 'canyonman', name: 'Canyonman', definition: 'canyonman' },
  { id: 'canyonwerks', name: 'CanyonWerks', definition: 'canyonwerks' },
  { id: 'capsize', name: 'Capsize', definition: 'capsize' },
  { id: 'carabiner', name: 'Carabiner', definition: 'carabiner' },
  { id: 'care', name: 'CARE', definition: 'care' },
  { id: 'catch-basin', name: 'Catch Basin', definition: 'drainage' },
  { id: 'ccc', name: 'CCC', definition: 'civilian-conservation-corps' },
  { id: 'ce-marking', name: 'CE Marking', definition: 'ce-marking' },
  { id: 'cem', name: 'CEM', definition: 'cem' },
  { id: 'cfs', name: 'CFS', definition: 'cfs' },
  { id: 'cheater-stick', name: 'Cheater Stick', definition: 'cheater-stick' },
  { id: 'check', name: 'CHECK', definition: 'check' },
  { id: 'chest-pouch', name: 'Chest Pouch', definition: 'chest-pouch' },
  { id: 'chester', name: 'Chester', definition: 'chester' },
  { id: 'chimney', name: 'Chimney', definition: 'chimney' },
  { id: 'chimneying', name: 'Chimneying', definition: 'chimneying' },
  { id: 'chockstone', name: 'Chockstone', definition: 'chockstone' },
  {
    id: 'chokestone',
    name: 'Chokestone',
    definition: 'chockstone',
    specific: (
      <>
        It's probably worth mentioning that this is an incorrect pronunciation.
      </>
    ),
  },
  { id: 'choss', name: 'Choss', definition: 'choss' },
  { id: 'chute', name: 'Chute', definition: 'chute' },
  { id: 'cinch-wrap', name: 'Cinch Wrap', definition: 'cinch-wrap' },
  {
    id: 'civilian-conservation-corps',
    name: 'Civilian Conservation Corps',
    definition: 'civilian-conservation-corps',
  },
  {
    id: 'class-3-scrambling',
    name: 'Class 3 Scrambling',
    definition: 'class-3-scrambling',
  },
  {
    id: 'class-4-scrambling',
    name: 'Class 4 Scrambling',
    definition: 'class-4-scrambling',
  },
  { id: 'climb-utah', name: 'Climb-Utah', definition: 'climb-utah' },
  {
    id: 'climbing-harness',
    name: 'Climbing Harness',
    definition: 'climbing-harness',
  },
  { id: 'clove-hitch', name: 'Clove Hitch', definition: 'clove-hitch' },
  {
    id: 'coalition-of-american-canyoneers',
    name: 'Coalition of American Canyoneers',
    definition: 'coalition-of-american-canyoneers',
  },
  {
    id: 'colorado-plateau',
    name: 'Colorado Plateau',
    definition: 'colorado-plateau',
  },
  {
    id: 'compact-secure',
    name: 'Compact Secure',
    definition: 'compact-secure',
  },
  {
    id: 'constrictor-hitch',
    name: 'Constrictor Hitch',
    definition: 'constrictor-hitch',
  },
  { id: 'contingency-8', name: 'Contingency 8', definition: 'contingency-8' },
  {
    id: 'contingency-block',
    name: 'Contingency Block',
    definition: 'contingency-block',
  },
  {
    id: 'contingency-redirect',
    name: 'Contingency Redirect',
    definition: 'contingency-redirect',
  },
  {
    id: 'contingency-rigging',
    name: 'Contingency Rigging',
    definition: 'releasable-rigging-system',
  },
  {
    id: 'contingency-system',
    name: 'Contingency System',
    definition: 'releasable-rigging-system',
  },
  {
    id: 'contingency-totem',
    name: 'Contingency Totem',
    definition: 'contingency-totem',
  },
  { id: 'contour-line', name: 'Contour Line', definition: 'contour-line' },
  { id: 'cordelette', name: 'Cordelette', definition: 'cordelette' },
  { id: 'cordura', name: 'Cordura', definition: 'cordura' },
  { id: 'core', name: 'Core', definition: 'core' },
  { id: 'core-shot', name: 'Core Shot', definition: 'core-shot' },
  {
    id: 'counter-weight-rappel',
    name: 'Counter Weight Rappel',
    definition: 'counter-weight-rappel',
  },
  {
    id: 'courtesy-anchor',
    name: 'Courtesy Anchor',
    definition: 'courtesy-rigging',
    specific:
      'Technically a misnomer since it refers to the rigging and not the entire anchor.',
  },
  { id: 'courtesy-loop', name: 'Courtesy Loop', definition: 'courtesy-loop' },
  {
    id: 'courtesy-rigging',
    name: 'Courtesy Rigging',
    definition: 'courtesy-rigging',
  },
  {
    id: 'courtesy-start',
    name: 'Courtesy Start',
    definition: 'dynamic-courtesy-rigging',
  },
  { id: 'cows-tail', name: "Cow's Tail", definition: 'safety-tether' },
  { id: 'creep', name: 'Creep', definition: 'creep' },
  { id: 'critr', name: 'CRITR', definition: 'critr' },
  { id: 'cross-joint', name: 'Cross Joint', definition: 'cross-joint' },
  { id: 'cross-load', name: 'Cross Load', definition: 'cross-load' },
  { id: 'crux', name: 'Crux', definition: 'crux' },
  {
    id: 'cryptobiotic-soil',
    name: 'Cryptobiotic Soil',
    definition: 'cryptobiotic-soil',
  },
  { id: 'cst', name: 'CST', definition: 'cst' },
  { id: 'cusa', name: 'CUSA', definition: 'imlay-canyon-gear' },
  { id: 'cut-and-lower', name: 'Cut and Lower', definition: 'cut-and-lower' },
  // d
  { id: 'daisy-chain', name: 'Daisy Chain', definition: 'daisy-chain' },
  {
    id: 'deadman-anchor',
    name: 'Deadman Anchor',
    definition: 'deadman-anchor',
  },
  { id: 'dear', name: 'DEAR', definition: 'dear' },
  { id: 'descender', name: 'Descender', definition: 'rappel-device' },
  { id: 'descent-ring', name: 'Descent Ring', definition: 'rappel-ring' },
  {
    id: 'desert-varnish',
    name: 'Desert Varnish',
    definition: 'desert-varnish',
  },
  { id: 'deviation', name: 'Deviation', definition: 'rappel-deviation' },
  { id: 'dipstick', name: 'Dipstick', definition: 'dipstick' },
  { id: 'dogbone', name: 'Dogbone', definition: 'dogbone' },
  {
    id: 'double-rope-technique',
    name: 'Double Rope Technique',
    definition: 'double-rope-technique',
  },
  {
    id: 'double-strand',
    name: 'Double Strand',
    definition: 'double-rope-technique',
  },
  { id: 'down-canyon', name: 'Down Canyon', definition: 'down-canyon' },
  {
    id: 'down-climb',
    name: 'Down Climb',
    definition: 'down-climb',
    specific: 'Often spelled "downclimb".',
  },
  { id: 'drainage', name: 'Drainage', definition: 'drainage' },
  { id: 'draw', name: 'Draw', definition: 'draw' },
  { id: 'dress', name: 'Dress', definition: 'dress' },
  {
    id: 'drop-loop',
    name: 'Drop Loop',
    definition: 'dynamic-courtesy-rigging',
  },
  { id: 'drt', name: 'DRT', definition: 'double-rope-technique' },
  { id: 'dry-bag', name: 'Dry Bag', definition: 'dry-bag' },
  { id: 'dry-sack', name: 'Dry Sack', definition: 'dry-bag' },
  { id: 'dryfall', name: 'Dryfall', definition: 'dryfall' },
  { id: 'ds', name: 'DS', definition: 'ds' },
  {
    id: 'dynamic-courtesy-rigging',
    name: 'Dynamic Courtesy Rigging',
    definition: 'dynamic-courtesy-rigging',
  },
  { id: 'dynamic-rope', name: 'Dynamic Rope', definition: 'dynamic-rope' },
  { id: 'dyneema', name: 'Dyneema', definition: 'dyneema' },
  // e
  { id: 'earnest', name: 'EARNEST', definition: 'earnest' },
  {
    id: 'edge-protection',
    name: 'Edge Protection',
    definition: 'edge-protection',
  },
  { id: 'edk', name: 'EDK', definition: 'flat-overhand-bend' },
  { id: 'egress', name: 'Egress', definition: 'egress' },
  { id: 'elevator', name: 'Elevator', definition: 'chimney' },
  { id: 'elevatoring', name: 'Elevatoring', definition: 'elevatoring' },
  { id: 'elongation', name: 'Elongation', definition: 'elongation' },
  {
    id: 'emergency-medical-services',
    name: 'Emergency Medical Services',
    definition: 'emergency-medical-services',
  },
  { id: 'ems', name: 'EMS', definition: 'emergency-medical-services' },
  {
    id: 'entrada-sandstone',
    name: 'Entrada Sandstone',
    definition: 'entrada-sandstone',
  },
  {
    id: 'epic',
    name: 'Epic',
    definition: 'epic',
  },
  { id: 'equalette', name: 'Equalette', definition: 'equalette' },
  { id: 'equalized', name: 'Equalized', definition: 'equalized' },
  { id: 'etrier', name: 'Etrier', definition: 'etrier' },
  { id: 'euro-8', name: 'Euro 8', definition: 'euro-8' },
  {
    id: 'european-death-knot',
    name: 'European Death Knot',
    definition: 'flat-overhand-bend',
  },
  { id: 'exit', name: 'Exit', definition: 'exit' },
  {
    id: 'expansion-bolt',
    name: 'Expansion Bolt',
    definition: 'expansion-bolt',
  },
  { id: 'exposure', name: 'Exposure', definition: 'exposure' },
  // f
  { id: 'fall-factor', name: 'Fall Factor', definition: 'fall-factor' },
  { id: 'fall-zone', name: 'Fall Zone', definition: 'fall-zone' },
  { id: 'farmer-john', name: 'Farmer John', definition: 'farmer-john' },
  { id: 'fast-rope', name: 'Fast Rope', definition: 'fast-rope' },
  { id: 'fat-rope', name: 'Fat Rope', definition: 'fat-rope' },
  { id: 'fiddlestick', name: 'FiddleStick', definition: 'fiddlestick' },
  { id: 'figure-8-braid', name: 'Figure 8 Braid', definition: 'euro-8' },
  { id: 'figure-8-knot', name: 'Figure 8 Knot', definition: 'figure-8-knot' },
  {
    id: 'figure-8-on-a-bight',
    name: 'Figure 8 on a Bight',
    definition: 'figure-8-on-a-bight',
  },
  {
    id: 'figure-8-rappel-device',
    name: 'Figure 8 (Rappel Device)',
    definition: 'figure-8-rappel-device',
  },
  {
    id: 'figure-of-8-knot',
    name: 'Figure of 8 Knot',
    definition: 'figure-8-knot',
  },
  { id: 'fireman-belay', name: 'Fireman Belay', definition: 'bottom-belay' },
  { id: 'flake', name: 'Flake', definition: 'flake' },
  { id: 'flash', name: 'Flash', definition: 'flash' },
  { id: 'flash-flood', name: 'Flash Flood', definition: 'flash-flood' },
  { id: 'flat-bend', name: 'Flat Bend', definition: 'flat-bend' },
  {
    id: 'flat-double-overhand-bend',
    name: 'Flat Double Overhand Bend',
    definition: 'flat-double-overhand-bend',
  },
  {
    id: 'flat-overhand-bend',
    name: 'Flat Overhand Bend',
    definition: 'flat-overhand-bend',
  },
  { id: 'flemish-bend', name: 'Flemish Bend', definition: 'flemish-bend' },
  {
    id: 'floating-disconnect',
    name: 'Floating Disconnect',
    definition: 'floating-disconnect',
  },
  { id: 'flute', name: 'Flute', definition: 'flute' },
  { id: 'focal-point', name: 'Focal Point', definition: 'master-point' },
  {
    id: 'focused-rigging',
    name: 'Focused Rigging',
    definition: 'focused-rigging',
  },
  {
    id: 'foot-entrapment',
    name: 'Foot Entrapment',
    definition: 'foot-entrapment',
  },
  { id: 'foot-loop', name: 'Foot Loop', definition: 'foot-loop' },
  { id: 'free-hang', name: 'Free Hang', definition: 'free-hang' },
  { id: 'free-rappel', name: 'Free Rappel', definition: 'free-rappel' },
  {
    id: 'friction-hitch',
    name: 'Friction Hitch',
    definition: 'friction-hitch',
  },
  { id: 'frost-knot', name: 'Frost Knot', definition: 'frost-knot' },
  { id: 'full-wetsuit', name: 'Full Wetsuit', definition: 'full-wetsuit' },
  // g
  { id: 'g-pick', name: 'G-Pick', definition: 'geologists-pick' },
  { id: 'galumph', name: 'Galumph', definition: 'galumph' },
  { id: 'gate', name: 'Gate', definition: 'gate' },
  { id: 'gcnra', name: 'GCNRA', definition: 'gcnra' },
  { id: 'gear-loop', name: 'Gear Loop', definition: 'gear-loop' },
  {
    id: 'geologists-pick',
    name: "Geologist's Pick",
    definition: 'geologists-pick',
  },
  { id: 'ghost', name: 'Ghost', definition: 'ghost' },
  { id: 'girth-hitch', name: 'Girth Hitch', definition: 'girth-hitch' },
  { id: 'glazing', name: 'Glazing', definition: 'glazing' },
  {
    id: 'global-positioning-system',
    name: 'Global Positioning System',
    definition: 'global-positioning-system',
  },
  { id: 'gloves', name: 'Gloves', definition: 'gloves' },
  { id: 'glue-in', name: 'Glue-In', definition: 'glue-in' },
  { id: 'gorging', name: 'Gorging', definition: 'gorging' },
  { id: 'gorp', name: 'GORP', definition: 'gorp' },
  { id: 'gps', name: 'GPS', definition: 'global-positioning-system' },
  {
    id: 'grappling-hook',
    name: 'Grappling Hook',
    definition: 'grappling-hook',
  },
  { id: 'gsenm', name: 'GSENM', definition: 'gsenm' },
  { id: 'guide-hand', name: 'Guide Hand', definition: 'guide-hand' },
  { id: 'guide-line', name: 'Guide Line', definition: 'guide-line' },
  { id: 'guided-rappel', name: 'Guided Rappel', definition: 'guided-rappel' },
  { id: 'guitar-hero', name: 'Guitar Hero', definition: 'guitar-hero' },
  // h
  { id: 'hallway', name: 'Hallway', definition: 'joint' },
  { id: 'hand-line', name: 'Hand Line', definition: 'hand-line' },
  {
    id: 'hands-free-backup',
    name: 'Hands-Free Backup',
    definition: 'hands-free-backup',
  },
  { id: 'hanger', name: 'Hanger', definition: 'hanger' },
  { id: 'happy-hooker', name: 'Happy Hooker', definition: 'happy-hooker' },
  { id: 'hard-lock-off', name: 'Hard Lock-off', definition: 'hard-lock-off' },
  { id: 'hard-start', name: 'Hard Start', definition: 'hard-start' },
  { id: 'harness', name: 'Harness', definition: 'harness' },
  {
    id: 'harness-interruptus',
    name: 'Harness Interruptus',
    definition: 'harness-interruptus',
  },
  { id: 'haul-system', name: 'Haul System', definition: 'haul-system' },
  { id: 'hcv', name: 'HCV', definition: 'hcv' },
  { id: 'helmet', name: 'Helmet', definition: 'helmet' },
  { id: 'helmet-police', name: 'Helmet Police', definition: 'helmet-police' },
  { id: 'helmet-stand', name: 'Helmet Stand', definition: 'helmet-stand' },
  { id: 'high-stemming', name: 'High Stemming', definition: 'high-stemming' },
  { id: 'hitch', name: 'Hitch', definition: 'hitch' },
  { id: 'hitrr', name: 'HITRR', definition: 'hitrr' },
  { id: 'hms-carabiner', name: 'HMS Carabiner', definition: 'hms-carabiner' },
  { id: 'hoodoo', name: 'HOODOO', definition: 'hoodoo' },
  {
    id: 'hoodoo-rock-formation',
    name: 'Hoodoo (Rock Formation)',
    definition: 'hoodoo-rock-formation',
  },
  { id: 'hook', name: 'Hook', definition: 'hook' },
  { id: 'hooking', name: 'Hooking', definition: 'hooking' },
  {
    id: 'hueco',
    name: 'Hueco',
    definition: 'solution-pocket',
    specific: <>Means "hole" in Spanish.</>,
  },
  { id: 'human-anchor', name: 'Human Anchor', definition: 'human-anchor' },
  { id: 'human-pyramid', name: 'Human Pyramid', definition: 'human-pyramid' },
  // i
  {
    id: 'i-beam-carabiner',
    name: 'I-Beam Carabiner',
    definition: 'i-beam-carabiner',
  },
  { id: 'i-time-rating', name: 'I (Time Rating)', definition: 'i-time-rating' },
  { id: 'ibis-hook', name: 'Ibis Hook', definition: 'ibis-hook' },
  { id: 'icopro', name: 'ICOpro', definition: 'icopro' },
  {
    id: 'ii-time-rating',
    name: 'II (Time Rating)',
    definition: 'ii-time-rating',
  },
  {
    id: 'iii-time-rating',
    name: 'III (Time Rating)',
    definition: 'iii-time-rating',
  },
  {
    id: 'imlay-canyon-gear',
    name: 'Imlay Canyon Gear',
    definition: 'imlay-canyon-gear',
  },
  { id: 'inreach', name: 'inReach', definition: 'inreach' },
  { id: 'italian-hitch', name: 'Italian Hitch', definition: 'munter-hitch' },
  {
    id: 'iv-time-rating',
    name: 'IV (Time Rating)',
    definition: 'iv-time-rating',
  },
  // j
  { id: 'jester', name: 'Jester', definition: 'jester' },
  { id: 'joint', name: 'Joint', definition: 'joint' },
  { id: 'joker', name: 'Joker', definition: 'joker' },
  { id: 'jug', name: 'Jug', definition: 'ascend' },
  { id: 'jumar', name: 'Jumar', definition: 'jumar' },
  // k
  {
    id: 'kayenta-sandstone',
    name: 'Kayenta Sandstone',
    definition: 'kayenta-sandstone',
  },
  {
    id: 'keeper-pothole',
    name: 'Keeper Pothole',
    definition: 'keeper-pothole',
  },
  { id: 'keg', name: 'Keg', definition: 'keg' },
  { id: 'kelsey-exit', name: 'Kelsey Exit', definition: 'kelsey-exit' },
  {
    id: 'kernmantle-rope',
    name: 'Kernmantle Rope',
    definition: 'kernmantle-rope',
  },
  { id: 'kilonewton', name: 'Kilonewton', definition: 'kilonewton' },
  { id: 'kn', name: 'kN', definition: 'kilonewton' },
  { id: 'knot', name: 'Knot', definition: 'knot' },
  { id: 'knot-block', name: 'Knot Block', definition: 'knot-block' },
  { id: 'knot-chock', name: 'Knot Chock', definition: 'knot-chock' },
  // l
  {
    id: 'lamar',
    name: 'LAMAR',
    definition: 'last-person-at-risk',
    specific: (
      <>This is a politically incorrect term and is no longer commonly used.</>
    ),
  },
  { id: 'lapar', name: 'LAPAR', definition: 'last-person-at-risk' },
  {
    id: 'last-person-at-risk',
    name: 'Last Person At Risk',
    definition: 'last-person-at-risk',
  },
  { id: 'lcv', name: 'LCV', definition: 'lcv' },
  { id: 'ldc', name: 'LDC', definition: 'left-down-canyon' },
  {
    id: 'leave-no-trace',
    name: 'Leave No Trace',
    definition: 'leave-no-trace',
  },
  {
    id: 'left-down-canyon',
    name: 'Left Down Canyon',
    definition: 'left-down-canyon',
  },
  {
    id: 'left-up-canyon',
    name: 'Left Up Canyon',
    definition: 'left-up-canyon',
  },
  { id: 'leg-loop', name: 'Leg Loop', definition: 'leg-loop' },
  { id: 'leg-wrap', name: 'Leg Wrap', definition: 'leg-wrap' },
  { id: 'leo', name: 'LEO', definition: 'leo' },
  {
    id: 'lift-and-shift',
    name: 'Lift and Shift',
    definition: 'lift-and-shift',
  },
  {
    id: 'lmar',
    name: 'LMAR',
    definition: 'last-person-at-risk',
    specific: (
      <>This is a politically incorrect term and is no longer commonly used.</>
    ),
  },
  {
    id: 'lmr',
    name: 'LMR',
    definition: 'last-person-at-risk',
    specific: (
      <>This is a politically incorrect term and is no longer commonly used.</>
    ),
  },
  {
    id: 'lnt',
    name: 'LNT',
    definition: 'lnt',
  },
  {
    id: 'load-releasing-hitch',
    name: 'Load Releasing Hitch',
    definition: 'load-releasing-hitch',
  },
  {
    id: 'loaded-for-bear',
    name: 'Loaded For Bear',
    definition: 'loaded-for-bear',
  },
  { id: 'log-soup', name: 'Log Soup', definition: 'log-soup' },
  { id: 'logjam', name: 'Logjam', definition: 'logjam' },
  { id: 'long-rappel', name: 'Long Rappel', definition: 'long-rappel' },
  { id: 'loop', name: 'Loop', definition: 'loop' },
  { id: 'lower', name: 'Lower', definition: 'lower' },
  { id: 'lpar', name: 'LPAR', definition: 'last-person-at-risk' },
  { id: 'luc', name: 'LUC', definition: 'left-down-canyon' },
  // m
  { id: 'ma', name: 'MA', definition: 'mechanical-advantage' },
  { id: 'macrame', name: 'Macrame', definition: 'macrame' },
  {
    id: 'mae-west-slot',
    name: 'Mae West Slot',
    definition: 'bell',
    specific: (
      <>
        Named after{' '}
        <Link to="https://en.wikipedia.org/wiki/Mae_West">the actress</Link>.
      </>
    ),
  },
  { id: 'magic-x', name: 'Magic X', definition: 'magic-x' },
  { id: 'major-axis', name: 'Major Axis', definition: 'major-axis' },
  {
    id: 'marginal-anchor',
    name: 'Marginal Anchor',
    definition: 'marginal-anchor',
  },
  {
    id: 'mariners-hitch',
    name: "Mariner's Hitch",
    definition: 'mariners-hitch',
  },
  { id: 'master-point', name: 'Master Point', definition: 'master-point' },
  { id: 'maxxipad', name: 'MaxxiPad', definition: 'maxxipad' },
  { id: 'mbs', name: 'MBS', definition: 'minimum-breaking-strength' },
  { id: 'meat-anchor', name: 'Meat Anchor', definition: 'human-anchor' },
  {
    id: 'mechanical-advantage',
    name: 'Mechanical Advantage',
    definition: 'mechanical-advantage',
  },
  { id: 'mia-exit', name: 'MIA Exit', definition: 'mia-exit' },
  { id: 'micro-traxion', name: 'Micro Traxion', definition: 'micro-traxion' },
  {
    id: 'minimum-breaking-strength',
    name: 'Minimum Breaking Strength',
    definition: 'minimum-breaking-strength',
  },
  { id: 'minor-axis', name: 'Minor Axis', definition: 'minor-axis' },
  { id: 'mmo', name: 'MMO', definition: 'munter-mule-overhand' },
  { id: 'moki', name: 'Moki', definition: 'moqui' },
  {
    id: 'monsoon-season',
    name: 'Monsoon Season',
    definition: 'monsoon-season',
  },
  {
    id: 'monsoonal-rain',
    name: 'Monsoonal Rain',
    definition: 'monsoonal-rain',
  },
  { id: 'moqui', name: 'Moqui', definition: 'moqui' },
  { id: 'moqui-marble', name: 'Moqui Marble', definition: 'moqui-marble' },
  { id: 'moqui-steps', name: 'Moqui Steps', definition: 'moqui-steps' },
  { id: 'mountain', name: 'Mountain', definition: 'mountain' },
  { id: 'mule-hitch', name: 'Mule Hitch', definition: 'mule-hitch' },
  {
    id: 'multi-directional-load',
    name: 'Multi-Directional Load',
    definition: 'multi-directional-load',
  },
  {
    id: 'multi-pitch-rappel',
    name: 'Multi-Pitch Rappel',
    definition: 'multi-stage-rappel',
  },
  {
    id: 'multi-point-anchor',
    name: 'Multi-Point Anchor',
    definition: 'multi-point-anchor',
  },
  {
    id: 'multi-stage-rappel',
    name: 'Multi-Stage Rappel',
    definition: 'multi-stage-rappel',
  },
  { id: 'munter-hitch', name: 'Munter Hitch', definition: 'munter-hitch' },
  {
    id: 'munter-mule-overhand',
    name: 'Munter Mule Overhand',
    definition: 'munter-mule-overhand',
  },
  // n
  { id: 'narrows', name: 'Narrows', definition: 'slot-canyon' },
  {
    id: 'national-park-service',
    name: 'National Park Service',
    definition: 'national-park-service',
  },
  {
    id: 'natural-anchor',
    name: 'Natural Anchor',
    definition: 'natural-anchor',
  },
  {
    id: 'natural-bridge',
    name: 'Natural Bridge',
    definition: 'natural-bridge',
  },
  {
    id: 'navajo-sandstone',
    name: 'Navajo Sandstone',
    definition: 'navajo-sandstone',
  },
  { id: 'neo', name: 'Neo', definition: 'neoprene' },
  { id: 'neoprene', name: 'Neoprene', definition: 'neoprene' },
  { id: 'no-extension', name: 'No Extension', definition: 'no-extension' },
  {
    id: 'nose-carabiner',
    name: 'Nose (Carabiner)',
    definition: 'nose-carabiner',
  },
  { id: 'nose-landform', name: 'Nose (Landform)', definition: 'spur' },
  { id: 'nps', name: 'NPS', definition: 'national-park-service' },
  {
    id: 'nuisance-rappel',
    name: 'Nuisance Rappel',
    definition: 'nuisance-rappel',
  },
  { id: 'nylon', name: 'Nylon', definition: 'nylon' },
  // o
  { id: 'off-width', name: 'Off Width', definition: 'off-width' },
  {
    id: 'on-rope-canyoneering',
    name: 'On Rope Canyoneering',
    definition: 'on-rope-canyoneering',
  },
  {
    id: 'open-end-prusik',
    name: 'Open-end Prusik',
    definition: 'open-end-prusik',
  },
  { id: 'orv', name: 'ORV', definition: 'orv' },
  { id: 'overhand-knot', name: 'Overhand Knot', definition: 'overhand-knot' },
  {
    id: 'overhand-on-a-bight',
    name: 'Overhand on a Bight',
    definition: 'overhand-on-a-bight',
  },
  { id: 'overhang', name: 'Overhang', definition: 'overhang' },
  { id: 'ow', name: 'OW', definition: 'off-width' },
  // p
  { id: 'pack-drag', name: 'Pack Drag', definition: 'pack-drag' },
  { id: 'pack-rope', name: 'Pack Rope', definition: 'pack-rope' },
  { id: 'pack-toss', name: 'Pack Toss', definition: 'pack-toss' },
  { id: 'packraft', name: 'Packraft', definition: 'packraft' },
  {
    id: 'partner-assist',
    name: 'Partner Assist',
    definition: 'partner-assist',
  },
  {
    id: 'partner-capture',
    name: 'Partner Capture',
    definition: 'partner-capture',
  },
  {
    id: 'pas',
    name: 'PAS',
    definition: 'safety-tether',
    specific: (
      <>
        Stands for <strong>P</strong>ersonal <strong>A</strong>nchor{' '}
        <strong>S</strong>ystem. Manufactured by{' '}
        <Link to="https://www.metoliusclimbing.com/pas_personal_anchor_system.html">
          Metolius
        </Link>
        .
      </>
    ),
  },
  { id: 'pass-a-knot', name: 'Pass a knot', definition: 'pass-a-knot' },
  { id: 'pcd', name: 'PCD', definition: 'progress-capture-device' },
  { id: 'peak', name: 'Peak', definition: 'peak' },
  {
    id: 'personal-locator-beacon',
    name: 'Personal Locator Beacon',
    definition: 'personal-locator-beacon',
  },
  { id: 'petroglyph', name: 'Petroglyph', definition: 'petroglyph' },
  { id: 'pick-off', name: 'Pick-off', definition: 'pick-off' },
  { id: 'picket', name: 'Picket', definition: 'picket' },
  { id: 'picpocket', name: 'PicPocket', definition: 'picpocket' },
  { id: 'pictograph', name: 'Pictograph', definition: 'pictograph' },
  { id: 'pig-rig', name: 'Pig Rig', definition: 'piggyback' },
  { id: 'piggyback', name: 'Piggyback', definition: 'piggyback' },
  { id: 'piton', name: 'Piton', definition: 'piton' },
  { id: 'plaquette', name: 'Plaquette', definition: 'plaquette' },
  { id: 'plb', name: 'PLB', definition: 'personal-locator-beacon' },
  {
    id: 'pmp',
    name: 'PMP',
    definition: 'prusik-minding-pulley',
    specific: (
      <>
        Stands for <strong>P</strong>rusik <strong>M</strong>inding{' '}
        <strong>P</strong>ulley.
      </>
    ),
  },
  { id: 'pnw', name: 'PNW', definition: 'pnw' },
  { id: 'poach', name: 'Poach', definition: 'poach' },
  { id: 'polyester', name: 'Polyester', definition: 'polyester' },
  { id: 'polypropylene', name: 'Polypropylene', definition: 'polypropylene' },
  { id: 'pot-sticker', name: 'Pot Sticker', definition: 'pot-sticker' },
  { id: 'potable', name: 'Potable', definition: 'potable' },
  { id: 'pothole', name: 'Pothole', definition: 'pothole' },
  {
    id: 'pothole-escape-technique',
    name: 'Pothole Escape Technique',
    definition: 'pothole-escape-technique',
  },
  { id: 'potshot', name: 'PotShot', definition: 'potshot' },
  {
    id: 'progress-capture-device',
    name: 'Progress Capture Device',
    definition: 'progress-capture-device',
  },
  { id: 'prusik', name: 'Prusik', definition: 'prusik' },
  {
    id: 'prusik-minding-pulley',
    name: 'Prusik Minding Pulley',
    definition: 'prusik-minding-pulley',
  },
  { id: 'pull', name: 'Pull', definition: 'pull' },
  { id: 'pull-cord', name: 'Pull Cord', definition: 'pull-cord' },
  { id: 'pull-side', name: 'Pull Side', definition: 'pull-side' },
  { id: 'pulley', name: 'Pulley', definition: 'pulley' },
  {
    id: 'purcell-prusik',
    name: 'Purcell Prusik',
    definition: 'purcell-prusik',
  },
  // q
  { id: 'quad', name: 'Quad', definition: 'quad' },
  { id: 'quick-link', name: 'Quick Link', definition: 'rapide' },
  { id: 'quickdraw', name: 'Quickdraw', definition: 'quickdraw' },
  { id: 'quicksand', name: 'Quicksand', definition: 'quicksand' },
  // r
  { id: 'r-risk-rating', name: 'R (Risk Rating)', definition: 'r-risk-rating' },
  { id: 'r-section', name: 'R Section', definition: 'r-section' },
  { id: 'ralston-rock', name: 'Ralston Rock', definition: 'ralston-rock' },
  {
    id: 'ralstone',
    name: 'Ralstone',
    definition: 'ralston-rock',
    specific: <>Portmanteau of Ralston and stone.</>,
  },
  { id: 'rap', name: 'Rap', definition: 'rappel' },
  { id: 'rapid-link', name: 'Rapid Link', definition: 'rapide' },
  { id: 'rapide', name: 'Rapide', definition: 'rapide' },
  { id: 'rappel', name: 'Rappel', definition: 'rappel' },
  {
    id: 'rappel-deviation',
    name: 'Rappel Deviation',
    definition: 'rappel-deviation',
  },
  { id: 'rappel-device', name: 'Rappel Device', definition: 'rappel-device' },
  { id: 'rappel-end', name: 'Rappel End', definition: 'working-end-rope' },
  { id: 'rappel-line', name: 'Rappel Line', definition: 'rappel-line' },
  {
    id: 'rappel-redirect',
    name: 'Rappel Redirect',
    definition: 'rappel-deviation',
  },
  { id: 'rappel-ring', name: 'Rappel Ring', definition: 'rappel-ring' },
  { id: 'rappel-side', name: 'Rappel Side', definition: 'working-end-rope' },
  { id: 'rappeller', name: 'Rappeller', definition: 'rappeller' },
  {
    id: 'rappelling-harness',
    name: 'Rappelling Harness',
    definition: 'rappelling-harness',
  },
  { id: 'ratchet', name: 'Ratchet', definition: 'progress-capture-device' },
  { id: 'rdc', name: 'RDC', definition: 'right-down-canyon' },
  { id: 'rebelay', name: 'Rebelay', definition: 'rebelay' },
  {
    id: 'recirculating-current',
    name: 'Recirculating Current',
    definition: 'recirculating-current',
  },
  { id: 'redirect', name: 'Redirect', definition: 'redirect' },
  { id: 'redundant', name: 'Redundant', definition: 'redundant' },
  {
    id: 'redundant-wrap',
    name: 'Redundant Wrap',
    definition: 'redundant-wrap',
  },
  {
    id: 'releasable-rigging-system',
    name: 'Releasable Rigging System',
    definition: 'releasable-rigging-system',
    specific: (
      <>
        <br />
        The term "releasable rigging system" is also commonly confused with{' '}
        <Link to="#retrievable-rigging-system">retrievable rigging system</Link>
        .
      </>
    ),
  },
  {
    id: 'releasable-twin-rope-system',
    name: 'Releasable Twin Rope System',
    definition: 'releasable-twin-rope-system',
  },
  { id: 'release-cord', name: 'Release Cord', definition: 'release-cord' },
  { id: 'rendezvous', name: 'Rendezvous', definition: 'rendezvous' },
  { id: 'rescue-rope', name: 'Rescue Rope', definition: 'rescue-rope' },
  { id: 'restop', name: 'Restop', definition: 'restop' },
  {
    id: 'rethreaded-figure-8-bend',
    name: 'Rethreaded Figure 8 Bend',
    definition: 'flemish-bend',
  },
  {
    id: 'retrievable-rigging-system',
    name: 'Retrievable Rigging System',
    definition: 'retrievable-rigging-system',
  },
  { id: 'ridge', name: 'Ridge', definition: 'ridge' },
  { id: 'rigging', name: 'Rigging', definition: 'rigging' },
  {
    id: 'right-down-canyon',
    name: 'Right Down Canyon',
    definition: 'right-down-canyon',
  },
  {
    id: 'right-up-canyon',
    name: 'Right Up Canyon',
    definition: 'right-up-canyon',
  },
  { id: 'ring-bend', name: 'Ring Bend', definition: 'ring-bend' },
  { id: 'risk-rating', name: 'Risk Rating', definition: 'risk-rating' },
  {
    id: 'road-trip-ryan',
    name: 'Road Trip Ryan',
    definition: 'road-trip-ryan',
  },
  {
    id: 'rock-formation',
    name: 'Rock Formation',
    definition: 'rock-formation',
  },
  { id: 'roll', name: 'Roll', definition: 'capsize' },
  { id: 'rope', name: 'Rope', definition: 'rope' },
  { id: 'rope-bag', name: 'Rope Bag', definition: 'rope-bag' },
  {
    id: 'rope-entanglement',
    name: 'Rope Entanglement',
    definition: 'rope-entanglement',
  },
  { id: 'rope-grab', name: 'Rope Grab', definition: 'rope-grab' },
  { id: 'rope-groove', name: 'Rope Groove', definition: 'rope-groove' },
  {
    id: 'rope-management',
    name: 'Rope Management',
    definition: 'rope-management',
  },
  { id: 'rope-spool', name: 'Rope Spool', definition: 'rope-spool' },
  { id: 'ropewiki', name: 'Ropewiki', definition: 'ropewiki' },
  { id: 'ruc', name: 'RUC', definition: 'right-up-canyon' },
  { id: 'runner', name: 'Runner', definition: 'sling' },
  { id: 'runner-end', name: 'Runner End', definition: 'skinny-end' },
  // s
  {
    id: 'saddle',
    name: 'Saddle',
    definition: 'saddle',
  },
  { id: 'safety-check', name: 'Safety Check', definition: 'safety-check' },
  { id: 'safety-line', name: 'Safety Line', definition: 'safety-line' },
  {
    id: 'safety-rigging',
    name: 'Safety (Rigging)',
    definition: 'safety-rigging',
  },
  { id: 'safety-tether', name: 'Safety Tether', definition: 'safety-tether' },
  { id: 'sand-bag', name: 'Sand Bag', definition: 'sand-bag' },
  { id: 'sand-trap', name: 'Sand Trap', definition: 'sand-trap' },
  {
    id: 'sand-trap-anchor',
    name: 'Sand Trap Anchor',
    definition: 'sand-trap-anchor',
  },
  { id: 'sandstone', name: 'Sandstone', definition: 'sandstone' },
  { id: 'sandtrap', name: 'SandTrap', definition: 'sandtrap' },
  { id: 'sar', name: 'SAR', definition: 'search-and-rescue' },
  { id: 'scrambling', name: 'Scrambling', definition: 'scrambling' },
  {
    id: 'scree',
    name: 'Scree',
    definition: 'scree',
    specific: 'Means "landslide" in Old Norse.',
  },
  { id: 'scuttlebutt', name: 'Scuttlebutt', definition: 'scuttlebutt' },
  {
    id: 'search-and-rescue',
    name: 'Search and Rescue',
    definition: 'search-and-rescue',
  },
  { id: 'secret-canyon', name: 'Secret Canyon', definition: 'unbetaed-canyon' },
  { id: 'self-belay', name: 'Self Belay', definition: 'self-belay' },
  { id: 'self-minding', name: 'Self-Minding', definition: 'self-minding' },
  { id: 'self-rescue', name: 'Self Rescue', definition: 'self-rescue' },
  {
    id: 'semi-keeper-pothole',
    name: 'Semi-Keeper Pothole',
    definition: 'semi-keeper-pothole',
  },
  {
    id: 'sequence-rappels',
    name: 'Sequence Rappels',
    definition: 'sequence-rappels',
  },
  { id: 'sequencing', name: 'Sequencing', definition: 'sequencing' },
  { id: 'serene', name: 'SERENE', definition: 'serene' },
  {
    id: 'set-rope-length',
    name: 'Set Rope Length',
    definition: 'set-rope-length',
  },
  {
    id: 'sewn-prusik-loop',
    name: 'Sewn Prusik Loop',
    definition: 'sewn-prusik-loop',
  },
  { id: 'sheath', name: 'Sheath', definition: 'sheath' },
  {
    id: 'sheath-slippage',
    name: 'Sheath Slippage',
    definition: 'sheath-slippage',
  },
  { id: 'shelf', name: 'Shelf', definition: 'master-point' },
  { id: 'short-rappel', name: 'Short Rappel', definition: 'short-rappel' },
  { id: 'short-rope', name: 'Short Rope', definition: 'short-rope' },
  {
    id: 'shorty-wetsuit',
    name: 'Shorty Wetsuit',
    definition: 'shorty-wetsuit',
  },
  { id: 'shuffle-butt', name: 'Shuffle Butt', definition: 'shuffle-butt' },
  { id: 'side-drainage', name: 'Side Drainage', definition: 'draw' },
  { id: 'silo', name: 'Silo', definition: 'silo' },
  { id: 'simul-rap', name: 'Simul Rap', definition: 'simultaneous-rappel' },
  {
    id: 'simultaneous-rappel',
    name: 'Simultaneous Rappel',
    definition: 'simultaneous-rappel',
  },
  {
    id: 'single-rope-technique',
    name: 'Single Rope Technique',
    definition: 'single-rope-technique',
  },
  {
    id: 'single-strand',
    name: 'Single Strand',
    definition: 'single-rope-technique',
  },
  { id: 'sinking-sand', name: 'Sinking Sand', definition: 'quicksand' },
  { id: 'siphon', name: 'Siphon', definition: 'siphon' },
  { id: 'sit-start', name: 'Sit Start', definition: 'sit-start' },
  { id: 'sitla', name: 'SITLA', definition: 'sitla' },
  { id: 'skinny-end', name: 'Skinny End', definition: 'skinny-end' },
  { id: 'skinny-rope', name: 'Skinny Rope', definition: 'skinny-rope' },
  { id: 'slickrock', name: 'Slickrock', definition: 'sandstone' },
  { id: 'slide', name: 'Slide', definition: 'chute' },
  { id: 'sliding-x', name: 'Sliding X', definition: 'magic-x' },
  { id: 'sling', name: 'Sling', definition: 'sling' },
  { id: 'slog', name: 'Slog', definition: 'slog' },
  { id: 'slot-canyon', name: 'Slot Canyon', definition: 'slot-canyon' },
  {
    id: 'slot-designation',
    name: 'Slot Designation',
    definition: 'slot-designation',
  },
  { id: 'slow-rope', name: 'Slow Rope', definition: 'slow-rope' },
  { id: 'smear', name: 'Smear', definition: 'smear' },
  {
    id: 'smooth-operator',
    name: 'Smooth Operator',
    definition: 'smooth-operator',
  },
  { id: 'social-trail', name: 'Social Trail', definition: 'social-trail' },
  {
    id: 'soft-lock-off',
    name: 'Soft Lock-off',
    definition: 'soft-lock-off',
  },
  {
    id: 'soft-rope-grab',
    name: 'Soft Rope Grab',
    definition: 'friction-hitch',
  },
  { id: 'soft-start', name: 'Soft Start', definition: 'soft-start' },
  { id: 'solid', name: 'Solid', definition: 'solid' },
  { id: 'solo', name: 'Solo', definition: 'solo' },
  {
    id: 'solution-pocket',
    name: 'Solution Pocket',
    definition: 'solution-pocket',
  },
  { id: 'spectra', name: 'Spectra', definition: 'dyneema' },
  { id: 'spelegyca', name: 'Spelegyca', definition: 'spelegyca' },
  { id: 'spine', name: 'Spine', definition: 'spine' },
  { id: 'spot', name: 'Spot', definition: 'spot' },
  { id: 'spot-plb', name: 'SPOT (PLB)', definition: 'spot-plb' },
  {
    id: 'spring-loaded-camming-device',
    name: 'Spring-Loaded Camming Device',
    definition: 'spring-loaded-camming-device',
  },
  { id: 'spur', name: 'Spur', definition: 'spur' },
  { id: 'sqwurel', name: 'SQWUREL', definition: 'sqwurel' },
  {
    id: 'srene',
    name: 'SRENE',
    definition: 'serene',
    specific: "This variation doesn't include the word Efficient.",
  },
  { id: 'srt', name: 'SRT', definition: 'single-rope-technique' },
  {
    id: 'stacked-overhand-bend',
    name: 'Stacked Overhand Bend',
    definition: 'stacked-overhand-bend',
  },
  { id: 'star-bridging', name: 'Star Bridging', definition: 'stemming' },
  { id: 'static-block', name: 'Static Block', definition: 'static-block' },
  {
    id: 'static-block-to-lower',
    name: 'Static Block to Lower',
    definition: 'static-block-to-lower',
  },
  {
    id: 'static-courtesy-rigging',
    name: 'Static Courtesy Rigging',
    definition: 'courtesy-rigging',
    specific: (
      <>
        Compare to{' '}
        <Link to="#dynamic-courtesy-rigging">dynamic courtesy rigging</Link>.
      </>
    ),
  },
  { id: 'static-rope', name: 'Static Rope', definition: 'static-rope' },
  { id: 'stein-knot', name: 'Stein Knot', definition: 'stone-knot' },
  { id: 'stemming', name: 'Stemming', definition: 'stemming' },
  { id: 'step', name: 'STEP', definition: 'step' },
  { id: 'stone-8', name: 'Stone 8', definition: 'stone-8' },
  { id: 'stone-knot', name: 'Stone Knot', definition: 'stone-knot' },
  {
    id: 'stone-totem',
    name: 'Stone Totem',
    definition: 'stone-8',
  },
  { id: 'strainer', name: 'Strainer', definition: 'strainer' },
  {
    id: 'subterranean-deadman',
    name: 'Subterranean Deadman',
    definition: 'deadman-anchor',
  },
  { id: 'subway', name: 'Subway', definition: 'subway' },
  {
    id: 'super-amazing-canyoneering-map',
    name: 'Super Amazing Canyoneering Map',
    definition: 'super-amazing-canyoneering-map',
  },
  {
    id: 'surface-deadman',
    name: 'Surface Deadman',
    definition: 'cairn-anchor',
  },
  { id: 'suwa', name: 'SUWA', definition: 'suwa' },
  { id: 'swift-water', name: 'Swift Water', definition: 'swift-water' },
  // t
  { id: 'taco', name: 'Taco', definition: 'taco' },
  {
    id: 'tafoni',
    name: 'Tafoni',
    definition: 'solution-pocket',
    specific: (
      <>
        <br />
        Singular form is tafone. Probably from{' '}
        <Link to="http://www.tafoni.com/Etymology.html">
          <em>taffoni</em>
        </Link>{' '}
        which means "windows" in Corsican.
      </>
    ),
  },
  { id: 'tag-line', name: 'Tag Line', definition: 'guide-line' },
  { id: 'tail', name: 'Tail', definition: 'tail' },
  {
    id: 'tail-down-rigging-system',
    name: 'Tail Down Rigging System',
    definition: 'bag-up-rigging-system',
  },
  {
    id: 'tail-up-retrievable-rigging-system',
    name: 'Tail Up Retrievable Rigging System',
    definition: 'tail-up-retrievable-rigging-system',
  },
  {
    id: 'tail-up-rigging-system',
    name: 'Tail Up Rigging System',
    definition: 'tail-up-rigging-system',
  },
  { id: 'talon', name: 'Talon', definition: 'talon' },
  {
    id: 'talus',
    name: 'Talus',
    definition: 'scree',
    specific: 'Means "slope" in Old French.',
  },
  { id: 'tandem-rappel', name: 'Tandem Rappel', definition: 'tandem-rappel' },
  { id: 'tape', name: 'Tape', definition: 'webbing' },
  {
    id: 'technical-ledge',
    name: 'Technical Ledge',
    definition: 'master-point',
  },
  {
    id: 'technical-rating',
    name: 'Technical Rating',
    definition: 'technical-rating',
  },
  {
    id: 'technical-section',
    name: 'Technical Section',
    definition: 'technical-section',
  },
  { id: 'technora', name: 'Technora', definition: 'technora' },
  {
    id: 'tensile-strength',
    name: 'Tensile Strength',
    definition: 'tensile-strength',
  },
  {
    id: 'tensionless-rigging',
    name: 'Tensionless Rigging',
    definition: 'tensionless-rigging',
  },
  { id: 'tg', name: 'T&G', definition: 'toss-n-go' },
  { id: 'throw-n-go', name: "Throw 'N Go", definition: 'toss-n-go' },
  { id: 'tibloc', name: 'Tibloc', definition: 'tibloc' },
  { id: 'time-rating', name: 'Time Rating', definition: 'time-rating' },
  { id: 'timely', name: 'Timely', definition: 'timely' },
  { id: 'tng', name: 'TNG', definition: 'toss-n-go' },
  { id: 'toggle', name: 'Toggle', definition: 'toggle' },
  {
    id: 'toothed-ascender',
    name: 'Toothed Ascender',
    definition: 'toothed-ascender',
  },
  {
    id: 'top-rope-belay',
    name: 'Top-Rope Belay',
    definition: 'top-rope-belay',
  },
  {
    id: 'topo',
    name: 'Topo',
    definition: 'topographic-map',
  },
  {
    id: 'topographic-map',
    name: 'Topographic Map',
    definition: 'topographic-map',
  },
  { id: 'toss-n-go', name: "Toss 'N Go", definition: 'toss-n-go' },
  { id: 'tostada', name: 'Tostada', definition: 'tostada' },
  { id: 'totem', name: 'Totem', definition: 'totem' },
  { id: 'track-line', name: 'Track Line', definition: 'guide-line' },
  { id: 'trade-canyon', name: 'Trade Canyon', definition: 'trade-canyon' },
  {
    id: 'transient-anchor',
    name: 'Transient Anchor',
    definition: 'transient-anchor',
  },
  { id: 'trash-compactor', name: 'Trash Compactor', definition: 'log-soup' },
  {
    id: 'traveling-rope-grab',
    name: 'Traveling Rope Grab',
    definition: 'traveling-rope-grab',
  },
  { id: 'traverse', name: 'Traverse', definition: 'traverse' },
  { id: 'traverse-line', name: 'Traverse Line', definition: 'safety-line' },
  { id: 'triaxial-load', name: 'Triaxial Load', definition: 'triaxial-load' },
  {
    id: 'triple-clove-hitch',
    name: 'Triple Clove Hitch',
    definition: 'triple-clove-hitch',
  },
  { id: 'truck', name: 'Truck', definition: 'traveling-rope-grab' },
  {
    id: 'tubular-webbing',
    name: 'Tubular Webbing',
    definition: 'tubular-webbing',
  },
  {
    id: 'twin-rope-system',
    name: 'Twin Rope System',
    definition: 'twin-rope-system',
  },
  {
    id: 'tyrolean-traverse',
    name: 'Tyrolean Traverse',
    definition: 'tyrolean-traverse',
  },
  // u
  { id: 'uiaa', name: 'UIAA', definition: 'uiaa' },
  {
    id: 'unbetaed-canyon',
    name: 'Unbetaed Canyon',
    definition: 'unbetaed-canyon',
  },
  { id: 'undercut', name: 'Undercut', definition: 'undercut' },
  { id: 'up-canyon', name: 'Up Canyon', definition: 'up-canyon' },
  { id: 'usgs', name: 'USGS', definition: 'usgs' },
  {
    id: 'usgs-topographic-maps',
    name: 'USGS Topographic Maps',
    definition: 'usgs-topographic-maps',
  },
  { id: 'utility-cord', name: 'Utility Cord', definition: 'cordelette' },
  // v
  { id: 'v-time-rating', name: 'V (Time Rating)', definition: 'v-time-rating' },
  { id: 'v7-academy', name: 'V7 Academy', definition: 'v7-academy' },
  {
    id: 'valdotain-tresse',
    name: 'Valdotain Tresse',
    definition: 'valdotain-tresse',
  },
  { id: 'vertaco', name: 'Vertaco', definition: 'vertaco' },
  {
    id: 'vi-time-rating',
    name: 'VI (Time Rating)',
    definition: 'vi-time-rating',
  },
  { id: 'via-ferrata', name: 'Via Ferrata', definition: 'via-ferrata' },
  { id: 'vrg', name: 'VRG', definition: 'vrg' },
  { id: 'vt', name: 'VT', definition: 'valdotain-tresse' },
  { id: 'vt-prusik', name: 'VT Prusik', definition: 'vt-prusik' },
  // w
  { id: 'wade', name: 'Wade', definition: 'wade' },
  { id: 'wag-bag', name: 'Wag Bag', definition: 'wag-bag' },
  { id: 'waist-belt', name: 'Waist Belt', definition: 'waist-belt' },
  { id: 'wanchor', name: "W'anchor", definition: 'wanchor' },
  { id: 'water-anchor', name: 'Water Anchor', definition: 'water-anchor' },
  { id: 'water-hazard', name: 'Water Hazard', definition: 'water-hazard' },
  { id: 'water-knot', name: 'Water Knot', definition: 'water-knot' },
  { id: 'water-pocket', name: 'Water Pocket', definition: 'pothole' },
  { id: 'water-rating', name: 'Water Rating', definition: 'water-rating' },
  { id: 'waterpocket', name: 'Waterpocket', definition: 'waterpocket' },
  { id: 'watershed', name: 'Watershed', definition: 'drainage' },
  { id: 'wccm', name: 'WCCM', definition: 'wccm' },
  { id: 'webbing', name: 'Webbing', definition: 'webbing' },
  { id: 'wetsuit', name: 'Wetsuit', definition: 'wetsuit' },
  { id: 'whiptail', name: 'Whiptail', definition: 'whiptail' },
  { id: 'wide-end', name: 'Wide End', definition: 'working-end-carabiner' },
  {
    id: 'wingate-sandstone',
    name: 'Wingate Sandstone',
    definition: 'wingate-sandstone',
  },
  {
    id: 'working-end-carabiner',
    name: 'Working End (Carabiner)',
    definition: 'working-end-carabiner',
  },
  {
    id: 'working-end-rope',
    name: 'Working End (Rope)',
    definition: 'working-end-rope',
  },
  { id: 'worm', name: 'Worm', definition: 'down-climb' },
  { id: 'wrap-3-pull-2', name: 'Wrap 3 Pull 2', definition: 'wrap-3-pull-2' },
  // x
  { id: 'x-canyon', name: 'X Canyon', definition: 'x-canyon' },
  { id: 'x-risk-rating', name: 'X (Risk Rating)', definition: 'x-risk-rating' },
  { id: 'x-section', name: 'X Section', definition: 'x-section' },
  {
    id: 'xx-risk-rating',
    name: 'XX (Risk Rating)',
    definition: 'xx-risk-rating',
  },
  // y
  {
    id: 'yahoo-canyons-group',
    name: 'Yahoo Canyons Group',
    definition: 'yahoo-canyons-group',
  },
  {
    id: 'yds',
    name: 'YDS',
    definition: 'yosemite-decimal-system',
  },
  {
    id: 'yosemite-decimal-system',
    name: 'Yosemite Decimal System',
    definition: 'yosemite-decimal-system',
  },
  // z
  { id: 'z-rig', name: 'Z Rig', definition: 'z-rig' },
  { id: 'zac', name: 'ZAC', definition: 'zac' },
  { id: 'zion-trifecta', name: 'Zion Trifecta', definition: 'zion-trifecta' },
  { id: 'zions', name: 'Zions', definition: 'zions' },
  { id: 'zip', name: 'Zip', definition: 'zip' },
  { id: 'znp', name: 'ZNP', definition: 'znp' },
];

export default terms;
