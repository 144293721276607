// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'hand-line',
    tooltip:
      'Fixed rope or webbing that is used as a secure hand hold when down climbing',
    jsx: (
      <>
        Fixed <Link to={routes.glossary_rope}>rope</Link> or{' '}
        <Link to={routes.glossary_webbing}>webbing</Link> that is used as a
        secure hand hold when{' '}
        <Link to={routes.glossary_down_climb}>down climbing</Link>. Hand lines
        can be fixed to an anchor and left in the canyon or quickly set up with
        a <Link to={routes.glossary_human_anchor}>human anchor</Link> when
        needed.
      </>
    ),
    terms: ['hand-line'],
  },
  {
    id: 'hands-free-backup',
    tooltip:
      'A friction hitch to stop a rappeller from descending when he/she releases it. This is a self belay if the rappeller is operating the hands-free backup.',
    jsx: (
      <>
        A <Link to={routes.glossary_friction_hitch}>friction hitch</Link> to
        stop a rappeller from descending when he/she releases it. This is a{' '}
        <Link to={routes.glossary_self_belay}>self belay</Link> if it is the
        rappeller who is operating the hands-free backup.
      </>
    ),
    terms: ['hands-free-backup'],
  },
  {
    id: 'hanger',
    tooltip:
      'Part of a bolt anchor that connects the bolt with a chain, quick link, or some other rigging',
    jsx: (
      <>
        Part of a <Link to={routes.glossary_bolt}>bolt</Link>{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> that connects the bolt
        to a chain, <Link to={routes.glossary_quick_link}>quick link</Link>, or
        some other <Link to={routes.glossary_rigging}>rigging</Link>.
      </>
    ),
    terms: ['hanger'],
  },
  {
    id: 'happy-hooker',
    tooltip: 'Imlay brand of a cheater stick',
    jsx: (
      <>
        <Link to="http://www.canyoneeringusa.com/">Imlay Canyon Gear</Link>{' '}
        brand of a <Link to={routes.glossary_cheater_stick}>cheater stick</Link>
        . No longer in production because of its limited usefulness.
      </>
    ),
    terms: ['happy-hooker'],
  },
  {
    id: 'hard-lock-off',
    tooltip:
      'Secure way of stopping oneself and have both hands free while on rappel',
    jsx: (
      <>
        Secure way of stopping oneself and have both hands free while on rappel.
      </>
    ),
    terms: ['hard-lock-off'],
  },
  {
    id: 'hard-start',
    tooltip:
      'Start a rappel by leaning into the anchor which can increase load on the anchor',
    jsx: (
      <>
        Used in reference to and the antithesis of{' '}
        <Link to={routes.glossary_soft_start}>soft start</Link>. Start a{' '}
        <Link to={routes.glossary_rappel}>rappel</Link> by leaning into the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> which increases load on
        the anchor.
      </>
    ),
    terms: ['hard-start'],
  },
  {
    id: 'harness',
    tooltip:
      'Nylon webbing that wraps around the waist and legs and secures the canyoneer to the rope or anchor',
    jsx: (
      <>
        Nylon <Link to={routes.glossary_webbing}>webbing</Link> that wraps
        around the waist and legs and secures the canyoneer to the{' '}
        <Link to={routes.glossary_rope}>rope</Link> or{' '}
        <Link to={routes.glossary_anchor}>anchor</Link>.
      </>
    ),
    terms: ['harness'],
  },
  {
    id: 'harness-interruptus',
    tooltip:
      'Distracting someone or being distracted while putting on a harness - adding to the possibility that a life-critical step is missed or done incorrectly',
    jsx: (
      <>
        Distracting someone or being distracted while putting on a{' '}
        <Link to={routes.glossary_harness}>harness</Link> - adding to the
        possibility that a life-critical step is missed or done incorrectly.
        Term possibly taken from{' '}
        <Link to="https://en.wikipedia.org/wiki/Coitus_interruptus">
          this possibly NSFW
        </Link>{' '}
        term.
      </>
    ),
    terms: ['harness-interruptus'],
  },
  {
    id: 'haul-system',
    tooltip:
      'A self-rescue technique that uses the rappel rigging to haul a load up to the top of a rappel',
    jsx: (
      <>
        A <Link to={routes.glossary_self_rescue}>self-rescue</Link> technique
        that uses the rappel <Link to={routes.glossary_rigging}>rigging</Link>{' '}
        to haul a load up to the top of a rappel with a{' '}
        <Link to={routes.glossary_progress_capture_device}>
          progress capture device
        </Link>{' '}
        and{' '}
        <Link to={routes.glossary_traveling_rope_grab}>
          traveling rope grab
        </Link>
        . Typically this would be done when the fastest way to exit a canyon is
        by going <Link to={routes.glossary_up_canyon}>up canyon</Link> rather
        than <Link to={routes.glossary_down_canyon}>down canyon</Link>.
      </>
    ),
    terms: ['haul-system'],
  },
  {
    id: 'hcv',
    tooltip: 'High Clearance Vehicle',
    jsx: (
      <>
        <strong>H</strong>igh <strong>C</strong>learance <strong>V</strong>
        ehicle. Necessary to access some remote canyons. Opposite of{' '}
        <Link to={routes.glossary_lcv}>LCV</Link>.
      </>
    ),
    terms: ['hcv'],
  },
  {
    id: 'helmet',
    tooltip:
      "Protects the skull from injury caused by falling debris, impact forces during a fall, and from bumping one's head on the canyon walls",
    jsx: (
      <>
        Protects the skull from injury caused by falling debris, impact forces
        during a fall, and from bumping one's head on the canyon walls.
      </>
    ),
    terms: ['helmet'],
  },
  {
    id: 'helmet-police',
    tooltip:
      'Individuals who are publicly outspoken about wearing helmets in all canyoneering pictures',
    jsx: (
      <>
        Derogatory term for individuals who are publicly outspoken about wearing{' '}
        <Link to={routes.glossary_helmet}>helmets</Link> in all canyoneering
        pictures. If someone posts a picture where someone isn't wearing a
        helmet when they should, expect a snarky remark from the helmet police.
        <br />
        The best way to avoid the helmet police is to always wear your helmet
        any time you should be wearing your helmet.
      </>
    ),
    terms: ['helmet-police'],
  },
  {
    id: 'helmet-stand',
    tooltip:
      'Stack a bunch of helmets below a canyoneer stuck in a skinny section of canyon. The canyoneer stands on the helmets to help get themselves unstuck.',
    jsx: (
      <>
        Stack a bunch of <Link to={routes.glossary_helmet}>helmets</Link> below
        a canyoneer stuck in a skinny section of canyon. The canyoneer stands on
        the helmets to help get themselves unstuck.
      </>
    ),
    terms: ['helmet-stand'],
  },
  {
    id: 'high-stemming',
    tooltip:
      'Chimneying, stemming, or bridging high off the deck because it the most practical way to move through a section of canyon since lower it is too skinny to fit',
    jsx: (
      <>
        <Link to={routes.glossary_chimneying}>Chimneying</Link>,{' '}
        <Link to={routes.glossary_stemming}>stemming</Link>, or{' '}
        <Link to={routes.glossary_bridging}>bridging</Link> high off the deck
        because it the most practical way to move through a section of canyon
        since lower it is too skinny to fit.
      </>
    ),
    terms: ['high-stemming'],
  },
  {
    id: 'hitch',
    tooltip: 'A knot that attaches the rope to some object',
    jsx: (
      <>
        A <Link to={routes.glossary_knot}>knot</Link> that attaches the rope to
        some object. Types of hitches are{' '}
        <Link to={routes.glossary_block_hitch}>block hitches</Link>,{' '}
        <Link to={routes.glossary_friction_hitch}>friction hitches</Link>, and{' '}
        <Link to={routes.glossary_load_releasing_hitch}>
          load releasing hitches
        </Link>
        .
      </>
    ),
    terms: ['hitch'],
  },
  {
    id: 'hitrr',
    tooltip: 'Hole-in-the-Rock Road',
    jsx: (
      <>
        <Link to="https://en.wikipedia.org/wiki/Hole_in_the_Rock_Trail">
          Hole-in-the-Rock Road
        </Link>{' '}
        - canyoneering region in Escalante, Utah.
      </>
    ),
    terms: ['hitrr'],
  },
  {
    id: 'hms-carabiner',
    tooltip: 'Pear-shaped carabiner that works well with the Munter hitch',
    jsx: (
      <>
        Pear-shaped <Link to={routes.glossary_carabiner}>carabiner</Link> that
        works well with the{' '}
        <Link to={routes.glossary_munter_hitch}>Munter hitch</Link>. HMS is
        short for <strong>H</strong>alb<strong>m</strong>astwurf
        <strong>s</strong>icherung which means "Munter hitch belay" (literally
        "half mast backup") in German.
      </>
    ),
    terms: ['hms-carabiner'],
    article: 'carabiner',
  },
  {
    id: 'hoodoo',
    tooltip:
      'On Rope Canyoneering figure 8 style canyoneering specific rappel device',
    jsx: (
      <>
        <Link to={routes.glossary_on_rope_canyoneering}>
          On Rope Canyoneering
        </Link>{' '}
        <Link to={routes.glossary_figure_8_rappel_device}>figure 8</Link> style
        canyoneering specific{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link>.
      </>
    ),
    terms: ['hoodoo'],
    article: {
      title: 'HOODOO website',
      route: 'http://www.onropecanyoneering.com/canyoneering-gear/hoodoo-sl',
    },
  },
  {
    id: 'hoodoo-rock-formation',
    tooltip: 'An aesthetically pleasing column of rock',
    jsx: (
      <>
        An aesthetically pleasing, column-shaped{' '}
        <Link to={routes.glossary_rock_formation}>rock formation</Link>.
      </>
    ),
    terms: ['hoodoo-rock-formation'],
  },
  {
    id: 'hook',
    tooltip:
      'Used for pothole escapes in a few canyons with drilled holes and as rigging for ghosting',
    jsx: (
      <>
        Used as rigging for <Link to={routes.glossary_ghost}>ghosting</Link>.
        The placements can <Link to={routes.glossary_blowout}>blow out</Link>{' '}
        pretty easily in <Link to={routes.glossary_sandstone}>sandstone</Link>{' '}
        so hooks aren't that popular.
        <br />
        Also used when <Link to={routes.glossary_hooking}>hooking</Link> out of
        a pothole.
      </>
    ),
    terms: ['hook'],
  },
  {
    id: 'hooking',
    tooltip:
      'Pothole escape technique where a canyoneer places a hook in a drilled hole to climb out of the pothole',
    jsx: (
      <>
        <Link to={routes.glossary_pothole_escape_technique}>
          Pothole escape technique
        </Link>{' '}
        where a canyoneer places a hook in a drilled hole to climb out of the{' '}
        <Link to={routes.glossary_keeper_pothole}>pothole</Link>.
      </>
    ),
    terms: ['hooking'],
  },
  {
    id: 'human-anchor',
    tooltip:
      'A canyoneer or canyoneers that secure themselves at the top of a rappel to be used as a transient anchor for rappelling',
    jsx: (
      <>
        A canyoneer or canyoneers that secure themselves at the top of a rappel
        to be used as a{' '}
        <Link to={routes.glossary_transient_anchor}>transient anchor</Link> for
        rappelling.
      </>
    ),
    terms: ['human-anchor', 'meat-anchor'],
  },
  {
    id: 'human-pyramid',
    tooltip:
      "One canyoneer standing on one or more canyoneer's shoulders who are in turn standing on the shoulders of others. Most often used to efficiently descend tall drops.",
    jsx: (
      <>
        One canyoneer standing on one or more canyoneer's shoulders who are in
        turn standing on the shoulders of others. Can be used as a{' '}
        <Link to={routes.glossary_pothole_escape_technique}>
          pothole escape technique
        </Link>{' '}
        though it is more complicated than other techniques. More often it is
        used to{' '}
        <Link to={routes.glossary_partner_capture}>partner capture</Link> or to
        escape a canyon in the event of a{' '}
        <Link to={routes.glossary_flash_flood}>flash flood</Link>.
      </>
    ),
    terms: ['human-pyramid'],
  },
];

export default definitions;
