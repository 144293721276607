// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'r-risk-rating',
    tooltip: 'A canyon where failure will likely result in serious injury',
    jsx: (
      <>
        A canyon with <Link to={routes.glossary_exposure}>exposure</Link> where
        failure will likely result in serious injury. See definition for{' '}
        <Link to={routes.glossary_r_section}>R section</Link>.
      </>
    ),
    terms: ['r-risk-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'r-section',
    tooltip:
      'A section within the canyon where failure will likely result in serious injury',
    jsx: (
      <>
        A section within the canyon where failure will likely result in serious
        injury. Canyoneers cannot rely on the skills of their partners to get
        them through these sections of canyon.
        <br />
        Examples of these risks are{' '}
        <Link to={routes.glossary_high_stemming}>high stemming</Link>, difficult{' '}
        <Link to={routes.glossary_down_climb}>down climbs</Link>, very skinny
        sections, and required off width climbing.
      </>
    ),
    terms: ['r-section'],
  },
  {
    id: 'ralston-rock',
    tooltip:
      'A chockstone that rolls or moves when weighted. Named after Aron Ralston.',
    jsx: (
      <>
        A <Link to={routes.glossary_chockstone}>chockstone</Link> that rolls or
        moves when weighted. Named after{' '}
        <Link to="https://en.wikipedia.org/wiki/Aron_Ralston">
          Aron Ralston
        </Link>
        .
      </>
    ),
    terms: ['ralston-rock', 'ralstone'],
  },
  {
    id: 'rapide',
    tooltip: 'A metal ring that connects the rope to the webbing',
    jsx: (
      <>
        A metal ring that connects the rope to the webbing. It has a screw-lock
        like a carabiner but does not have a{' '}
        <Link to={routes.glossary_gate}>gate</Link>. I'd trust my life to a
        5/16" galvanized steel,{' '}
        <Link to={routes.glossary_ce_marking}>CE marked</Link> rapide. Also
        called rapid link or quick link, though I hear rapide most often.
        Maillon rapide is French for "quick link".
      </>
    ),
    terms: ['quick-link', 'rapid-link', 'rapide'],
  },
  {
    id: 'rappel',
    tooltip:
      'Descend a rock face or other near-vertical surface by using a rope',
    jsx: (
      <>
        Descend a cliff face by using a{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> and{' '}
        <Link to={routes.glossary_rope}>rope</Link> to control the speed.
      </>
    ),
    terms: ['abseil', 'rap', 'rappel'],
  },
  {
    id: 'rappel-deviation',
    tooltip:
      "An anchor mid-rappel used to change the rope's direction of pull. The rope can freely slide  up and down the anchor.",
    jsx: (
      <>
        An <Link to={routes.glossary_anchor}>anchor</Link> mid-
        <Link to={routes.glossary_rappel}>rappel</Link> used to change the{' '}
        <Link to={routes.glossary_rope}>rope</Link>'s direction of pull. The
        rope can freely slide up and down the anchor like unlike a{' '}
        <Link to={routes.glossary_rebelay}>rebelay</Link>.
      </>
    ),
    terms: ['deviation', 'rappel-deviation', 'rappel-redirect'],
  },
  {
    id: 'rappel-device',
    tooltip:
      'A device used for rappelling that clips to a canyoneer and the rappel rope, and allows the rappeller to control the speed of their descent',
    jsx: (
      <>
        A device used for <Link to={routes.glossary_rappel}>rappelling</Link>{' '}
        that clips to a canyoneer and the rappel rope, and allows the rappeller
        to control the speed of their descent.
        <br />
        Many canyoneering specific rappel devices have horns that allow the
        rappeller to add friction mid-rappel.
      </>
    ),
    terms: ['rappel-device'],
  },
  {
    id: 'rappel-line',
    tooltip: 'The rope from which a rappeller rappels',
    jsx: (
      <>
        The <Link to={routes.glossary_rope}>rope</Link> from which a{' '}
        <Link to={routes.glossary_rappeller}>rappeller</Link> rappels.
      </>
    ),
    terms: ['rappel-line'],
  },
  {
    id: 'rappel-ring',
    tooltip: 'Metal ring that is like a rapide without the screw opening',
    jsx: (
      <>
        Metal ring that is like a{' '}
        <Link to={routes.glossary_rapide}>rapide</Link> without the screw
        opening.
      </>
    ),
    terms: ['descent-ring', 'rappel-ring'],
  },
  {
    id: 'rappeller',
    tooltip:
      'One who descends a cliff face by using a rappel device and rope to control their speed',
    jsx: (
      <>
        One who descends a cliff face by using a{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> and{' '}
        <Link to={routes.glossary_rope}>rope</Link> to control their speed.
      </>
    ),
    terms: ['rappeller'],
  },
  {
    id: 'rappelling-harness',
    tooltip: 'Harness designed for rappelling and has a horizontal belay loop',
    jsx: (
      <>
        <Link to={routes.glossary_harness}>Harness</Link> designed for{' '}
        <Link to={routes.glossary_rappel}>rappelling</Link> and has a horizontal{' '}
        <Link to={routes.glossary_belay_loop}>belay loop</Link>.
      </>
    ),
    terms: ['rappelling-harness'],
  },
  {
    id: 'rebelay',
    tooltip:
      "An anchor mid-rappel used to change the rope's direction of pull. The rope is fixed to the anchor.",
    jsx: (
      <>
        An <Link to={routes.glossary_anchor}>anchor</Link> mid-
        <Link to={routes.glossary_rappel}>rappel</Link> used to change the{' '}
        <Link to={routes.glossary_rope}>rope</Link>'s direction of pull. The
        rope is fixed to the anchor unlike a{' '}
        <Link to={routes.glossary_deviation}>deviation</Link>.
      </>
    ),
    terms: ['rebelay'],
  },
  {
    id: 'recirculating-current',
    tooltip:
      'Swift water hazard downstream of an obstacle where the water current spirals',
    jsx: (
      <>
        <Link to={routes.glossary_swift_water}>Swift water</Link>{' '}
        <Link to={routes.glossary_water_hazard}>hazard</Link> downstream of an
        obstacle where the water current spirals.
      </>
    ),
    terms: ['recirculating-current'],
  },
  {
    id: 'redirect',
    tooltip:
      'Puts the haul line through a last pulley so the haulers pull downward on the anchor instead of lifting towards the anchor',
    jsx: (
      <>
        In{' '}
        <Link to={routes.glossary_mechanical_advantage}>
          mechanical advantage
        </Link>
        , a redirect puts the{' '}
        <Link to={routes.glossary_haul_system}>haul line</Link> through a last
        pulley so the haulers pull downward on the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> instead of lifting
        towards the anchor.
        <br />A redirect provides no mechanical advantage and adds friction to
        the system but can be a good choice under some circumstances.
      </>
    ),
    terms: ['redirect'],
  },
  {
    id: 'redundant',
    tooltip:
      'One of the qualities of EARNEST rigging. If any anchor or part of the rigging fails, the load will still be secured by another anchor/rigging.',
    jsx: (
      <>
        One of the qualities of EARNEST rigging. If any anchor or part of the
        rigging fails, the load will still be secured by another anchor/rigging.
      </>
    ),
    terms: ['redundant'],
    article: 'earnest',
  },
  {
    id: 'redundant-wrap',
    tooltip:
      'Natural anchor rigging that uses a basket hitch with an overhand knot tied as the master point',
    jsx: (
      <>
        <Link to={routes.glossary_natural_anchor}>Natural anchor</Link>{' '}
        <Link to={routes.glossary_rigging}>rigging</Link> that uses a{' '}
        <Link to={routes.glossary_basket_hitch}>basket hitch</Link> with an{' '}
        <Link to="overhand-knot">overhand knot</Link> tied as the{' '}
        <Link to={routes.glossary_master_point}>master point</Link>.
      </>
    ),
    terms: ['redundant-wrap'],
  },
  {
    id: 'releasable-rigging-system',
    tooltip:
      'A rigging system that can release the weighted rope to lower a stuck rappeller',
    jsx: (
      <>
        A <Link to={routes.glossary_rigging}>rigging</Link> system that can
        release the weighted rope to lower a stuck{' '}
        <Link to={routes.glossary_rappeller}>rappeller</Link>.
        <br />
        Consists of three parts: 1) friction mechanism to allow controlled
        lowering, 2) tie-off that is releasable under tension, and 3) a{' '}
        <Link to={routes.glossary_safety_rigging}>safety</Link> to prevent the
        tie-off from releasing unintentionally.
        <br />
        There are two types:{' '}
        <Link to={routes.glossary_contingency_block}>
          contingency block
        </Link>{' '}
        and{' '}
        <Link to={routes.glossary_contingency_redirect}>
          contingency redirect
        </Link>
        .
      </>
    ),
    terms: [
      'contingency-rigging',
      'contingency-system',
      'releasable-rigging-system',
    ],
  },
  {
    id: 'releasable-twin-rope-system',
    tooltip: 'A releasable rigging system that is also a twin rope system',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_releasable_rigging_system}>
          releasable rigging system
        </Link>{' '}
        that is also a{' '}
        <Link to={routes.glossary_twin_rope_system}>twin rope system</Link>.
        Example is the <Link to={routes.glossary_jester}>Jester</Link>.
      </>
    ),
    terms: ['releasable-twin-rope-system'],
  },
  {
    id: 'release-cord',
    tooltip:
      'Rope, cordelette, or Dyneema cord used to release and retrieve the rappel line and retrievable rigging. Similar to pull cord.',
    jsx: (
      <>
        <Link to={routes.glossary_rope}>Rope</Link>,{' '}
        <Link to={routes.glossary_cordelette}>cordelette</Link>, or{' '}
        <Link to={routes.glossary_dyneema}>Dyneema</Link> cord used to release
        and retrieve the{' '}
        <Link to={routes.glossary_rappel_line}>rappel line</Link> and{' '}
        <Link to={routes.glossary_retrievable_rigging_system}>
          retrievable rigging
        </Link>
        . Similar to <Link to={routes.glossary_pull_cord}>pull cord</Link>.
      </>
    ),
    terms: ['release-cord'],
  },
  {
    id: 'rendezvous',
    tooltip:
      'Organized meeting of a group of canyoneers at a specific place and time',
    jsx: (
      <>
        Organized meeting of a group of canyoneers at a specific place and time.
        Great opportunity to meet canyoneers and do easy to moderately difficult
        canyons.
      </>
    ),
    terms: ['rendezvous'],
  },
  {
    id: 'rescue-rope',
    tooltip:
      'A rope that is kept at the top of a rappel as a contingency in case the rappel rope is no longer available',
    jsx: (
      <>
        A <Link to={routes.glossary_rope}>rope</Link> that is kept at the top of
        a <Link to={routes.glossary_rappel}>rappel</Link> as a contingency in
        case the rappel rope is no longer available.
      </>
    ),
    terms: ['rescue-rope'],
  },
  {
    id: 'restop',
    tooltip: 'Brand name of a wag bag',
    jsx: (
      <>
        Brand name of a <Link to={routes.glossary_wag_bag}>wag bag</Link>.
      </>
    ),
    terms: ['restop'],
  },
  {
    id: 'retrievable-rigging-system',
    tooltip: 'Rigging that can be retrieved after everyone is down a rappel',
    jsx: (
      <>
        <Link to={routes.glossary_rigging}>Rigging</Link> that can be retrieved
        after everyone is down a rappel. Used in{' '}
        <Link to={routes.glossary_ghost}>ghosting</Link>.<br />
        Examples are the the{' '}
        <Link to={routes.glossary_2_ring_retrievable_rigging}>
          2-ring retrievable rigging
        </Link>
        , rope-only systems such as the{' '}
        <Link to={routes.glossary_macrame}>Macrame</Link> and{' '}
        <Link to={routes.glossary_cem}>CEM</Link>, a{' '}
        <Link to={routes.glossary_toggle}>toggle</Link>,{' '}
        <Link to={routes.glossary_sand_trap}>sand trap</Link>, and{' '}
        <Link to={routes.glossary_water_anchor}>water anchor</Link>.
      </>
    ),
    terms: ['retrievable-rigging-system'],
  },
  {
    id: 'ridge',
    tooltip: 'Line of high-points connecting two peaks',
    jsx: (
      <>
        Line of high-points connecting two{' '}
        <Link to={routes.glossary_peak}>peaks</Link>.
      </>
    ),
    terms: ['ridge'],
  },
  {
    id: 'rigging',
    tooltip: 'Gear that connects the anchor to the rope',
    jsx: (
      <>
        A broad term for the gear that connects the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> to the{' '}
        <Link to={routes.glossary_rope}>rope</Link>. In{' '}
        <Link to={routes.glossary_trade_canyon}>trade canyons</Link> it is
        commonly <Link to={routes.glossary_webbing}>webbing</Link> and a{' '}
        <Link to={routes.glossary_rapide}>rapide</Link> that is left with the
        rest of the anchor as well as any{' '}
        <Link to={routes.glossary_block}>block</Link> that is attached to the
        rope.
      </>
    ),
    terms: ['rigging'],
  },
  {
    id: 'right-down-canyon',
    tooltip: 'On the right side when looking down canyon',
    jsx: (
      <>
        On the right side when looking{' '}
        <Link to={routes.glossary_down_canyon}>down canyon</Link>.
      </>
    ),
    terms: ['canyon-right', 'rdc', 'right-down-canyon'],
  },
  {
    id: 'right-up-canyon',
    tooltip: 'On the right side when looking up canyon',
    jsx: (
      <>
        On the right side when looking{' '}
        <Link to={routes.glossary_up_canyon}>up canyon</Link>.
      </>
    ),
    terms: ['right-up-canyon', 'ruc'],
  },
  {
    id: 'ring-bend',
    tooltip: 'Rethreaded overhand bend that joins two pieces of webbing',
    jsx: (
      <>
        Rethreaded <Link to={routes.glossary_overhand_knot}>overhand</Link>{' '}
        <Link to={routes.glossary_bend}>bend</Link> that joins two pieces of{' '}
        <Link to={routes.glossary_webbing}>webbing</Link>.
      </>
    ),
    terms: ['ring-bend'],
  },
  {
    id: 'risk-rating',
    tooltip: 'The consequence of making a mistake in the canyon',
    jsx: (
      <>
        The consequence of making a mistake in the canyon. This rating loosely
        follows the MPAA ratings where G is easy and X can be deadly. Beta
        providers rarely use anything but{' '}
        <Link to={routes.glossary_r_risk_rating}>R</Link> and{' '}
        <Link to={routes.glossary_x_risk_rating}>X</Link>.
      </>
    ),
    terms: ['risk-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'road-trip-ryan',
    tooltip: 'Beta provider website',
    jsx: (
      <>
        <Link to={routes.glossary_beta_provider}>Beta provider</Link>{' '}
        <Link to="https://www.roadtripryan.com/go/">website</Link>.
      </>
    ),
    terms: ['road-trip-ryan'],
  },
  {
    id: 'rock-formation',
    tooltip: 'Aesthetically pleasing rocks',
    jsx: (
      <>
        Aesthetically pleasing rocks. Common on the Colorado Plateau. Types I
        can think of offhand are{' '}
        <Link to={routes.glossary_hoodoo_rock_formation}>hoodoos</Link>,{' '}
        <Link to={routes.glossary_solution_pocket}>solution pockets</Link>, and{' '}
        <Link to={routes.glossary_arch}>arches</Link>.
      </>
    ),
    terms: ['rock-formation'],
  },
  {
    id: 'rope',
    tooltip: 'Static kernmantle rope',
    jsx: (
      <>
        A group of yarns, plies, fibers or strands that are twisted or braided
        together into a larger and stronger form. (
        <Link to="https://en.wikipedia.org/wiki/Rope">
          Definition from Wikipedia
        </Link>
        ). All canyoneering ropes are{' '}
        <Link to={routes.glossary_kernmantle_rope}>kernmantle rope</Link>.
      </>
    ),
    terms: ['rope'],
    article: 'rope',
  },
  {
    id: 'rope-bag',
    tooltip:
      'A bag designed to hold your rope. Lots of advantages over coiling the rope.',
    jsx: (
      <>
        A bag designed to hold your rope. Lots of advantages over coiling the
        rope.
      </>
    ),
    terms: ['rope-bag'],
  },
  {
    id: 'rope-entanglement',
    tooltip:
      'Water hazard where a canyoneer gets tangled in excess rope in the water and is unable to free themselves',
    jsx: (
      <>
        <Link to={routes.glossary_water_hazard}>Water hazard</Link> where a
        canyoneer gets tangled in excess{' '}
        <Link to={routes.glossary_rope}>rope</Link> in the water and is unable
        to free themselves.
      </>
    ),
    terms: ['rope-entanglement'],
  },
  {
    id: 'rope-grab',
    tooltip:
      'Something that grabs on to the rope in one direction and slides along the rope in the other direction',
    jsx: (
      <>
        Something that grabs on to the rope in one direction and slides along
        the rope in the other direction. This includes{' '}
        <Link to={routes.glossary_ascender}>ascenders</Link> and{' '}
        <Link to={routes.glossary_friction_hitch}>friction hitches</Link>.
      </>
    ),
    terms: ['rope-grab'],
  },
  {
    id: 'rope-groove',
    tooltip:
      'A groove in the soft sandstone cliff below an anchor. It is formed when canyoneers pull their rope down after a rappel. The weight of the rappel rope going up and through the rapide and the force applied to weighted pull rope grinds into the rock.',
    jsx: (
      <>
        A groove in the soft{' '}
        <Link to={routes.glossary_sandstone}>sandstone</Link> cliff below an{' '}
        <Link to={routes.glossary_anchor}>anchor</Link>. It is formed when
        canyoneers <Link to={routes.glossary_pull}>pull</Link> their rope down
        after a rappel. The weight of the rappel rope going up and through the{' '}
        <Link to={routes.glossary_rapide}>rapide</Link> and the force applied to
        weighted pull rope grinds into the rock.
      </>
    ),
    terms: ['rope-groove'],
  },
  {
    id: 'rope-management',
    tooltip: 'Keep ropes tidy and organized to increase efficiency',
    jsx: (
      <>
        Keep <Link to={routes.glossary_rope}>ropes</Link> tidy and organized to
        increase efficiency.
      </>
    ),
    terms: ['rope-management'],
  },
  {
    id: 'rope-spool',
    tooltip: 'The longest length of rope a manufacturer cuts and sells',
    jsx: (
      <>
        The longest length of <Link to={routes.glossary_rope}>rope</Link> a
        manufacturer cuts and sells. Usually around 600'-650'.
      </>
    ),
    terms: ['rope-spool'],
  },
  {
    id: 'ropewiki',
    tooltip: 'Canyoneering wiki and comprehensive beta database',
    jsx: (
      <>
        Canyoneering wiki and comprehensive{' '}
        <Link to={routes.glossary_beta}>beta</Link> database.
      </>
    ),
    terms: ['ropewiki'],
    article: {
      title: 'Ropewiki website',
      route: 'http://ropewiki.com/Main_Page',
    },
  },
];

export default definitions;
