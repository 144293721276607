// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'saddle',
    tooltip:
      'Low point on a ridge connecting two peaks. Usually the easiest spot to traverse from one drainage to another.',
    jsx: (
      <>
        Low point on a <Link to={routes.glossary_ridge}>ridge</Link> connecting
        two <Link to={routes.glossary_peak}>peaks</Link>. Usually the easiest
        spot to traverse from one{' '}
        <Link to={routes.glossary_drainage}>drainage</Link> to another.
      </>
    ),
    terms: ['saddle'],
  },
  {
    id: 'safety-check',
    tooltip:
      'Check each canyoneer goes through at each rappel to ensure they are wearing necessary gear correctly and rigged correctly',
    jsx: (
      <>
        Check each canyoneer goes through at each{' '}
        <Link to={routes.glossary_rappel}>rappel</Link> to ensure they are
        wearing necessary gear correctly and rigged correctly. One example is{' '}
        <Link to={routes.glossary_check}>CHECK</Link>.
      </>
    ),
    terms: ['buddy-check', 'safety-check'],
    article: 'check',
  },
  {
    id: 'safety-line',
    tooltip:
      'Rope or webbing anchored at both ends to protect a horizontal traverse',
    jsx: (
      <>
        <Link to={routes.glossary_rope}>Rope</Link> or{' '}
        <Link to={routes.glossary_webbing}>webbing</Link> anchored at both ends
        to protect a horizontal{' '}
        <Link to={routes.glossary_traverse}>traverse</Link>.
      </>
    ),
    terms: ['safety-line', 'traverse-line'],
  },
  {
    id: 'safety-rigging',
    tooltip:
      'Something that connects the block to the rigging and prevents the rope from moving if the block fails or the rappeller rappels off the bag end of the rope',
    jsx: (
      <>
        Something that connects the{' '}
        <Link to={routes.glossary_block}>block</Link> to the{' '}
        <Link to={routes.glossary_rigging}>rigging</Link> and prevents the rope
        from moving if the block fails or the rappeller rappels off the{' '}
        <Link to={routes.glossary_bag_end}>bag end</Link> of the rope.
      </>
    ),
    terms: ['safety-rigging'],
  },
  {
    id: 'safety-tether',
    tooltip:
      'A short piece of cord that is attached to a belay loop and clipped into rigging on an exposed anchor',
    jsx: (
      <>
        A short piece of cord that is attached to a{' '}
        <Link to="belay-loop">belay loop</Link> and clipped into{' '}
        <Link to={routes.glossary_rigging}>rigging</Link> on an{' '}
        <Link to={routes.glossary_exposure}>exposed</Link> anchor. It is used
        for many other things as well.
      </>
    ),
    terms: ['bunny-strap', 'cows-tail', 'pas', 'safety-tether'],
  },
  {
    id: 'sand-bag',
    tooltip:
      'A sewn bag designed to hold sand. Its primary function is to be a counterweight when getting out of potholes.',
    jsx: (
      <>
        A sewn bag designed to hold sand. Its primary function is to be a
        counterweight when getting out of potholes. Sand bags were also the
        first <Link to={routes.glossary_sand_trap}>sand trap</Link>.<br />
        Brands names are <Link to={routes.glossary_potshot}>
          PotShot
        </Link> and <Link to={routes.glossary_pot_sticker}>Pot Sticker</Link>.{' '}
        <Link to={routes.glossary_atwood_gear}>Atwood Gear</Link> makes one as
        well but I don't know its name.
      </>
    ),
    terms: ['sand-bag'],
  },
  {
    id: 'sand-trap',
    tooltip:
      'A retrievable rigging system that uses sand as the anchor material. Fill it with sand and rappel off of it. Pull a cord that displaces the sand allowing you to retrieve the rigging.',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_retrievable_rigging_system}>
          retrievable rigging system
        </Link>{' '}
        that uses sand as the{' '}
        <Link to={routes.glossary_transient_anchor}>transient anchor</Link>{' '}
        material. Fill it with sand and rappel off of it. Pull a cord that
        displaces the sand allowing you to retrieve the rigging.
        <br />I know of the <Link to={routes.glossary_sandtrap}>
          SandTrap
        </Link>, <Link to={routes.glossary_maxxipad}>MaxxiPad</Link>,{' '}
        <Link to={routes.glossary_sand_trap_anchor}>Sand Trap Anchor</Link>, and{' '}
        <Link to={routes.glossary_sand_bag}>sand bags</Link>.
      </>
    ),
    terms: ['sand-trap'],
  },
  {
    id: 'sand-trap-anchor',
    tooltip: 'Atwood Gear brand name for the sand trap',
    jsx: (
      <>
        <Link to={routes.glossary_atwood_gear}>Atwood Gear</Link> brand name for
        the <Link to={routes.glossary_sand_trap}>sand trap</Link>.
      </>
    ),
    terms: ['sand-trap-anchor'],
    article: {
      title: 'Sand Trap Anchor website',
      route:
        'https://atwoodgear.com/collections/anchors/products/sand-trap-anchor',
    },
  },
  {
    id: 'sandstone',
    tooltip: 'Sedimentary rock composed of sand-sized particles',
    jsx: (
      <>
        Sedimentary rock composed of sand-sized particles that make up the vast
        majority of{' '}
        <Link to={routes.glossary_colorado_plateau}>Colorado Plateau</Link>{' '}
        canyons.
      </>
    ),
    terms: ['sandstone', 'slickrock'],
  },
  {
    id: 'sandtrap',
    tooltip: 'Imlay Canyon Gear brand name for the sand trap',
    jsx: (
      <>
        <Link to="http://www.store.canyoneeringusa.com/index.php?option=com_rokecwid&Itemid=108&view=ecwid&ecwid_product_id=10914150#ecwid:category=0&mode=product&product=10914150">
          Imlay Canyon Gear
        </Link>{' '}
        brand name for the <Link to={routes.glossary_sand_trap}>sand trap</Link>
        .
      </>
    ),
    terms: ['sandtrap'],
  },
  {
    id: 'scrambling',
    tooltip: "Move up steep terrain using one's hands and feet",
    jsx: <>Move up steep terrain using one's hands and feet.</>,
    terms: ['scrambling'],
  },
  {
    id: 'scree',
    tooltip: 'Loose rocks that make up steep slopes at the base of cliffs',
    jsx: <>Loose rocks that make up steep slopes at the base of cliffs.</>,
    terms: ['scree', 'talus'],
  },
  {
    id: 'scuttlebutt',
    tooltip: 'Imlay Canyon Gear brand name for a butt protector',
    jsx: (
      <>
        <Link to="http://www.store.canyoneeringusa.com/index.php?option=com_rokecwid&Itemid=108&view=ecwid&ecwid_product_id=10914065#ecwid:category=0&mode=product&product=10914065">
          Imlay Canyon Gear
        </Link>{' '}
        brand name for a{' '}
        <Link to={routes.glossary_butt_protector}>butt protector</Link>.
      </>
    ),
    terms: ['scuttlebutt'],
  },
  {
    id: 'search-and-rescue',
    tooltip:
      "Search And Rescue - trained volunteers who's job it is to find missing or injured canyoneers, provide first aid, and bring them to safety",
    jsx: (
      <>
        Trained volunteers who's job it is to find missing or injured
        canyoneers, provide first aid, and bring them to safety. They risk their
        lives to do this.
      </>
    ),
    terms: ['sar', 'search-and-rescue'],
  },
  {
    id: 'self-belay',
    tooltip:
      'A rappeller using a friction hitch to stop him/herself from descending when he/she releases it',
    jsx: (
      <>
        A rappeler using a{' '}
        <Link to={routes.glossary_friction_hitch}>friction hitch</Link> to stop
        him/herself from descending when he/she releases it.
        <br />
        The <Link to={routes.glossary_autoblock}>Autoblock</Link> is the most
        common friction hitch used below the rappel device and the{' '}
        <Link to={routes.glossary_valdotain_tresse}>Valdotain Tresse</Link> is
        the most common friction hitch used above the rappel device.
        <br />
        Lots of things can go wrong when using a self belay. Don't assume that
        it will just work and no more thought is required. Get training and
        practice until you are proficient before using it in a canyon.
      </>
    ),
    terms: ['self-belay'],
  },
  {
    id: 'self-minding',
    tooltip:
      'A progress capture device that does not require a person to manually set the rope grab for it to engage',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_progress_capture_device}>
          progress capture device
        </Link>{' '}
        that does not require a person to manually set the{' '}
        <Link to={routes.glossary_rope_grab}>rope grab</Link> for it to engage.
        Examples are a <Link to={routes.glossary_prusik}>Prusik</Link> and{' '}
        <Link to={routes.glossary_prusik_minding_pulley}>PMP</Link> or an{' '}
        <Link to={routes.glossary_ascender}>ascender</Link> like a{' '}
        <Link to={routes.glossary_micro_traxion}>Micro Traxion</Link>.
      </>
    ),
    terms: ['self-minding'],
  },
  {
    id: 'self-rescue',
    tooltip:
      'Safely escape from a canyon when something has gone wrong and without any outside help',
    jsx: (
      <>
        Safely escape from a canyon when something has gone wrong and without
        any outside help like{' '}
        <Link to={routes.glossary_search_and_rescue}>SAR</Link>.
      </>
    ),
    terms: ['self-rescue'],
  },
  {
    id: 'semi-keeper-pothole',
    tooltip:
      'A pothole that some canyoneers can get out of without using pothole escape techniques',
    jsx: (
      <>
        A pothole that some canyoneers can get out of without using{' '}
        <Link to={routes.glossary_pothole_escape_technique}>
          pothole escape techniques
        </Link>
        . These are much less notorious than{' '}
        <Link to={routes.glossary_keeper_pothole}>keeper potholes</Link>.
      </>
    ),
    terms: ['semi-keeper-pothole'],
  },
  {
    id: 'sequence-rappels',
    tooltip:
      'Efficiently move a large group through a canyon by rigging multiple rappels before finishing previous rappels',
    jsx: (
      <>
        Efficiently move a large group through a canyon by{' '}
        <Link to={routes.glossary_rigging}>rigging</Link> multiple rappels
        before finishing previous rappels. This allows several canyoneers to
        rappel at once. It also requires more familiarity with the canyon and
        sufficient <Link to={routes.glossary_rope}>rope</Link>.
      </>
    ),
    terms: ['sequence-rappels'],
  },
  {
    id: 'sequencing',
    tooltip:
      'Overcome an obstacle using a specific ordering that maximizes efficiency or minimizes risk',
    jsx: (
      <>
        Overcome an obstacle using a specific ordering that maximizes efficiency
        or minimizes risk.
      </>
    ),
    terms: ['sequencing'],
  },
  {
    id: 'serene',
    tooltip:
      'Variant of EARNEST. It stands for Solid Equalized Redundant Efficient No Extension.',
    jsx: (
      <>
        Variant of <Link to={routes.glossary_earnest}>EARNEST</Link>. It stands
        for{' '}
        <Link to={routes.glossary_solid}>
          <strong>S</strong>olid
        </Link>{' '}
        <Link to={routes.glossary_equalized}>
          <strong>E</strong>qualized
        </Link>{' '}
        <Link to={routes.glossary_redundant}>
          <strong>R</strong>edundant
        </Link>{' '}
        <Link to={routes.glossary_timely}>
          <strong>E</strong>fficient
        </Link>{' '}
        <Link to={routes.glossary_no_extension}>
          <strong>N</strong>o <strong>E</strong>xtension
        </Link>{' '}
        and the SA is{' '}
        <Link to={routes.glossary_angle}>
          <strong>S</strong>mall <strong>A</strong>ngle
        </Link>
        .
      </>
    ),
    terms: ['serene', 'srene'],
  },
  {
    id: 'set-rope-length',
    tooltip:
      'Make the end of the rope reach the bottom of the rappel with no extra rope on the ground',
    jsx: (
      <>
        Make the end of the rope reach the bottom of the rappel with no extra
        rope on the ground. There are many benefits to doing this.
      </>
    ),
    terms: ['set-rope-length'],
  },
  {
    id: 'sewn-prusik-loop',
    tooltip: 'A cord that is professionally sewn into a loop',
    jsx: (
      <>
        A cord that is professionally sewn into a loop. Designed to easily tie a
        three wrap <Link to={routes.glossary_prusik}>Prusik</Link>.
      </>
    ),
    terms: ['sewn-prusik-loop'],
  },
  {
    id: 'sheath',
    tooltip: 'The outer part of a kernmantle rope',
    jsx: (
      <>
        The outer part of a{' '}
        <Link to={routes.glossary_kernmantle_rope}>kernmantle rope</Link>. It
        protects the <Link to={routes.glossary_core}>core</Link>.
      </>
    ),
    terms: ['sheath'],
    article: 'rope',
  },
  {
    id: 'sheath-slippage',
    tooltip: 'When the outer sheath slips away from the core of the rope',
    jsx: (
      <>
        When the outer <Link to={routes.glossary_sheath}>sheath</Link> slips
        away from the <Link to={routes.glossary_core}>core</Link> of the rope.
        This is common in ropes where the{' '}
        <Link to="http://ropewiki.com/Rope_comparison#Materials">
          core and sheath materials
        </Link>{' '}
        do not bind well.
      </>
    ),
    terms: ['sheath-slippage'],
  },
  {
    id: 'short-rappel',
    tooltip:
      'Rappel that is less than or equal to half the length of the rope being used',
    jsx: (
      <>
        <Link to={routes.glossary_rappel}>Rappel</Link> that is less than or
        equal to half the length of the{' '}
        <Link to={routes.glossary_rope}>rope</Link> being used.
      </>
    ),
    terms: ['short-rappel'],
  },
  {
    id: 'short-rope',
    tooltip: 'A rope at the end of a spool and therefore a non-standard length',
    jsx: (
      <>
        A <Link to={routes.glossary_rope}>rope</Link> that, when purchased, is
        at the end of a <Link to={routes.glossary_rope_spool}>spool</Link> and
        is therefore a non-standard length. This rope is usually less expensive
        per foot than a standard length.
      </>
    ),
    terms: ['short-rope'],
  },
  {
    id: 'shorty-wetsuit',
    tooltip:
      'Wetsuit that covers the body down to the thighs and short sleeved',
    jsx: (
      <>
        <Link to={routes.glossary_wetsuit}>Wetsuit</Link> that covers the body
        down to the thighs and short sleeved.
      </>
    ),
    terms: ['shorty-wetsuit'],
  },
  {
    id: 'shuffle-butt',
    tooltip: 'Imlay Canyon Gear butt protector',
    jsx: (
      <>
        <Link to="http://www.store.canyoneeringusa.com/component/rokecwid/?option=com_rokecwid&Itemid=&view=ecwid&ecwid_product_id=59416383#!/Imlay-Shuffle-Butt/p/59416383">
          Imlay Canyon Gear
        </Link>{' '}
        <Link to={routes.glossary_butt_protector}>butt protector</Link>. Lighter
        but less durable version of the{' '}
        <Link to={routes.glossary_scuttlebutt}>ScuttleButt</Link>.
      </>
    ),
    terms: ['shuffle-butt'],
  },
  {
    id: 'silo',
    tooltip:
      'A wide obstacle that is difficult to traverse in high stemming canyons',
    jsx: (
      <>
        A wide obstacle that is difficult to traverse in{' '}
        <Link to={routes.glossary_high_stemming}>high stemming</Link> canyons.
      </>
    ),
    terms: ['silo'],
  },
  {
    id: 'simultaneous-rappel',
    tooltip:
      'Two rappellers going off opposite ends of the same rope using each other as counterweight',
    jsx: (
      <>
        Two rappellers going off opposite ends of the same rope using each other
        as counterweight.
      </>
    ),
    terms: ['simul-rap', 'simultaneous-rappel'],
  },
  {
    id: 'single-rope-technique',
    tooltip: 'Rappelling on one rope',
    jsx: (
      <>
        Rappelling on one rope as opposed to{' '}
        <Link to={routes.glossary_double_rope_technique}>DRT</Link>. There are
        advantages and disadvantages to each technique.
      </>
    ),
    terms: ['single-rope-technique', 'single-strand', 'srt'],
  },
  {
    id: 'siphon',
    tooltip:
      'Swift water hazard where there is empty space between two obstacles that creates unpredictable and dangerous flow',
    jsx: (
      <>
        <Link to={routes.glossary_swift_water}>Swift water</Link>{' '}
        <Link to={routes.glossary_water_hazard}>hazard</Link> where there is
        empty space between two rocks that creates unpredictable and dangerous
        flow.
      </>
    ),
    terms: ['siphon'],
  },
  {
    id: 'sit-start',
    tooltip:
      "Start a rappel while sitting. This is done when the anchor is low to the ground and it isn't practical or safe to start the rappel standing.",
    jsx: (
      <>
        Start a rappel while sitting. This is done when the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> is low to the ground and
        it isn't practical or safe to start the rappel standing.
      </>
    ),
    terms: ['sit-start'],
  },
  {
    id: 'sitla',
    tooltip: 'School and Institutional Trust Land Administration',
    jsx: (
      <>
        School and Institutional Trust Land Administration - Utah government
        agency that owns property where many canyons are located. If you look at
        property ownership maps SITLA owns a bunch of random squares throughout
        Utah.
      </>
    ),
    terms: ['sitla'],
    article: {
      title: 'School and Institutional Trust Land Administration',
      route: 'https://trustlands.utah.gov/',
    },
  },
  {
    id: 'skinny-end',
    tooltip:
      'The end of a carabiner that attaches to a single point such as the anchor or a rappeller',
    jsx: (
      <>
        The end of a <Link to={routes.glossary_carabiner}>carabiner</Link> that
        attaches to a single point such as the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> or a{' '}
        <Link to={routes.glossary_rappeller}>rappeller</Link>. The opposite side
        is the{' '}
        <Link to={routes.glossary_working_end_carabiner}>working end</Link>.
      </>
    ),
    terms: ['runner-end', 'skinny-end'],
    article: 'carabiner',
  },
  {
    id: 'skinny-rope',
    tooltip: 'Refers to rope that has a small diameter',
    jsx: (
      <>
        Refers to <Link to={routes.glossary_rope}>rope</Link> that has a small
        diameter. Most of the time it refers to rope with a diameter of 8mm but
        I've also heard it used on any rope that's less than 9mm.
      </>
    ),
    terms: ['skinny-rope'],
    article: 'rope',
  },
  {
    id: 'sling',
    tooltip:
      'A weight-bearing closed loop usually made of Nylon or Dyneema. Standard lengths are 12, 24, and 48 inches. Used for a variety of canyoneering applications.',
    jsx: (
      <>
        A weight-bearing closed loop usually made of{' '}
        <Link to={routes.glossary_nylon}>Nylon</Link> or{' '}
        <Link to={routes.glossary_dyneema}>Dyneema</Link>. Standard lengths are
        12, 24, and 48 inches. Used for a variety of canyoneering applications.
      </>
    ),
    terms: ['runner', 'sling'],
  },
  {
    id: 'slog',
    tooltip:
      'Long obnoxious hike through difficult terrain such as sand or mud',
    jsx: (
      <>Long obnoxious hike through difficult terrain such as sand or mud.</>
    ),
    terms: ['slog'],
  },
  {
    id: 'slot-canyon',
    tooltip: 'A canyon where the walls are very tall and very close together',
    jsx: (
      <>
        A <Link to={routes.glossary_canyon}>canyon</Link> where the walls are
        very tall and very close together.
      </>
    ),
    terms: ['slot-canyon'],
  },
  {
    id: 'slot-designation',
    tooltip:
      'The canyon gets skinny enough that some people may not be able to fit through',
    jsx: (
      <>
        The canyon gets skinny enough that some people may not be able to fit
        through. Beta providers don’t commonly use this. The letter{' '}
        <strong>S</strong> is appended to the canyon rating.
      </>
    ),
    terms: ['slot-designation'],
    article: 'canyon-rating-system',
  },
  {
    id: 'slow-rope',
    tooltip: 'Misnomer for a fat rope',
    jsx: (
      <>
        Misnomer for a <Link to={routes.glossary_fat_rope}>fat rope</Link>. Slow
        implies that with the same friction setting on a{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link>, there
        will be more friction overall.
      </>
    ),
    terms: ['slow-rope'],
    article: 'rope',
  },
  {
    id: 'smear',
    tooltip:
      'Maximize contact with the rock to minimize force on the rappel anchor',
    jsx: (
      <>
        Maximize contact with the rock while on rappel or being partner captured
        to minimize force on the rappel{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> or capturers.
      </>
    ),
    terms: ['smear'],
  },
  {
    id: 'smooth-operator',
    tooltip: 'BluuGnome brand name of a toggle',
    jsx: (
      <>
        <Link to={routes.glossary_bluugnome}>BluuGnome</Link> brand name of a{' '}
        <Link to={routes.glossary_toggle}>toggle</Link>.
      </>
    ),
    terms: ['smooth-operator'],
    article: {
      title: 'Smooth Operator website',
      route:
        'https://www.bg-gear.com/store/index.php?main_page=product_info&cPath=1&products_id=1',
    },
  },
  {
    id: 'social-trail',
    tooltip:
      'Informal, non-designated trail between two locations. Social trails often result in trampling stresses to sensitive vegetation types.',
    jsx: (
      <>
        Informal, non-designated trail between two locations. Social trails
        often result in trampling stresses to sensitive vegetation types.
        Definition from{' '}
        <Link to="https://www.nps.gov/yose/learn/management/glossary.htm">
          Yosemite National Park Glossary Terms
        </Link>
        .
      </>
    ),
    terms: ['social-trail'],
  },
  {
    id: 'soft-lock-off',
    tooltip:
      "Quick, temporary way of stopping on rappel while freeing up one's brake hand",
    jsx: (
      <>
        Quick, temporary way of stopping oneself and have both hands free while
        on rappel.
      </>
    ),
    terms: ['soft-lock-off'],
  },
  {
    id: 'soft-start',
    tooltip:
      'Technique for starting a rappel where the rappeller applies friction on the rock to minimize loading the anchor',
    jsx: (
      <>
        Technique for starting a <Link to={routes.glossary_rappel}>rappel</Link>{' '}
        where the <Link to={routes.glossary_rappeller}>rappeller</Link> applies
        friction on the rock to minimize loading the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link>. Antithesis of{' '}
        <Link to={routes.glossary_hard_start}>hard start</Link>.
      </>
    ),
    terms: ['soft-start'],
  },
  {
    id: 'solid',
    tooltip:
      'One of the qualities of EARNEST rigging. The anchor can easily sustain forces from rappelling without possibility of failure.',
    jsx: (
      <>
        One of the qualities of EARNEST rigging. The anchor(s) can easily
        sustain forces from rappelling without possibility of failure.
      </>
    ),
    terms: ['solid'],
    article: 'earnest',
  },
  {
    id: 'solo',
    tooltip: 'Descend a canyon without any partners',
    jsx: (
      <>
        Descend a <Link to={routes.glossary_canyon}>canyon</Link> without any
        partners.
      </>
    ),
    terms: ['solo'],
  },
  {
    id: 'solution-pocket',
    tooltip: 'Little pockets in the sandstone walls',
    jsx: (
      <>
        Little pockets in the{' '}
        <Link to={routes.glossary_sandstone}>sandstone</Link> walls. Makes the
        walls look like Swiss cheese.
        <br />
        Sometimes there are <Link to={routes.glossary_arch}>arches</Link> within
        the holes you can <Link to={routes.glossary_rigging}>rig</Link> for an{' '}
        <Link to={routes.glossary_anchor}>anchor</Link>.
      </>
    ),
    terms: ['hueco', 'solution-pocket', 'tafoni'],
  },
  {
    id: 'spelegyca',
    tooltip: 'Petzl brand safety tether',
    jsx: (
      <>
        Petzl brand{' '}
        <Link to={routes.glossary_safety_tether}>safety tether</Link>.
      </>
    ),
    terms: ['spelegyca'],
  },
  {
    id: 'spine',
    tooltip: 'The weight-bearing part of the carabiner opposite to the gate',
    jsx: (
      <>
        This is the weight-bearing part of the{' '}
        <Link to={routes.glossary_carabiner}>carabiner</Link> opposite of the{' '}
        <Link to={routes.glossary_gate}>gate</Link>. It is also where one ties a{' '}
        <Link to={routes.glossary_block_hitch}>block hitch</Link> or{' '}
        <Link to={routes.glossary_bight}>bights</Link> on a{' '}
        <Link to={routes.glossary_jester}>Jester</Link> or{' '}
        <Link to={routes.glossary_plaquette}>plaquette</Link>.
      </>
    ),
    terms: ['spine'],
    article: 'carabiner',
  },
  {
    id: 'spot',
    tooltip:
      "Stand below someone who is down climbing and if they fall try to redirect them so their head or back don't hit the ground",
    jsx: (
      <>
        Stand below someone who is{' '}
        <Link to={routes.glossary_down_climb}>down climbing</Link> and if they
        fall try to redirect them so their head or back don't hit the ground.
      </>
    ),
    terms: ['spot'],
  },
  {
    id: 'spot-plb',
    tooltip: 'Company that manufactures personal locator beacons',
    jsx: (
      <>
        Company that manufactures{' '}
        <Link to={routes.glossary_personal_locator_beacon}>
          personal locator beacons
        </Link>
        .
      </>
    ),
    terms: ['spot-plb'],
  },
  {
    id: 'spring-loaded-camming-device',
    tooltip:
      'Rock climbing protection that pinches into a crack and holds a falling climber',
    jsx: (
      <>
        Rock climbing protection that pinches into a crack and holds a falling
        climber. Rarely used for rappelling in canyoneering since they would be
        difficult to retrieve. Occasionally used in{' '}
        <Link to={routes.glossary_x_canyon}>X canyons</Link> to climb{' '}
        <Link to={routes.glossary_off_width}>OW</Link> sections.
      </>
    ),
    terms: ['spring-loaded-camming-device'],
  },
  {
    id: 'spur',
    tooltip: 'Lateral ridge descending from a larger ridge',
    jsx: (
      <>
        Lateral <Link to={routes.glossary_ridge}>ridge</Link> descending from a
        larger ridge.
      </>
    ),
    terms: ['nose-landform', 'spur'],
  },
  {
    id: 'sqwurel',
    tooltip:
      'Bluugnome brand figure 8 style canyoneering specific rappel device',
    jsx: (
      <>
        <Link to={routes.glossary_bluugnome}>Bluugnome</Link> brand{' '}
        <Link to={routes.glossary_figure_8_rappel_device}>figure 8</Link> style
        canyoneering specific{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link>. It is
        named for one of his prototypes that looked like a squirrel and the name
        stuck.
      </>
    ),
    terms: ['sqwurel'],
    article: {
      title: 'SQWUREL Rappel/Belay Device website',
      route: 'http://www.bluugnome.com/cyn_how-to/gear/sqwurel/sqwurel.aspx',
    },
  },
  {
    id: 'stacked-overhand-bend',
    tooltip:
      'A flat overhand bend with a second overhand knot tied onto the tail as a backup to prevent capsizing',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_flat_overhand_bend}>flat overhand bend</Link>{' '}
        with a second{' '}
        <Link to={routes.glossary_overhand_knot}>overhand knot</Link> tied into
        the <Link to={routes.glossary_tail}>tail</Link> as a backup to prevent{' '}
        <Link to={routes.glossary_capsize}>capsizing</Link>.
      </>
    ),
    terms: ['stacked-overhand-bend'],
  },
  {
    id: 'static-block',
    tooltip: 'A block that is unable to release under load',
    jsx: (
      <>
        A <Link to={routes.glossary_block}>block</Link> that is unable to
        release under load. The two I can think of off-hand are{' '}
        <Link to={routes.glossary_biner_block}>biner blocks</Link> and{' '}
        <Link to={routes.glossary_knot_block}>knot blocks</Link>.
      </>
    ),
    terms: ['static-block'],
  },
  {
    id: 'static-block-to-lower',
    tooltip:
      'The process of lifting and shifting the load off of the static block and onto a friction hitch or rappel device to safely lower the load',
    jsx: (
      <>
        The process of{' '}
        <Link to={routes.glossary_lift_and_shift}>lifting and shifting</Link>{' '}
        the load off of the{' '}
        <Link to={routes.glossary_static_block}>static block</Link> and onto a{' '}
        <Link to={routes.glossary_friction_hitch}>friction hitch</Link> or{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> to safely
        lower the load. This is done when the rappeller is stuck on rappel and
        is unable to <Link to={routes.glossary_self_rescue}>self-rescue</Link>.
      </>
    ),
    terms: ['static-block-to-lower'],
  },
  {
    id: 'static-rope',
    tooltip:
      'Kernmantle rope that does not stretch a lot when loaded. Great for rappelling.',
    jsx: (
      <>
        <Link to={routes.glossary_kernmantle_rope}>Kernmantle rope</Link> that
        does not stretch a lot when loaded. Great for rappelling. All
        canyoneering ropes are static. The other kind of kernmantle rope is{' '}
        <Link to={routes.glossary_dynamic_rope}>dynamic rope</Link>.
      </>
    ),
    terms: ['static-rope'],
    article: 'rope',
  },
  {
    id: 'stemming',
    tooltip:
      'General term for traversing a skinny section of canyon without touching the bottom by applying pressure on both canyon walls',
    jsx: (
      <>
        General term for traversing a skinny section of canyon without touching
        the bottom by applying pressure on both canyon walls. It also
        specifically refers to traversing with one hand and foot on one wall and
        the other hand and foot on the other wall. See{' '}
        <Link to={routes.glossary_chimneying}>chimneying</Link> and{' '}
        <Link to={routes.glossary_bridging}>bridging</Link>.
      </>
    ),
    terms: ['star-bridging', 'stemming'],
  },
  {
    id: 'step',
    tooltip: 'Last person on rappel checklist: Safety, Tug, Equipment, Pull',
    jsx: (
      <>
        <strong>S</strong>afety <strong>T</strong>ug <strong>E</strong>quipment{' '}
        <strong>P</strong>ull. Checklist of thing the last person on rappel
        should do before rappelling.
      </>
    ),
    terms: ['step'],
  },
  {
    id: 'stone-8',
    tooltip:
      'A static twin rope system that uses a figure 8 to isolate the strands of rope',
    jsx: (
      <>
        A static{' '}
        <Link to={routes.glossary_twin_rope_system}>twin rope system</Link> that
        uses a <Link to={routes.glossary_figure_8_rappel_device}>figure 8</Link>
        -style <Link to={routes.glossary_rappel_device}>rappel device</Link> to
        isolate the strands of rope. Named after the{' '}
        <Link to={routes.glossary_stone_knot}>stone knot</Link>.
      </>
    ),
    terms: ['stone-8', 'stone-totem'],
  },
  {
    id: 'stone-knot',
    tooltip:
      'A hitch tied below the rigging that is used most often with a toggle or a twin rope system',
    jsx: (
      <>
        A <Link to={routes.glossary_hitch}>hitch</Link> tied below the{' '}
        <Link to={routes.glossary_rigging}>rigging</Link> that is used most
        often with a <Link to={routes.glossary_toggle}>toggle</Link> or a{' '}
        <Link to={routes.glossary_twin_rope_system}>twin rope system</Link>.
        Originally from Steinknoten, which is German for stone knot.
      </>
    ),
    terms: ['stein-knot', 'stone-knot'],
  },
  {
    id: 'strainer',
    tooltip:
      'Swift water hazard where water can pass through but canyoneers or other objects cannot',
    jsx: (
      <>
        <Link to={routes.glossary_swift_water}>Swift water</Link>{' '}
        <Link to={routes.glossary_water_hazard}>hazard</Link> where water can
        pass through but canyoneers or other objects cannot. An example of a
        strainer is a <Link to={routes.glossary_logjam}>logjam</Link>.
      </>
    ),
    terms: ['strainer'],
  },
  {
    id: 'subway',
    tooltip:
      'Section of canyon with vertically rounded walls that resemble a subway tube',
    jsx: <>Section of canyon with rounded walls that resemble a subway tube.</>,
    terms: ['subway'],
  },
  {
    id: 'super-amazing-canyoneering-map',
    tooltip: 'Comprehensive map of canyon names and locations',
    jsx: (
      <>Comprehensive map of canyon names and locations created by Bob Allan.</>
    ),
    terms: ['super-amazing-canyoneering-map'],
    article: {
      title: 'Super Amazing Canyoneering Map website',
      route:
        'https://amazingslots.blogspot.com/2017/07/the-super-amazing-canyoneering-map.html',
    },
  },
  {
    id: 'suwa',
    tooltip: 'Southern Utah Wilderness Alliance',
    jsx: (
      <>Southern Utah Wilderness Alliance - canyon country advocacy group.</>
    ),
    terms: ['suwa'],
    article: {
      title: 'Southern Utah Wilderness Alliance',
      route: 'https://suwa.org/',
    },
  },
  {
    id: 'swift-water',
    tooltip:
      'Water with a current sufficiently strong enough to create additional hazards in canyoneering',
    jsx: (
      <>
        Water with a current sufficiently strong enough to create additional{' '}
        <Link to={routes.glossary_water_hazard}>hazards</Link> in canyoneering.
      </>
    ),
    terms: ['swift-water'],
  },
];

export default definitions;
