// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'macrame',
    tooltip: 'A retrievable rigging system that requires only a rope',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_retrievable_rigging_system}>
          retrievable rigging system
        </Link>{' '}
        that requires only a rope. Rarely used because the knot at the top is
        easily overtightened and the rope cannot be retrieved. The name probably
        comes from the{' '}
        <Link to="https://en.wikipedia.org/wiki/Macram%C3%A9">textile</Link>.
      </>
    ),
    terms: ['macrame'],
  },
  {
    id: 'magic-x',
    tooltip:
      'Puts the rapide within the webbing loop so that the rigging is redundant',
    jsx: (
      <>
        When rigging a{' '}
        <Link to={routes.glossary_multi_point_anchor}>multi-point anchor</Link>{' '}
        and not tying a{' '}
        <Link to={routes.glossary_master_point}>master point</Link>, a magic X
        puts the rapide within the webbing loop so that the rigging is
        redundant.
      </>
    ),
    terms: ['magic-x', 'sliding-x'],
  },
  {
    id: 'major-axis',
    tooltip: 'The direction a carabiner is designed to be weighted',
    jsx: (
      <>
        The direction a <Link to={routes.glossary_carabiner}>carabiner</Link> is
        designed to be weighted. The perpendicular direction is{' '}
        <Link to={routes.glossary_minor_axis}>minor axis</Link>.
      </>
    ),
    terms: ['major-axis'],
    article: 'carabiner',
  },
  {
    id: 'marginal-anchor',
    tooltip:
      'An anchor where the amount of force a canyoneer applies to it is close to the amount of force that will cause it to fail',
    jsx: (
      <>
        An <Link to={routes.glossary_anchor}>anchor</Link> where the amount of
        force a canyoneer applies to it is close to the amount of force that
        will cause it to fail.
      </>
    ),
    terms: ['marginal-anchor'],
  },
  {
    id: 'mariners-hitch',
    tooltip:
      'A load releasing hitch that can also be used to lift and shift a load',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_load_releasing_hitch}>
          load releasing hitch
        </Link>{' '}
        that can also be used to{' '}
        <Link to={routes.glossary_lift_and_shift}>lift and shift</Link> a load.
      </>
    ),
    terms: ['mariners-hitch'],
  },
  {
    id: 'master-point',
    tooltip:
      'An overhand knot tied above the rapide that can be used as an alternate rigging point',
    jsx: (
      <>
        An <Link to={routes.glossary_overhand_knot}>overhand knot</Link> tied
        above the <Link to={routes.glossary_rapide}>rapide</Link> that can be
        used as an alternate rigging point.
      </>
    ),
    terms: ['bhk', 'focal-point', 'master-point', 'shelf', 'technical-ledge'],
  },
  {
    id: 'maxxipad',
    tooltip:
      'Name of the original sand trap. Invented by the brilliant Steve Woodford.',
    jsx: (
      <>
        Name of the original{' '}
        <Link to={routes.glossary_sand_trap}>sand trap</Link>. Invented by the
        brilliant Steve Woodford.
      </>
    ),
    terms: ['maxxipad'],
  },
  {
    id: 'mechanical-advantage',
    tooltip:
      'Mechanical Advantage - use simple tools to make hauling someone up a cliff easier',
    jsx: (
      <>
        Use simple tools to make{' '}
        <Link to={routes.glossary_haul_system}>hauling</Link> someone up a cliff
        easier. Measured as a ratio of how much rope is pulled up by the hauler
        versus how much the person is moved up the cliff (assuming 0% rope{' '}
        <Link to={routes.glossary_elongation}>elongation</Link>).
        <br />A simple example would be having one side of the the rope tied to
        an anchor and the other side going down to the person, through a pulley,
        and back up the person hauling. In this example for every 2 feet the
        rope is hauled, the person goes up one foot so it is "2 to 1" mechanical
        advantage.
      </>
    ),
    terms: ['ma', 'mechanical-advantage'],
  },
  {
    id: 'mia-exit',
    tooltip: 'The most difficult canyon exit in Zion National Park',
    jsx: (
      <>
        The most difficult canyon exit in Zion National Park. MIA stands for{' '}
        <strong>M</strong>utual <strong>I</strong>mprovement <strong>A</strong>
        ssociation and is an organization within the Church of Jesus Christ of
        Latter-Day Saints that caters to young people. One of their camps is
        located at the top of the ascent.
      </>
    ),
    terms: ['mia-exit'],
    article: {
      title: 'Boundary Canyon',
      route:
        'https://www.canyoneeringusa.com/zion/technical/boundary#block-480527a2e9738cae1ff2',
    },
  },
  {
    id: 'micro-traxion',
    tooltip: 'Petzl brand ascender and PCD',
    jsx: (
      <>
        Petzl brand <Link to={routes.glossary_pulley}>pulley</Link>,{' '}
        <Link to={routes.glossary_toothed_ascender}>toothed ascender</Link>, and{' '}
        <Link to={routes.glossary_progress_capture_device}>PCD</Link>.
      </>
    ),
    terms: ['micro-traxion'],
  },
  {
    id: 'minimum-breaking-strength',
    tooltip:
      'The minimum amount of force required that could cause a safety-critical component to fail',
    jsx: (
      <>
        The minimum amount of force required that could cause a safety-critical
        component to fail.
      </>
    ),
    terms: ['mbs', 'minimum-breaking-strength'],
  },
  {
    id: 'minor-axis',
    tooltip: 'The direction of pull that cross-loads a carabiner',
    jsx: (
      <>
        The direction of pull that{' '}
        <Link to={routes.glossary_cross_load}>cross-loads</Link> a{' '}
        <Link to={routes.glossary_carabiner}>carabiner</Link>. The perpendicular
        direction is the <Link to={routes.glossary_major_axis}>major axis</Link>
        .
      </>
    ),
    terms: ['minor-axis'],
    article: 'carabiner',
  },
  {
    id: 'monsoon-season',
    tooltip: 'Mid July to mid September',
    jsx: (
      <>
        Mid July to mid September on the{' '}
        <Link to={routes.glossary_colorado_plateau}>Colorado Plateau</Link>.
        This is when flash floods are most common though flash floods can happen
        any time of the year.
      </>
    ),
    terms: ['monsoon-season'],
  },
  {
    id: 'monsoonal-rain',
    tooltip: 'Heavy burst of rain common during the monsoon season',
    jsx: (
      <>
        Heavy burst of rain common during the{' '}
        <Link to={routes.glossary_monsoon_season}>monsoon season</Link>.
      </>
    ),
    terms: ['monsoonal-rain'],
  },
  {
    id: 'moqui',
    tooltip: "Means 'the dead' in the Hopi language",
    jsx: <>Means "the dead" in the Hopi language.</>,
    terms: ['moki', 'moqui'],
  },
  {
    id: 'moqui-marble',
    tooltip: 'Ball of hematite cemented sandstone',
    jsx: (
      <>
        Ball of{' '}
        <Link to="https://en.wikipedia.org/wiki/Hematite">hematite</Link>{' '}
        cemented <Link to={routes.glossary_sandstone}>sandstone</Link>. See{' '}
        <Link to={routes.glossary_moqui}>moqui</Link>.
      </>
    ),
    terms: ['moqui-marble'],
  },
  {
    id: 'moqui-steps',
    tooltip: 'Ancient hand and foot holds carved into the canyon walls',
    jsx: (
      <>
        Ancient hand and foot holds carved into the canyon walls. See{' '}
        <Link to={routes.glossary_moqui}>moqui</Link>.
      </>
    ),
    terms: ['moqui-steps'],
  },
  {
    id: 'mountain',
    tooltip: 'Upside down canyon',
    jsx: (
      <>
        Upside down <Link to={routes.glossary_canyon}>canyon</Link>.
      </>
    ),
    terms: ['mountain'],
  },
  {
    id: 'mule-hitch',
    tooltip: 'Used to lock off a Munter hitch in an MMO',
    jsx: (
      <>
        Used to lock off a{' '}
        <Link to={routes.glossary_munter_hitch}>Munter hitch</Link> in an{' '}
        <Link to={routes.glossary_munter_mule_overhand}>MMO</Link>.
      </>
    ),
    terms: ['mule-hitch'],
  },
  {
    id: 'multi-directional-load',
    tooltip:
      "Weighting a carabiner in three or more directions - most carabiners aren't rated for such a load",
    jsx: (
      <>
        Weighting a <Link to={routes.glossary_carabiner}>carabiner</Link> in
        three or more directions - most carabiners aren't rated for such a load.
        Loading in exactly three direction is{' '}
        <Link to={routes.glossary_triaxial_load}>triaxial loading</Link>.
      </>
    ),
    terms: ['multi-directional-load'],
    article: 'carabiner',
  },
  {
    id: 'multi-point-anchor',
    tooltip: 'Multiple anchors connected by one set of rigging',
    jsx: (
      <>
        Multiple <Link to={routes.glossary_anchor}>anchors</Link> connected by
        one set of <Link to={routes.glossary_rigging}>rigging</Link>.
      </>
    ),
    terms: ['multi-point-anchor'],
  },
  {
    id: 'multi-stage-rappel',
    tooltip:
      'Multiple rappels separated by short walks or swims that are chained together into a single rappel',
    jsx: (
      <>
        Multiple <Link to={routes.glossary_rappel}>rappels</Link> separated by
        short walks or swims that are chained together into a single rappel.
      </>
    ),
    terms: ['multi-pitch-rappel', 'multi-stage-rappel'],
  },
  {
    id: 'munter-hitch',
    tooltip: 'A friction hitch that can be used to rappel or lower',
    jsx: (
      <>
        A <Link to={routes.glossary_friction_hitch}>friction hitch</Link> that
        can be used to rappel or <Link to={routes.glossary_lower}>lower</Link>.
        Has a tendency to twist rope. Named after Swiss mountain guide Werner
        Munter.
      </>
    ),
    terms: ['italian-hitch', 'munter-hitch'],
  },
  {
    id: 'munter-mule-overhand',
    tooltip:
      'Munter Mule Overhand - a load releasing hitch and releasable rigging system that consists of a Munter hitch to provide friction, a mule hitch that stops the rope and can be released under tension, and an overhand on a bight backup for the mule hitch',
    jsx: (
      <>
        A{' '}
        <Link to={routes.glossary_load_releasing_hitch}>
          load releasing hitch
        </Link>{' '}
        and{' '}
        <Link to={routes.glossary_releasable_rigging_system}>
          releasable rigging system
        </Link>{' '}
        that consists of a{' '}
        <Link to={routes.glossary_munter_hitch}>Munter hitch</Link> to provide
        friction, a <Link to={routes.glossary_mule_hitch}>mule hitch</Link> that
        stops the rope and can be released under tension, and an{' '}
        <Link to={routes.glossary_overhand_on_a_bight}>
          overhand on a bight
        </Link>{' '}
        backup for the mule hitch.
      </>
    ),
    terms: ['mmo', 'munter-mule-overhand'],
  },
];

export default definitions;
