// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'taco',
    tooltip:
      'A sand trap configuration that is used with favorable geometry like when going over a lip',
    jsx: (
      <>
        A <Link to={routes.glossary_sand_trap}>sand trap</Link> configuration
        that is used with favorable geometry like when going over a lip. Less
        sand is needed than with the{' '}
        <Link to={routes.glossary_tostada}>tostada</Link>.
      </>
    ),
    terms: ['taco'],
  },
  {
    id: 'tail',
    tooltip: 'The end of a rope',
    jsx: (
      <>
        The end of a <Link to={routes.glossary_rope}>rope</Link>.
      </>
    ),
    terms: ['tail'],
  },
  {
    id: 'tail-up-retrievable-rigging-system',
    tooltip:
      'Retrievable rigging system where the entirety of the rappel line does not have to be pulled through the anchor rigging in order to retrieve the rigging',
    jsx: (
      <>
        <Link to={routes.glossary_tail_up_rigging_system}>
          Tail up rigging system
        </Link>{' '}
        and{' '}
        <Link to={routes.glossary_retrievable_rigging_system}>
          Retrievable rigging system
        </Link>{' '}
        where the <Link to={routes.glossary_rappel_line}>rappel line</Link> does
        not have to pass through the{' '}
        <Link to={routes.glossary_rigging}>rigging</Link> in order to be
        retrieved.
        <br />
        Examples are rope-only systems such as the{' '}
        <Link to={routes.glossary_macrame}>Macrame</Link> and{' '}
        <Link to={routes.glossary_cem}>CEM</Link>, a{' '}
        <Link to={routes.glossary_toggle}>toggle</Link>,{' '}
        <Link to={routes.glossary_sand_trap}>sand trap</Link>, and{' '}
        <Link to={routes.glossary_water_anchor}>water anchor</Link>.
      </>
    ),
    terms: ['tail-up-retrievable-rigging-system'],
  },
  {
    id: 'tail-up-rigging-system',
    tooltip:
      'A method of rigging the rope to the anchor near the tail and dropping the bag down to the bottom of the rappel',
    jsx: (
      <>
        A method of <Link to={routes.glossary_rigging}>rigging</Link> the{' '}
        <Link to={routes.glossary_rope}>rope</Link> to the{' '}
        <Link to={routes.glossary_anchor}>anchor</Link> near the{' '}
        <Link to={routes.glossary_tail}>tail</Link> and dropping the{' '}
        <Link to={routes.glossary_rope_bag}>bag</Link> down to the bottom of the{' '}
        <Link to={routes.glossary_rappel}>rappel</Link>. This is the fastest way
        to ensure that the rope reaches the bottom of the rappel but there are
        many disadvantages to rigging this way compared to{' '}
        <Link to={routes.glossary_bag_up_rigging_system}>bag up rigging</Link>.
      </>
    ),
    terms: ['bag-down-rigging-system', 'tail-up-rigging-system'],
  },
  {
    id: 'talon',
    tooltip: 'Black Diamond brand 3-in-1 aid climbing hook',
    jsx: (
      <>
        <Link to="https://www.blackdiamondequipment.com/en_US/talon-hook-BD5202000000ALL1.html">
          Black Diamond
        </Link>{' '}
        brand 3-in-1 aid climbing <Link to={routes.glossary_hook}>hook</Link>.
      </>
    ),
    terms: ['talon'],
  },
  {
    id: 'tandem-rappel',
    tooltip:
      'Two rappellers descent together on one or two rappel devices that are controlled by only one of the rappellers',
    jsx: (
      <>
        Two rappellers descent together on one or two rappel devices that are
        controlled by only one of the rappellers. Great way to get children or
        first-time rappellers comfortable on rappel.
      </>
    ),
    terms: ['assisted-rappel', 'tandem-rappel'],
  },
  {
    id: 'technical-rating',
    tooltip: 'A vague estimation of the difficulty of a canyon',
    jsx: (
      <>
        A vague estimation of the difficulty of a canyon.
        <br />
        This includes things like whether or not rappelling is required, if the
        canyon has advanced down climbing, and if there are any difficult or
        advanced features in the canyon. The ratings are{' '}
        <Link to={routes.glossary_1_technical_rating}>1</Link>,{' '}
        <Link to={routes.glossary_2_technical_rating}>2</Link>,{' '}
        <Link to={routes.glossary_3_technical_rating}>3</Link>, and{' '}
        <Link to={routes.glossary_4_technical_rating}>4</Link>.
      </>
    ),
    terms: ['technical-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'technical-section',
    tooltip:
      'The part between the approach and exit where the rappelling, down climbing, and other obstacles are located',
    jsx: (
      <>
        The part between the <Link to={routes.glossary_approach}>approach</Link>{' '}
        and <Link to={routes.glossary_exit}>exit</Link> where the rappelling,
        down climbing, and other obstacles are located. The exact definition
        varies between{' '}
        <Link to={routes.glossary_beta_provider}>beta providers</Link> and
        canyons.
      </>
    ),
    terms: ['technical-section'],
  },
  {
    id: 'technora',
    tooltip: 'Rope material that can withstand heat and is very durable',
    jsx: <>Rope material that can withstand heat and is very durable.</>,
    terms: ['technora'],
  },
  {
    id: 'tensile-strength',
    tooltip:
      'How much force can be applied to a rope without it breaking or being damaged',
    jsx: (
      <>
        How much force can be applied to a{' '}
        <Link to={routes.glossary_rope}>rope</Link> without it breaking or being
        damaged.
      </>
    ),
    terms: ['tensile-strength'],
  },
  {
    id: 'tensionless-rigging',
    tooltip:
      'Rigging that is typically wrapped around a large tree several times before being secured with a knot',
    jsx: (
      <>
        <Link to={routes.glossary_rigging}>Rigging</Link> that is typically
        wrapped around a large tree several times before being secured with a
        knot. The rigging can hold much more weight, will be easy to untie, and
        will help to preserve the tree. I've never seen this rigging on an
        anchor in canyon.
      </>
    ),
    terms: ['tensionless-rigging'],
  },
  {
    id: 'tibloc',
    tooltip: 'Lightweight Petzl brand toothed ascender and rope grab',
    jsx: (
      <>
        Lightweight Petzl brand{' '}
        <Link to={routes.glossary_toothed_ascender}>toothed ascender</Link> and{' '}
        <Link to={routes.glossary_rope_grab}>rope grab</Link>.
      </>
    ),
    terms: ['tibloc'],
  },
  {
    id: 'time-rating',
    tooltip:
      'How long it should take to descend a canyon including the approach and exit',
    jsx: (
      <>
        How long it should take to descend a canyon including the{' '}
        <Link to={routes.glossary_approach}>approach</Link> and{' '}
        <Link to={routes.glossary_exit}>exit</Link>. It ranges from a couple of
        hours to multiple days. Time rating is a Roman Numeral{' '}
        <Link to={routes.glossary_i_time_rating}>I</Link>,{' '}
        <Link to={routes.glossary_ii_time_rating}>II</Link>,{' '}
        <Link to={routes.glossary_iii_time_rating}>III</Link>,{' '}
        <Link to={routes.glossary_iv_time_rating}>IV</Link>,{' '}
        <Link to={routes.glossary_v_time_rating}>V</Link>, and{' '}
        <Link to={routes.glossary_vi_time_rating}>VI</Link>.
      </>
    ),
    terms: ['time-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'timely',
    tooltip:
      'One of the qualities of EARNEST rigging. The anchor is rigged in a timely manner.',
    jsx: (
      <>
        One of the qualities of EARNEST rigging. The anchor is rigged in a
        timely manner.
      </>
    ),
    terms: ['timely'],
    article: 'earnest',
  },
  {
    id: 'toggle',
    tooltip:
      'Stick used as a component in retrievable rigging. Rig a stone knot with the toggle instead of a carabiner.',
    jsx: (
      <>
        Stick used as a component in{' '}
        <Link to={routes.glossary_retrievable_rigging_system}>
          retrievable rigging
        </Link>
        . Rig a <Link to={routes.glossary_stone_knot}>stone knot</Link> with the
        toggle instead of a carabiner. The toggle is tied to the{' '}
        <Link to={routes.glossary_pull_cord}>pull cord</Link>. Everyone rappels.
        Pull the pull cord. Out comes the toggle and the knot falls apart. Pull
        down the rope and pull cord. Definitely an advanced tool. Brands I'm
        aware of are the{' '}
        <Link to={routes.glossary_fiddlestick}>FiddleStick</Link>,{' '}
        <Link to={routes.glossary_smooth_operator}>Smooth Operator</Link>, and{' '}
        <Link to={routes.glossary_whiptail}>Whiptail</Link>.
      </>
    ),
    terms: ['toggle'],
  },
  {
    id: 'toothed-ascender',
    tooltip:
      'An ascender that uses teeth to bite the rope rather than a cam that pinches it',
    jsx: (
      <>
        An <Link to={routes.glossary_ascender}>ascender</Link> that uses teeth
        to bite the rope rather than a <Link to={routes.glossary_cam}>cam</Link>{' '}
        that pinches it.
      </>
    ),
    terms: ['toothed-ascender'],
  },
  {
    id: 'top-rope-belay',
    tooltip:
      'A belay that is done by attaching a separate rope tied to the rappeller and belayed with a separate device',
    jsx: (
      <>
        A <Link to={routes.glossary_belay}>belay</Link> that is done by
        attaching a separate rope tied to the rappeller and belayed with a
        separate device. The belayer can be at the top or bottom of the rappel.
      </>
    ),
    terms: ['top-rope-belay'],
  },
  {
    id: 'topographic-map',
    tooltip:
      'A type of map characterized by large-scale detail and quantitative representation of relief, usually using contour lines',
    jsx: (
      <>
        A type of map characterized by large-scale detail and quantitative
        representation of relief, usually using{' '}
        <Link to={routes.glossary_contour_line}>contour lines</Link>. Definition
        from{' '}
        <Link to="https://en.wikipedia.org/wiki/Topographic_map">
          Wikipedia
        </Link>
        .
      </>
    ),
    terms: ['topo', 'topographic-map'],
    article: {
      title: 'USGS Topographic Maps website',
      route: 'https://www.usgs.gov/products/maps/topo-maps',
    },
  },
  {
    id: 'toss-n-go',
    tooltip:
      'Feed the rope through the rapide until both strands reach the bottom of the rappel. Then rappel on both strands.',
    jsx: (
      <>
        The simplest form of{' '}
        <Link to={routes.glossary_double_rope_technique}>DRT</Link>. Feed the
        rope through the <Link to={routes.glossary_rapide}>rapide</Link> until
        both strands reach the bottom of the rappel. Then rappel on both
        strands.
      </>
    ),
    terms: ['tg', 'throw-n-go', 'tng', 'toss-n-go'],
  },
  {
    id: 'tostada',
    tooltip:
      'A sand trap configuration that is used with unfavorable geometry such as flat ground',
    jsx: (
      <>
        A <Link to={routes.glossary_sand_trap}>sand trap</Link> configuration
        that is used with unfavorable geometry such as flat ground. More sand is
        needed than with the <Link to={routes.glossary_taco}>taco</Link>.
      </>
    ),
    terms: ['tostada'],
  },
  {
    id: 'totem',
    tooltip:
      'Rigging plate and rappel device invented by Rich Carlson and manufactured by Rock Exotica',
    jsx: (
      <>
        Rigging plate and{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> invented
        by Rich Carlson and manufactured by Rock Exotica. Looks like a{' '}
        <Link to={routes.glossary_figure_8_rappel_device}>figure 8</Link> plus a
        Kong Gi-Gi.{' '}
        <Link to="https://www.rockexotica.com/product/descenders/totem/">
          Buy a Totem
        </Link>
        .
      </>
    ),
    terms: ['totem'],
  },
  {
    id: 'trade-canyon',
    tooltip:
      'A canyon that is easily accessible, has lots of beta, is beginner friendly, and frequently descended',
    jsx: (
      <>
        A <Link to={routes.glossary_canyon}>canyon</Link> that is easily
        accessible, has lots of <Link to={routes.glossary_beta}>beta</Link>, is
        beginner friendly, and frequently descended.
      </>
    ),
    terms: ['trade-canyon'],
  },
  {
    id: 'transient-anchor',
    tooltip:
      'A temporary anchor that is created for the rappel and then removed afterwards',
    jsx: (
      <>
        A temporary <Link to={routes.glossary_anchor}>anchor</Link> that is
        created and used for the <Link to={routes.glossary_rappel}>rappel</Link>{' '}
        and then removed afterwards. Examples are{' '}
        <Link to={routes.glossary_sand_trap}>sand trap</Link>,{' '}
        <Link to={routes.glossary_water_anchor}>water anchor</Link>,{' '}
        <Link to={routes.glossary_human_anchor}>human anchor</Link>,{' '}
        <Link to={routes.glossary_sand_bag}>sand bag</Link>,{' '}
        <Link to={routes.glossary_pack_drag}>pack drag</Link>, and{' '}
        <Link to={routes.glossary_hook}>hook</Link>.
      </>
    ),
    terms: ['transient-anchor'],
  },
  {
    id: 'traveling-rope-grab',
    tooltip: 'A specific rope grab in a mechanical advantage hauling system',
    jsx: (
      <>
        A specific <Link to={routes.glossary_rope_grab}>rope grab</Link> in a{' '}
        <Link to={routes.glossary_mechanical_advantage}>
          mechanical advantage
        </Link>{' '}
        hauling system.
      </>
    ),
    terms: ['traveling-rope-grab', 'truck'],
  },
  {
    id: 'traverse',
    tooltip: 'travel horizontally',
    jsx: (
      <>
        Travel horizontally. Similar to{' '}
        <Link to={routes.glossary_stemming}>stemming</Link>.
      </>
    ),
    terms: ['traverse'],
  },
  {
    id: 'triaxial-load',
    tooltip:
      "Weighting a carabiner in three directions - most carabiners aren't rated for such a load",
    jsx: (
      <>
        Weighting a <Link to={routes.glossary_carabiner}>carabiner</Link> in
        three directions - most carabiners aren't rated for such a load. The
        generic term for this is{' '}
        <Link to={routes.glossary_multi_directional_load}>
          multi-directional load
        </Link>
        .
      </>
    ),
    terms: ['triaxial-load'],
    article: 'carabiner',
  },
  {
    id: 'triple-clove-hitch',
    tooltip:
      'A hitch that is most commonly used in canyoneering as a block hitch for biner blocks',
    jsx: (
      <>
        A <Link to={routes.glossary_hitch}>hitch</Link> that is most commonly
        used in canyoneering as a{' '}
        <Link to={routes.glossary_block_hitch}>block hitch</Link> for{' '}
        <Link to={routes.glossary_biner_block}>biner blocks</Link>.
      </>
    ),
    terms: ['triple-clove-hitch'],
  },
  {
    id: 'tubular-webbing',
    tooltip:
      'Webbing that is sewn as a tube and flattened. It is stronger and more durable than traditional flat webbing of the same size.',
    jsx: (
      <>
        <Link to={routes.glossary_webbing}>Webbing</Link> that is sewn as a tube
        and flattened. It is stronger and more durable than traditional flat
        webbing of the same size.
      </>
    ),
    terms: ['tubular-webbing'],
  },
  {
    id: 'twin-rope-system',
    tooltip:
      'A system where both strands of rope are isolated so that one canyoneer can rappel on one while another canyoneer rigs on the other',
    jsx: (
      <>
        A system where both strands of rope are isolated so that one canyoneer
        can rappel on one while another canyoneer rigs on the other. This is an
        efficient way to get a large group down a rappel. Examples are the{' '}
        <Link to={routes.glossary_stone_knot}>Stone Knot</Link> and{' '}
        <Link to={routes.glossary_jester}>Jester</Link>.
      </>
    ),
    terms: ['twin-rope-system'],
  },
  {
    id: 'tyrolean-traverse',
    tooltip:
      'A traverse that is performed by being clipped into a horizontal rope that is anchored on both sides',
    jsx: (
      <>
        A <Link to={routes.glossary_traverse}>traverse</Link> over an obstacle
        like a <Link to={routes.glossary_keeper_pothole}>keeper pothole</Link>{' '}
        that is performed by being clipped into a horizontal rope that is
        anchored on both sides.
      </>
    ),
    terms: ['tyrolean-traverse'],
  },
];

export default definitions;
