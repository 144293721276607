// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'fall-factor',
    tooltip:
      'The ratio of the height a canyoneer falls before their safety tether begins to stretch and the tether length available to absorb the energy of the fall',
    jsx: (
      <>
        The ratio of the height a canyoneer falls before their{' '}
        <Link to={routes.glossary_safety_tether}>safety tether</Link> begins to
        stretch and the tether length available to absorb the energy of the fall
        (
        <Link to="https://en.wikipedia.org/wiki/Fall_factor">
          Wikipedia article
        </Link>
        ).
        <br />
        The only time a canyoneer is above the anchor is when they are rigging
        the anchor.
      </>
    ),
    terms: ['fall-factor'],
  },
  {
    id: 'fall-zone',
    tooltip:
      'The location below a rappeller where rocks that are dislodged by the canyoneer will fall',
    jsx: (
      <>
        The location below a rappeller where rocks that are dislodged by the
        canyoneer will fall.
      </>
    ),
    terms: ['fall-zone'],
  },
  {
    id: 'farmer-john',
    tooltip:
      'Wetsuit that covers the body and legs, but not the arms or shoulders',
    jsx: (
      <>
        Wetsuit that covers the body and legs, but not the arms or shoulders.
        Looks like overalls. Designed to be worn with a jacket.
      </>
    ),
    terms: ['farmer-john'],
  },
  {
    id: 'fast-rope',
    tooltip: 'Misnomer for skinny rope',
    jsx: (
      <>
        Misnomer for <Link to={routes.glossary_skinny_rope}>skinny rope</Link>.
        Fast implies that with the same friction setting on a{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link>, there
        will be less friction overall.
      </>
    ),
    terms: ['fast-rope'],
    article: 'rope',
  },
  {
    id: 'fat-rope',
    tooltip: "A rope that has a large diameter - I'd say at least 9.2mm.",
    jsx: (
      <>
        A <Link to={routes.glossary_rope}>rope</Link> that has a large diameter
        - I'd say at least 9.5mm.
      </>
    ),
    terms: ['fat-rope'],
    article: 'rope',
  },
  {
    id: 'fiddlestick',
    tooltip: 'Imlay Canyon Gear brand name of a toggle',
    jsx: (
      <>
        <Link to={routes.glossary_imlay_canyon_gear}>Imlay Canyon Gear</Link>{' '}
        brand name of a <Link to={routes.glossary_toggle}>toggle</Link>.
      </>
    ),
    terms: ['fiddlestick'],
    article: {
      title: 'FiddleStick website',
      route:
        'http://www.store.canyoneeringusa.com/index.php?option=com_rokecwid&Itemid=108#!/FiddleStick-Advanced-Anchor-Tool/p/21353554/category=2490812',
    },
  },
  {
    id: 'figure-8-knot',
    tooltip:
      'Knot tied like an overhand with an additional twist. Various uses in canyoneering.',
    jsx: (
      <>
        <Link to={routes.glossary_knot}>Knot</Link> tied like an{' '}
        <Link to={routes.glossary_overhand_knot}>overhand</Link> with an
        additional twist. It is the basis of canyoneering knots such as the{' '}
        <Link to={routes.glossary_figure_8_on_a_bight}>
          figure 8 on a bight
        </Link>{' '}
        and <Link to={routes.glossary_flemish_bend}>flemish bend</Link>.
      </>
    ),
    terms: ['figure-8-knot', 'figure-of-8-knot'],
  },
  {
    id: 'figure-8-on-a-bight',
    tooltip: 'Make a bight in the rope and tie a figure 8 knot',
    jsx: (
      <>
        Make a <Link to={routes.glossary_bight}>bight</Link> in the rope and tie
        a <Link to={routes.glossary_figure_8_knot}>figure 8 knot</Link>.
      </>
    ),
    terms: ['figure-8-on-a-bight'],
  },
  {
    id: 'figure-8-rappel-device',
    tooltip: 'A rappel device that has the shape of the number 8',
    jsx: (
      <>
        A <Link to={routes.glossary_rappel_device}>rappel device</Link> that has
        the shape of the number 8. Most canyoneering rappel devices are derived
        from a figure 8.
      </>
    ),
    terms: ['figure-8-rappel-device'],
  },
  {
    id: 'flake',
    tooltip:
      "Prepare a rope to be deployed so that it doesn't tangle when thrown over the cliff",
    jsx: (
      <>
        Prepare a rope to be deployed so that it doesn't tangle when thrown over
        the cliff. Done by uncoiling an entire rope on the ground and throwing
        the top end. Unnecessary if using a{' '}
        <Link to={routes.glossary_rope_bag}>rope bag</Link>.
      </>
    ),
    terms: ['flake'],
  },
  {
    id: 'flash',
    tooltip: 'When a canyon is experiencing a flash flood',
    jsx: (
      <>
        When a canyon is experiencing a{' '}
        <Link to={routes.glossary_flash_flood}>flash flood</Link>.
      </>
    ),
    terms: ['flash'],
  },
  {
    id: 'flash-flood',
    tooltip:
      'When a canyon has a significant amount of water traveling through it and it is no longer safe to be in that canyon',
    jsx: (
      <>
        When a canyon has a significant amount of water traveling through it and
        it is no longer safe to be in that canyon.
      </>
    ),
    terms: ['flash-flood'],
  },
  {
    id: 'flat-bend',
    tooltip:
      'Bend used to join two ropes in such a way as to prevent the knot from catching on a sharp ledge when retrieving rope',
    jsx: (
      <>
        <Link to={routes.glossary_bend}>Bend</Link> used to join two ropes in
        such a way as to prevent the knot from catching on a sharp ledge when{' '}
        <Link to={routes.glossary_pull}>retrieving rope</Link>. Must have long{' '}
        <Link to={routes.glossary_tail}>tails</Link> due to the propensity to{' '}
        <Link to={routes.glossary_capsize}>capsize</Link>. Common types of flat
        bends are{' '}
        <Link to={routes.glossary_flat_overhand_bend}>flat overhand bend</Link>,{' '}
        <Link to={routes.glossary_flat_double_overhand_bend}>
          flat double overhand bend
        </Link>
        , and{' '}
        <Link to={routes.glossary_stacked_overhand_bend}>
          stacked overhand bend
        </Link>
        .
      </>
    ),
    terms: ['flat-bend'],
  },
  {
    id: 'flat-double-overhand-bend',
    tooltip:
      'A type of flat bend made up of a double (using two ropes) overhand bend with long tails',
    jsx: (
      <>
        A type of <Link to={routes.glossary_flat_bend}>flat bend</Link> made up
        of a double (using two ropes){' '}
        <Link to={routes.glossary_overhand_knot}>overhand knot</Link> with long{' '}
        <Link to={routes.glossary_tail}>tails</Link>.
      </>
    ),
    terms: ['flat-double-overhand-bend'],
  },
  {
    id: 'flat-overhand-bend',
    tooltip:
      'A type of flat bend that uses a single overhand knot with long tails',
    jsx: (
      <>
        A type of <Link to={routes.glossary_flat_bend}>flat bend</Link> that
        uses a single{' '}
        <Link to={routes.glossary_overhand_knot}>overhand knot</Link> with long{' '}
        <Link to={routes.glossary_tail}>tails</Link>. Has the propensity to{' '}
        <Link to={routes.glossary_capsize}>capsize</Link> when weighted. Adding
        a second overhand as a back up makes a{' '}
        <Link to={routes.glossary_stacked_overhand_bend}>
          stacked overhand bend
        </Link>
        .
      </>
    ),
    terms: ['edk', 'european-death-knot', 'flat-overhand-bend'],
  },
  {
    id: 'flemish-bend',
    tooltip: 'Figure 8 knot that is rethreaded, as a bend, with a second tail',
    jsx: (
      <>
        <Link to={routes.glossary_figure_8_knot}>Figure 8 knot</Link> that is
        rethreaded, as a <Link to={routes.glossary_bend}>bend</Link>, with a
        second <Link to={routes.glossary_tail}>tail</Link>.
      </>
    ),
    terms: ['flemish-bend', 'rethreaded-figure-8-bend'],
  },
  {
    id: 'floating-disconnect',
    tooltip:
      "Disconnecting one's rappel device from the rappel rope while floating in water",
    jsx: (
      <>
        Disconnecting one's{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> from the
        rappel <Link to={routes.glossary_rope}>rope</Link> while floating in
        water.
      </>
    ),
    terms: ['floating-disconnect'],
  },
  {
    id: 'flute',
    tooltip: 'An aesthetically pleasing sculpted sandstone dryfall',
    jsx: (
      <>
        An aesthetically pleasing sculpted{' '}
        <Link to={routes.glossary_sandstone}>sandstone</Link>{' '}
        <Link to={routes.glossary_dryfall}>dryfall</Link>.
      </>
    ),
    terms: ['flute'],
  },
  {
    id: 'focused-rigging',
    tooltip:
      'Rigging that is equalized in a specific direction so that the weight distribution favors one anchor over another',
    jsx: (
      <>
        <Link to={routes.glossary_rigging}>Rigging</Link> that is{' '}
        <Link to={routes.glossary_equalized}>equalized</Link> in a specific
        direction so that the weight distribution favors one anchor over
        another. This is done if one anchor is stronger than another.
      </>
    ),
    terms: ['focused-rigging'],
  },
  {
    id: 'foot-entrapment',
    tooltip:
      "Swift water hazard where a canyoneer's foot or other body part can get caught",
    jsx: (
      <>
        <Link to={routes.glossary_swift_water}>Swift water</Link>{' '}
        <Link to={routes.glossary_water_hazard}>hazard</Link> where a
        canyoneer's foot or other body part can get caught under the water.
      </>
    ),
    terms: ['foot-entrapment'],
  },
  {
    id: 'foot-loop',
    tooltip:
      'A loop of cord that is attached to the foot and the rope via a rope grab and used as part of an ascending system',
    jsx: (
      <>
        A loop of cord that is attached to the foot and the rope via a{' '}
        <Link to={routes.glossary_rope_grab}>rope grab</Link> and used as part
        of an <Link to={routes.glossary_ascend}>ascending</Link> system.
      </>
    ),
    terms: ['foot-loop'],
  },
  {
    id: 'free-hang',
    tooltip:
      'Section of a rappel where the rappeller in not in contact with the cliff',
    jsx: (
      <>
        Section of a <Link to={routes.glossary_rappel}>rappel</Link> where the
        rappeller in not in contact with the cliff.
      </>
    ),
    terms: ['free-hang'],
  },
  {
    id: 'free-rappel',
    tooltip: 'Rappel on a free hang',
    jsx: (
      <>
        <Link to={routes.glossary_rappel}>Rappel</Link> on a{' '}
        <Link to={routes.glossary_free_hang}>free hang</Link>.
      </>
    ),
    terms: ['free-rappel'],
  },
  {
    id: 'friction-hitch',
    tooltip:
      'A hitch where a cord is tied around a larger diameter rope so that the cord can support the weight of a canyoneer but can be easily adjusted',
    jsx: (
      <>
        A <Link to={routes.glossary_hitch}>hitch</Link> where a cord is tied
        around a larger diameter rope so that the cord can support the weight of
        a canyoneer but can be easily adjusted. Uses I know of are as an{' '}
        <Link to={routes.glossary_autoblock}>autoblock</Link>,{' '}
        <Link to={routes.glossary_ascend}>ascending</Link>, a backup when{' '}
        <Link to={routes.glossary_lower}>lowering</Link> someone, and part of a
        system when <Link to={routes.glossary_pass_a_knot}>passing a knot</Link>
        .
      </>
    ),
    terms: ['friction-hitch', 'soft-rope-grab'],
  },
  {
    id: 'frost-knot',
    tooltip:
      'Rethreaded overhand bend that joins one strand of webbing with a loop of webbing. Its primary use is for courtesy rigging.',
    jsx: (
      <>
        Rethreaded <Link to={routes.glossary_overhand_knot}>overhand</Link>{' '}
        <Link to={routes.glossary_bend}>bend</Link> that joins one strand of{' '}
        <Link to={routes.glossary_webbing}>webbing</Link> with a{' '}
        <Link to={routes.glossary_loop}>loop</Link> of webbing. Its primary use
        is for{' '}
        <Link to={routes.glossary_courtesy_rigging}>courtesy rigging</Link>.
      </>
    ),
    terms: ['frost-knot'],
  },
  {
    id: 'full-wetsuit',
    tooltip:
      'Wetsuit that covers the entire body except for hands, feet, and head',
    jsx: (
      <>
        <Link to={routes.glossary_wetsuit}>Wetsuit</Link> that covers the entire
        body except for hands, feet, and head.
      </>
    ),
    terms: ['full-wetsuit'],
  },
];

export default definitions;
