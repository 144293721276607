// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: '1-technical-rating',
    tooltip: 'A canyon that is non-technical and easy',
    jsx: <>A canyon that is non-technical and easy.</>,
    terms: ['1-technical-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: '2-ring-retrievable-rigging',
    tooltip:
      'Retrievable rigging made up of a basket hitched sling with a ring on each end which is retrieved with a separate pull cord',
    jsx: (
      <>
        <Link to={routes.glossary_retrievable_rigging_system}>
          Retrievable rigging
        </Link>{' '}
        made up of a{' '}
        <Link to={routes.glossary_basket_hitch}>basket hitched</Link>{' '}
        <Link to={routes.glossary_sling}>sling</Link> with a{' '}
        <Link to={routes.glossary_rappel_ring}>rappel ring</Link> on each end.
        The <Link to={routes.glossary_rappel_line}>rappel line</Link> is rigged{' '}
        <Link to={routes.glossary_drt}>DRT</Link> through both rings. After{' '}
        <Link to={routes.glossary_pull}>pulling</Link> the rappel line, the
        sling is pulled down using a separate{' '}
        <Link to={routes.glossary_pull_cord}>pull cord</Link>.
      </>
    ),
    terms: ['2-ring-retrievable-rigging'],
    article: {
      title: 'How to Rig a 2-Ring Retrievable Anchor',
      route:
        'https://www.canyoneeringusa.com/techtips/tworingretrievableanchor',
    },
  },
  {
    id: '2-technical-rating',
    tooltip:
      'Semi-technical with lots of down climbing. Hand lines come in handy but no rappelling required.',
    jsx: (
      <>
        Non-technical with lots of{' '}
        <Link to={routes.glossary_down_climb}>down climbing</Link>.{' '}
        <Link to={routes.glossary_hand_line}>Hand lines</Link> come in handy but
        no <Link to={routes.glossary_rappel}>rappelling</Link> required.
      </>
    ),
    terms: ['2-technical-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: '3-technical-rating',
    tooltip: 'Technical - rappelling almost always required',
    jsx: (
      <>
        Technical. <Link to={routes.glossary_rappel}>Rappelling</Link> almost
        always required.
      </>
    ),
    terms: ['3-technical-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: '4-technical-rating',
    tooltip:
      'Advanced with long rappels, keeper potholes, or creative anchor strategies',
    jsx: (
      <>
        Advanced. It is generally associated with rappels longer than 200′,
        canyons with <Link to={routes.glossary_keeper_pothole}>potholes</Link>,
        or canyons that require creative anchor strategies such as{' '}
        <Link to={routes.glossary_marginal_anchor}>marginal anchors</Link> or{' '}
        <Link to={routes.glossary_retrievable_rigging_system}>
          retrievable rigging
        </Link>
        .
      </>
    ),
    terms: ['4-technical-rating'],
    article: 'canyon-rating-system',
  },
];

export default definitions;
