// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'v-time-rating',
    tooltip: 'It should take about two days to complete the canyon',
    jsx: <>It should take about two days to complete the canyon.</>,
    terms: ['v-time-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'v7-academy',
    tooltip: 'Website that provides online canyoneering instruction',
    jsx: (
      <>
        Website that provides online canyoneering instruction. If{' '}
        <Link to="/">Canyon Companion</Link> is medium quality then V7 is high
        quality. V7 is a reference to the highest vertical rating of the French
        Canyon Rating System.
      </>
    ),
    terms: ['v7-academy'],
    article: {
      title: 'V7 Academy',
      route: 'https://v7academy.com/',
    },
  },
  {
    id: 'valdotain-tresse',
    tooltip:
      'A friction hitch that is easy to slowly release and control the amount of friction it provides',
    jsx: (
      <>
        A <Link to={routes.glossary_friction_hitch}>friction hitch</Link> that,
        unlike other friction hitches, is easy to slowly release and control the
        amount of friction it provides.
      </>
    ),
    terms: ['valdotain-tresse', 'vt'],
  },
  {
    id: 'vertaco',
    tooltip:
      'Way to add additional friction for a rappel by putting the rappel rope through the carabiner',
    jsx: (
      <>
        Way to add additional friction to a{' '}
        <Link to="figure-8-rappel-device">figure 8</Link> style{' '}
        <Link to={routes.glossary_rappel_device}>rappel device</Link> by putting
        the brake side of the rappel rope through the carabiner.
        <br />I haven't found any origin of the term but my guess would be:
        <br />
        <em>ver-</em> → <em>true</em> in Latin-based languages.
        <br />
        <em>taco</em> → <em>stopper</em> in Spanish.
      </>
    ),
    terms: ['vertaco'],
  },
  {
    id: 'vi-time-rating',
    tooltip: 'It should take three or more days to complete the canyon',
    jsx: <>It should take three or more days to complete the canyon.</>,
    terms: ['vi-time-rating'],
    article: 'canyon-rating-system',
  },
  {
    id: 'via-ferrata',
    tooltip:
      'Steel cable fixed to a climbing route to be used as protection or climbing aid',
    jsx: (
      <>
        Steel cable fixed to a climbing route to be used as protection or
        climbing aid. Means "iron path" in Italian.
      </>
    ),
    terms: ['via-ferrata'],
  },
  {
    id: 'vrg',
    tooltip: 'Virgin River Gorge',
    jsx: (
      <>
        <Link to="http://ropewiki.com/Virgin_River_Gorge">
          Virgin River Gorge
        </Link>{' '}
        - canyoneering region in Utah and Arizona.
      </>
    ),
    terms: ['vrg'],
  },
  {
    id: 'vt-prusik',
    tooltip: 'The brand name of a specialized open-end Prusik',
    jsx: (
      <>
        The brand name of a specialized{' '}
        <Link to={routes.glossary_open_end_prusik}>open-end Prusik</Link>. The
        distinguishing factor is that it works particularly well for the{' '}
        <Link to={routes.glossary_valdotain_tresse}>Valdotain Tresse</Link>. It
        has many other functions.
      </>
    ),
    terms: ['vt-prusik'],
  },
];

export default definitions;
