// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'uiaa',
    tooltip:
      'Safety commission that develops and maintains safety standards for climbing equipment',
    jsx: (
      <>
        Union Internationale des Associations d'Alpinisme in French, or
        International Climbing and Mountaineering Federation in English, has a
        safety commission that develops and maintains safety standards for
        climbing equipment.
      </>
    ),
    terms: ['uiaa'],
    article: {
      title: "Union Internationale des Associations d'Alpinisme website",
      route: 'https://www.theuiaa.org/',
    },
  },
  {
    id: 'unbetaed-canyon',
    tooltip: 'A canyon that has no public beta',
    jsx: (
      <>
        A canyon that has no public <Link to={routes.glossary_beta}>beta</Link>.
      </>
    ),
    terms: ['secret-canyon', 'unbetaed-canyon'],
  },
  {
    id: 'undercut',
    tooltip:
      'Swift water hazard where the water current flows underneath an overhanging rock',
    jsx: (
      <>
        <Link to={routes.glossary_swift_water}>Swift water</Link>{' '}
        <Link to={routes.glossary_water_hazard}>hazard</Link> where the water
        current flows underneath an overhanging rock.
      </>
    ),
    terms: ['undercut'],
  },
  {
    id: 'up-canyon',
    tooltip: 'The upstream direction in a canyon',
    jsx: (
      <>
        The upstream direction in a{' '}
        <Link to={routes.glossary_canyon}>canyon</Link>.
      </>
    ),
    terms: ['up-canyon'],
  },
  {
    id: 'usgs',
    tooltip: 'United States Geological Survey',
    jsx: (
      <>
        United States Geological Survey - U.S. government agency that provides{' '}
        <Link to={routes.glossary_topographic_map}>topographic maps</Link>.
      </>
    ),
    terms: ['usgs'],
    article: {
      title: 'United States Geological Survey',
      route: 'https://www.usgs.gov/products/maps/topo-maps',
    },
  },
  {
    id: 'usgs-topographic-maps',
    tooltip:
      'Topographic maps commonly used for canyoneering and produced by the United States Geological Survey',
    jsx: (
      <>
        7.5 minute quadrangles commonly used for canyoneering and produced by
        the United States Geological Survey.{' '}
        <Link to="https://www.usgs.gov/faqs/how-do-i-find-and-download-us-topo-and-historical-topographic-htmc-maps">
          Get any of them for free
        </Link>
        . See <Link to={routes.glossary_topographic_map}>Topographic Map</Link>.
      </>
    ),
    terms: ['usgs-topographic-maps'],
  },
];

export default definitions;
