// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'yahoo-canyons-group',
    tooltip:
      'Defunct email list and predecessor to forums and Facebook groups we have today',
    jsx: (
      <>
        Defunct email list and predecessor to forums and Facebook groups we have
        today. An archive is housed on{' '}
        <Link to={routes.glossary_canyon_collective}>Canyon Collective</Link>.
      </>
    ),
    terms: ['yahoo-canyons-group'],
    article: {
      title: 'Yahoo Canyons Group Archive website',
      route:
        'http://canyoncollective.com/forums/archives-yahoo-canyons-group.17/',
    },
  },
  {
    id: 'yosemite-decimal-system',
    tooltip:
      'Yosemite Decimal System - A system used for rating the difficulty of walks, hikes, and climbs',
    jsx: (
      <>A system used for rating the difficulty of walks, hikes, and climbs.</>
    ),
    terms: ['yds', 'yosemite-decimal-system'],
    article: {
      title: 'Yosemite Decimal System on Wikipedia',
      route: 'https://en.wikipedia.org/wiki/Yosemite_Decimal_System',
    },
  },
];

export default definitions;
