// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'earnest',
    tooltip:
      'Ideal rigging is Equalized Angle Redundant No Extension Solid and Timely',
    jsx: (
      <>
        <Link to={routes.glossary_equalized}>
          <strong>E</strong>qualized
        </Link>{' '}
        <Link to={routes.glossary_angle}>
          <strong>A</strong>ngle
        </Link>{' '}
        <Link to={routes.glossary_redundant}>
          <strong>R</strong>edundant
        </Link>{' '}
        <Link to={routes.glossary_no_extension}>
          <strong>N</strong>o <strong>E</strong>xtension
        </Link>{' '}
        <Link to={routes.glossary_solid}>
          <strong>S</strong>olid
        </Link>{' '}
        <Link to={routes.glossary_timely}>
          <strong>T</strong>imely
        </Link>
        . Qualities of ideal anchor rigging.
      </>
    ),
    terms: ['earnest'],
    article: 'earnest',
  },
  {
    id: 'edge-protection',
    tooltip:
      'Something that is placed between the rappel rope and the ledge that protects the rope from abrasion or cutting and protects the rock from forming grooves',
    jsx: (
      <>
        Something that is placed between the rappel rope and the ledge that
        protects the rope from{' '}
        <Link to={routes.glossary_core_shot}>abrasion or cutting</Link> and
        protects the rock from forming{' '}
        <Link to={routes.glossary_rope_groove}>grooves</Link>.
      </>
    ),
    terms: ['edge-protection'],
  },
  {
    id: 'egress',
    tooltip: 'The action of going out or leaving the canyon',
    jsx: (
      <>
        The action of going out or leaving the{' '}
        <Link to={routes.glossary_canyon}>canyon</Link>.
      </>
    ),
    terms: ['egress'],
  },
  {
    id: 'elevatoring',
    tooltip: 'Move up or down in a canyon without moving forward or backward',
    jsx: (
      <>
        Move up or down in a canyon without moving forward or backward. See{' '}
        <Link to={routes.glossary_stemming}>stemming</Link> and{' '}
        <Link to={routes.glossary_chimney}>chimney</Link>.
      </>
    ),
    terms: ['elevatoring'],
  },
  {
    id: 'elongation',
    tooltip:
      'The amount a rope stretches when loaded with a specified amount of force',
    jsx: (
      <>
        The amount a <Link to={routes.glossary_rope}>rope</Link> stretches when
        loaded with a specified amount of force.{' '}
        <Link to={routes.glossary_static_rope}>Static rope</Link> minimizes
        elongation.
      </>
    ),
    terms: ['elongation'],
    article: 'rope',
  },
  {
    id: 'emergency-medical-services',
    tooltip: 'Emergency Medical Services',
    jsx: (
      <>Agency of the U.S. Government that provides emergency medical care.</>
    ),
    terms: ['emergency-medical-services', 'ems'],
    article: {
      title: 'Emergency Medical Services',
      route: 'https://www.ems.gov/',
    },
  },
  {
    id: 'entrada-sandstone',
    tooltip: 'Red, cross-bedded, arch-forming sandstone',
    jsx: (
      <>
        Red, cross-bedded, arch-forming{' '}
        <Link to={routes.glossary_sandstone}>sandstone</Link>. Examples are{' '}
        <Link to="http://ropewiki.com/Goblin_Valley_State_Park">
          Goblin Valley
        </Link>{' '}
        and{' '}
        <Link to="http://ropewiki.com/Arches_National_Park">
          Arches National Park
        </Link>
        .
      </>
    ),
    terms: ['entrada-sandstone'],
  },
  {
    id: 'epic',
    tooltip:
      'Canyon descent or trip that is unexpectedly long or difficult, often resulting in an unplanned bivy and/or self rescue',
    jsx: (
      <>
        Canyon descent or trip that is unexpectedly long or difficult, often
        resulting in an unplanned <Link to={routes.glossary_bivy}>bivy</Link>{' '}
        and/or <Link to={routes.glossary_self_rescue}>self rescue</Link>.
      </>
    ),
    terms: ['epic'],
  },
  {
    id: 'equalette',
    tooltip:
      'EARNEST rigging that allows for greater equalization than a single master point by allowing the rapide to slide so as to change the direction of pull',
    jsx: (
      <>
        <Link to={routes.glossary_earnest}>EARNEST</Link> rigging that allows
        for greater <Link to={routes.glossary_equalized}>equalization</Link>{' '}
        than a single{' '}
        <Link to={routes.glossary_master_point}>master point</Link> by allowing
        the <Link to={routes.glossary_rapide}>rapide</Link> to slide so as to
        change the direction of pull.
      </>
    ),
    terms: ['equalette'],
  },
  {
    id: 'equalized',
    tooltip:
      'One of the qualities of EARNEST rigging. The weight is equally distributed on each anchor.',
    jsx: (
      <>
        One of the qualities of EARNEST rigging. The weight is equally
        distributed on each anchor.
      </>
    ),
    terms: ['equalized'],
    article: 'earnest',
  },
  {
    id: 'etrier',
    tooltip: 'Set of webbing loops for use as a light-weight portable ladder',
    jsx: (
      <>
        Set of webbing loops for use as a light-weight portable ladder. Most
        commonly used in a system for{' '}
        <Link to={routes.glossary_pothole_escape_technique}>
          pothole escapes
        </Link>
        . Stirrup in French.
      </>
    ),
    terms: ['aider', 'etrier'],
  },
  {
    id: 'euro-8',
    tooltip:
      'A variation of the contingency 8 that tries to minimize the possibility of the rope getting stuck when converting from a block to a lower',
    jsx: (
      <>
        A variation of the{' '}
        <Link to={routes.glossary_contingency_8}>contingency 8</Link> that tries
        to minimize the possibility of the rope getting stuck when converting
        from a block to a lower.
      </>
    ),
    terms: ['euro-8', 'figure-8-braid'],
  },
  {
    id: 'exit',
    tooltip:
      'Between the technical section of the canyon and the end of hiking',
    jsx: (
      <>
        The part between the technical section of the canyon and the end of
        hiking. The exact definition usually varies between{' '}
        <Link to={routes.glossary_beta_provider}>beta providers</Link> and
        canyons.
      </>
    ),
    terms: ['exit'],
  },
  {
    id: 'expansion-bolt',
    tooltip:
      'A type of bolt that is anchored to the rock by an expanding sleeve',
    jsx: (
      <>
        A type of <Link to={routes.glossary_bolt}>bolt</Link> that is anchored
        to the rock by an expanding sleeve.
      </>
    ),
    terms: ['expansion-bolt'],
  },
  {
    id: 'exposure',
    tooltip: 'A fall could result in serious injury or death',
    jsx: <>A fall could result in serious injury or death.</>,
    terms: ['exposure'],
  },
];

export default definitions;
