// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'last-person-at-risk',
    tooltip:
      'LAPAR or the LAst Person At Risk faces unusually difficult obstacles with additional risks and challenges',
    jsx: (
      <>
        Refers to the fact that <strong>LAPAR</strong> or the{' '}
        <strong>LA</strong>st <strong>P</strong>erson <strong>A</strong>t{' '}
        <strong>R</strong>isk faces unusually difficult obstacles with
        additional risks and challenges.
      </>
    ),
    terms: ['lamar', 'lapar', 'last-person-at-risk', 'lmar', 'lmr', 'lpar'],
  },
  {
    id: 'lcv',
    tooltip: 'Low Clearance Vehicle',
    jsx: (
      <>
        <strong>L</strong>ow <strong>C</strong>learance <strong>V</strong>
        ehicle. Necessary to access some remote canyons. Opposite of{' '}
        <Link to={routes.glossary_hcv}>HCV</Link>.
      </>
    ),
    terms: ['lcv'],
  },
  {
    id: 'leave-no-trace',
    tooltip: 'Responsibility in the outdoors',
    jsx: (
      <>
        Philosophy and <Link to="https://lnt.org/">organization</Link> that
        promote responsibility in the outdoors.
      </>
    ),
    terms: ['leave-no-trace'],
  },
  {
    id: 'left-down-canyon',
    tooltip: 'On the left side when looking down canyon',
    jsx: (
      <>
        On the left side when looking{' '}
        <Link to={routes.glossary_down_canyon}>down canyon</Link>.
      </>
    ),
    terms: ['canyon-left', 'ldc', 'left-down-canyon'],
  },
  {
    id: 'left-up-canyon',
    tooltip: 'On the left side when looking up canyon',
    jsx: (
      <>
        On the left side when looking{' '}
        <Link to={routes.glossary_up_canyon}>up canyon</Link>.
      </>
    ),
    terms: ['left-up-canyon', 'luc'],
  },
  {
    id: 'leg-loop',
    tooltip: 'Part of the harness that wraps around and supports the legs',
    jsx: (
      <>
        Part of the <Link to={routes.glossary_harness}>harness</Link> that wraps
        around and supports the legs. Leg loops are rated to hold rappellers.
      </>
    ),
    terms: ['leg-loop'],
  },
  {
    id: 'leg-wrap',
    tooltip:
      "Soft lock-off achieved by wrapping the rappel rope around one's leg",
    jsx: (
      <>
        <Link to={routes.glossary_soft_lock_off}>Soft lock-off</Link> achieved
        by wrapping the rappel rope around one's leg.
      </>
    ),
    terms: ['leg-wrap'],
  },
  {
    id: 'leo',
    tooltip: 'Law Enforcement Officer',
    jsx: (
      <>
        Law Enforcement Officer. Same as ranger in the context of canyoneering.
      </>
    ),
    terms: ['leo'],
  },
  {
    id: 'lift-and-shift',
    tooltip:
      'The act of lifting a load off of one rope and shifting its weight to another rope',
    jsx: (
      <>
        The act of lifting a load off of one rope and shifting its weight to
        another rope. One of the steps of the algorithm to determine how to
        safely lower someone stuck on rappel.
      </>
    ),
    terms: ['lift-and-shift'],
  },
  {
    id: 'lnt',
    tooltip: 'Leave No Trace',
    jsx: (
      <>
        Leave No Trace - organization focused on sustainable outdoor practices.
      </>
    ),
    terms: ['lnt'],
    article: {
      title: 'Leave No Trace',
      route: 'https://lnt.org/',
    },
  },
  {
    id: 'load-releasing-hitch',
    tooltip: 'A family of hitches which can be released while weighted',
    jsx: (
      <>
        A family of <Link to={routes.glossary_hitch}>hitches</Link> which can be
        released while weighted. One purpose of a load releasing hitch is to
        efficiently shift weight from one rope to another. Examples are the{' '}
        <Link to={routes.glossary_munter_mule_overhand}>MMO</Link>,{' '}
        <Link to={routes.glossary_mariners_hitch}>Mariner's Hitch</Link>, and{' '}
        <Link to={routes.glossary_purcell_prusik}>Purcell Prusik</Link>.
      </>
    ),
    terms: ['load-releasing-hitch'],
  },
  {
    id: 'loaded-for-bear',
    tooltip:
      'Carry lots of weighty stuff so as to be prepared for anything in the canyon',
    jsx: (
      <>
        Carry lots of weighty stuff so as to be prepared for anything in the
        canyon.
      </>
    ),
    terms: ['loaded-for-bear'],
  },
  {
    id: 'log-soup',
    tooltip:
      'A mass of logs in standing water that make passage more difficult',
    jsx: (
      <>A mass of logs in standing water that make passage more difficult.</>
    ),
    terms: ['log-soup', 'trash-compactor'],
  },
  {
    id: 'logjam',
    tooltip:
      'A mass of logs that are pressed up against and wedged into a skinny section of canyon',
    jsx: (
      <>
        A mass of logs that are pressed up against and wedged into a skinny
        section of canyon.
      </>
    ),
    terms: ['logjam'],
  },
  {
    id: 'long-rappel',
    tooltip: 'Rappel that is more than half the length of the rope being used',
    jsx: (
      <>
        <Link to={routes.glossary_rappel}>Rappel</Link> that is more than half
        the length of the <Link to={routes.glossary_rope}>rope</Link> being
        used.
      </>
    ),
    terms: ['long-rappel'],
  },
  {
    id: 'loop',
    tooltip: 'A length of rope that is doubled and crosses itself',
    jsx: (
      <>
        A length of <Link to={routes.glossary_rope}>rope</Link> that is doubled
        and crosses itself.
      </>
    ),
    terms: ['loop'],
  },
  {
    id: 'lower',
    tooltip:
      'Lower a canyoneer down a cliff that would normally be rappelled. This is done when a canyoneer is unable to rappel on their own.',
    jsx: (
      <>
        Lower a canyoneer down a cliff that would normally be rappelled. This is
        done when a canyoneer is unable to rappel on their own.
      </>
    ),
    terms: ['lower'],
  },
];

export default definitions;
