// @flow
import type { GlossaryDefinition } from '../../utils/types';
import Link from '../../components/link';
import routes from '../../routes';
import React from 'react';

const definitions: Array<GlossaryDefinition> = [
  {
    id: 'daisy-chain',
    tooltip: 'Safety tether or a chain or a way to secure webbing',
    jsx: (
      <>
        <Link to={routes.glossary_safety_tether}>Safety tether</Link> that has a
        bunch of loops. Also series of slip knots tied on{' '}
        <Link to={routes.glossary_webbing}>webbing</Link> that allow it to store
        securely and can quickly be untied while keeping the webbing untangled.
      </>
    ),
    terms: ['daisy-chain'],
  },
  {
    id: 'deadman-anchor',
    tooltip:
      'A rock that is rigged with webbing and buried to be a rappel anchor',
    jsx: (
      <>
        A rock that is rigged with webbing and buried to be a rappel{' '}
        <Link to={routes.glossary_anchor}>anchor</Link>. The only way to know if
        it is safe is to dig it up, inspect it, and rebury it.
      </>
    ),
    terms: ['deadman-anchor'],
  },
  {
    id: 'dear',
    tooltip:
      'Dry Efficient Accessible Rope Retrieval - qualities of an ideal anchor location',
    jsx: (
      <>
        <strong>D</strong>ry <strong>E</strong>fficient <strong>A</strong>
        ccessible <strong>R</strong>ope <strong>R</strong>etrieval. Qualities of
        an ideal <Link to={routes.glossary_anchor}>anchor</Link> location. Also
        written DEARR.
      </>
    ),
    terms: ['dear'],
    article: 'dear',
  },
  {
    id: 'desert-varnish',
    tooltip:
      'Black coating found on sandstone walls that is primarily made of manganese and iron',
    jsx: (
      <>
        Black coating found on{' '}
        <Link to={routes.glossary_sandstone}>sandstone</Link> walls that is
        primarily made of manganese and iron.
      </>
    ),
    terms: ['desert-varnish'],
  },
  {
    id: 'dipstick',
    tooltip: 'The tallest person in the group',
    jsx: (
      <>
        The tallest person in the group. Their responsibility is to safely
        perform water depth check.
      </>
    ),
    terms: ['dipstick'],
    article: 'core-skills-checklist#swimming-jumping-water-slides',
  },
  {
    id: 'dogbone',
    tooltip:
      'Short piece of semi-rigid material that holds a carabiner on each end',
    jsx: (
      <>
        Short piece of semi-rigid material that holds a{' '}
        <Link to={routes.glossary_carabiner}>carabiner</Link> on each end. Part
        of a <Link to={routes.glossary_quickdraw}>quickdraw</Link> and{' '}
        <Link to={routes.glossary_canyon_quickdraw}>canyon quickdraw</Link>.
      </>
    ),
    terms: ['dogbone'],
  },
  {
    id: 'double-rope-technique',
    tooltip: 'Rappelling on two ropes',
    jsx: (
      <>
        Rappelling on two ropes as opposed to{' '}
        <Link to={routes.glossary_single_rope_technique}>SRT</Link>. There are
        advantages and disadvantages to each technique.
      </>
    ),
    terms: ['double-rope-technique', 'double-strand', 'drt'],
  },
  {
    id: 'down-canyon',
    tooltip: 'The downstream direction in a canyon',
    jsx: <>The downstream direction in a canyon.</>,
    terms: ['down-canyon'],
  },
  {
    id: 'down-climb',
    tooltip: 'Climbing down sections of canyon that could be rappelled',
    jsx: (
      <>
        Climbing down sections of canyon that could be rappelled. Aside from the
        view, this is perhaps my favorite part of canyoneering. A major aspect
        of many canyon areas on the{' '}
        <Link to={routes.glossary_colorado_plateau}>Colorado Plateau</Link>.
      </>
    ),
    terms: ['down-climb', 'worm'],
  },
  {
    id: 'drainage',
    tooltip:
      'Any area of land where precipitation collects and drains off into a common outlet',
    jsx: (
      <>
        Any area of land where precipitation collects and drains off into a
        common outlet.
      </>
    ),
    terms: ['catch-basin', 'drainage', 'watershed'],
    article: {
      title: 'Drainage basin on Wikipedia',
      route: 'https://en.wikipedia.org/wiki/Drainage_basin',
    },
  },
  {
    id: 'draw',
    tooltip: 'Drainage which makes up the area between two spurs',
    jsx: (
      <>
        <Link to={routes.glossary_drainage}>Drainage</Link> which makes up the
        area between two <Link to={routes.glossary_spur}>spurs</Link>.
      </>
    ),
    terms: ['draw', 'side-drainage'],
  },
  {
    id: 'dress',
    tooltip: 'Make a knot look pretty',
    jsx: (
      <>
        Make a <Link to={routes.glossary_knot}>knot</Link> look pretty. Also
        makes it easier to inspect and for some knots they become stronger.
      </>
    ),
    terms: ['dress'],
  },
  {
    id: 'dry-bag',
    tooltip:
      'Bag made of flexible, sturdy, waterproof material to keep things dry',
    jsx: (
      <>
        Bag made of flexible, sturdy, waterproof material to keep things dry.
        The opening is typically sealed by coiling it several times and securing
        it so it won't accidentally open.
      </>
    ),
    terms: ['dry-bag', 'dry-sack'],
  },
  {
    id: 'dryfall',
    tooltip:
      'A drop in a canyon that would have a waterfall when water is flowing in the canyon',
    jsx: (
      <>
        A drop in a canyon that would have a waterfall when water is flowing in
        the canyon.
      </>
    ),
    terms: ['dryfall'],
  },
  {
    id: 'ds',
    tooltip:
      'Dual Sheath - BlueWater ropes that use technora/polyester for their sheath',
    jsx: (
      <>
        <strong>D</strong>ual <strong>S</strong>heath -{' '}
        <Link to="https://www.bluewaterropes.com/">BlueWater</Link> ropes that
        use <Link to={routes.glossary_technora}>technora</Link>/
        <Link to={routes.glossary_polyester}>polyester</Link> for their{' '}
        <Link to={routes.glossary_sheath}>sheath</Link>.
      </>
    ),
    terms: ['ds'],
  },
  {
    id: 'dynamic-courtesy-rigging',
    tooltip:
      'A 2 to 1 mechanical advantage system used to lower a rappeller over a lip in place of static courtesy rigging',
    jsx: (
      <>
        A 2 to 1{' '}
        <Link to={routes.glossary_mechanical_advantage}>
          mechanical advantage
        </Link>{' '}
        system used to lower a{' '}
        <Link to={routes.glossary_rappeller}>rappeller</Link> over a lip in
        place of{' '}
        <Link to={routes.glossary_static_courtesy_rigging}>
          static courtesy rigging
        </Link>
        .
      </>
    ),
    terms: ['drop-loop', 'dynamic-courtesy-rigging'],
  },
  {
    id: 'dynamic-rope',
    tooltip:
      'Kernmantle rope that is designed to stretch when loaded. Bad for rappelling.',
    jsx: (
      <>
        <Link to={routes.glossary_kernmantle_rope}>Kernmantle rope</Link> that
        is designed to stretch when loaded. Bad for rappelling. The only time a
        dynamic rope is applicable in canyoneering is for a{' '}
        <Link to={routes.glossary_safety_tether}>safety tether</Link> to help
        with <Link to={routes.glossary_fall_factor}>fall factor</Link>.
        Canyoneers use <Link to={routes.glossary_static_rope}>Static Rope</Link>
        .
      </>
    ),
    terms: ['dynamic-rope'],
    article: 'rope',
  },
  {
    id: 'dyneema',
    tooltip:
      'Rope and sling material that is very strong, very static, and slippery so knots might fail',
    jsx: (
      <>
        Rope and <Link to={routes.glossary_sling}>sling</Link> material that is
        very strong, very static, and slippery so knots might fail.
      </>
    ),
    terms: ['dyneema', 'spectra'],
  },
];

export default definitions;
